import React, { useState, useEffect, useContext } from "react";
import "./RolloverRow.css";
import moment from "moment";
import { fetchLocalTokenData, getFeeManagerContract, truncateNumber } from "../../utils/Utils";
import { ethers } from "@usedapp/core/node_modules/ethers";
import { fetchTokenLogo } from "../../utils/Utils";
import ReactTooltip from "react-tooltip";
import { WalletDataContext } from "../../context/WalletDataContext";
import { WALLET_DATA_CONTEXT } from "../../utils/Interfaces";

const RolloverRow = (props: {
  pool: any,
  oldPoolAddress: string,
  oldExpiry: number,
  active: boolean,
  rolloverRowClicked: (
    colSymbol: string,
    lendSymbol: string,
    mintRatio: string,
    expiry: number,
    feeRate: number,
    index: number
  ) => void,
  index: number
  colPrice: number,
  lendPrice: number,
  rolloverPoolDataLoaded: (data: any) => void
}) => {
  const [expiry, setExpiry] = useState<number>(0);
  const [mintRatio, setMintRatio] = useState("");
  const [colSymbol, setColSymbol] = useState("");
  const [lendSymbol, setLendSymbol] = useState("");
  // const [newPoolOwner, setNewPoolOwner] = useState("");
  // const [oldPoolOwner, setOldPoolOwner] = useState("");
  const [feeRate, setFeeRate] = useState<number>(0);
  const [annualizedFeeRate, setAnnualizeFeeRate] = useState<number>(0);
  const [borrowingDisabled, setBorrowingDisabled] = useState<boolean>(false);
  const { provider, chainId } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;

  useEffect(() => {
    getPoolStats();
    // eslint-disable-next-line
  }, []);

  const getPoolStats = async () => {
    if (!provider) return;
    // let newPoolOwner = props.pool._deployer
    let mintRatioResp = props.pool._mintRatio
    let expiryResp = props.pool._expiry;
    const feeManagerContract = getFeeManagerContract(provider, chainId);
    let feeRateResp = await feeManagerContract.getCurrentRate(
      props.pool.id,
    );
    const lendTokenData = fetchLocalTokenData(props.pool._lendToken, chainId);
    const colTokenData = fetchLocalTokenData(props.pool._colToken, chainId);
    const colSymbol = colTokenData.symbol;
    const lendSymbol = lendTokenData.symbol;
    const feeTypeResp = await feeManagerContract.rateFunction(props.pool.id);
    const feeType = feeTypeResp.toString();
    const disabledBorrow = props.pool._paused;

    let annualizedFeeRate = await feeManagerContract.feeRates(props.pool.id);
    if (feeType === "1") {
      annualizedFeeRate = (31536000 / (((Number(props.pool._expiry)) - new Date().getTime() / 1000))) * (parseFloat(feeRateResp.toString()));
    } else {
      annualizedFeeRate = annualizedFeeRate.toString();
    }

    // update state
    setExpiry(expiryResp);
    setBorrowingDisabled(disabledBorrow);
    setMintRatio(ethers.utils.formatUnits(mintRatioResp, 18));
    setColSymbol(colSymbol);
    setLendSymbol(lendSymbol);
    setFeeRate(Number(feeRateResp.toString()) / 10000);
    setAnnualizeFeeRate(Number(annualizedFeeRate));

    // notify the parent component that the pool is loaded
    // and pass some data about the pool
    props.rolloverPoolDataLoaded({
      id: props.pool.id,
      disabledBorrow,
    });
    
  };

  const renderRow = () => {
    // make sure symbols are loaded
    // new expiry must be > old expiry
    if (
      !colSymbol ||
      !lendSymbol
    ) {
      return <div></div>;
    } else {
      const lendValue = parseFloat(mintRatio) * props.lendPrice;
      const colValue = props.colPrice;
      const apr = annualizedFeeRate / 10000;
      const ltv = (lendValue / colValue) * 100;
      return (
        <div
          className={`rollover-row fade-in ${props.active ? "active" : ""} ${borrowingDisabled ? "disabled" : ""}`}
          onClick={() => 
            props.rolloverRowClicked(
              colSymbol,
              lendSymbol,
              mintRatio,
              expiry,
              feeRate,
              props.index
            )
          }
        >
          <ReactTooltip id={`token-pair-tip-${props.pool.id}`} type="info" className="tool-tip">
            <span>{colSymbol}/{lendSymbol}</span>
          </ReactTooltip>
          <div className="repay-icon-pair" data-tip data-for={`token-pair-tip-${props.pool.id}`}>
            <img
              className="token-logo repay-col"
              alt="collateral-token"
              src={fetchTokenLogo(colSymbol)}
            ></img>
            <img
              className="token-logo repay-pair"
              alt="pair-token"
              src={fetchTokenLogo(lendSymbol)}
            ></img>
          </div>
          <div className="rollover-lend-ratio rollover-text">
            <ReactTooltip id={`rollover-ltv-tip-${props.pool.id}`} type="info" className="tool-tip">
              Lend Ratio: {mintRatio}
            </ReactTooltip>
            <span data-tip data-for={`rollover-ltv-tip-${props.pool.id}`}>
              {ltv.toFixed(2)}%
            </span>
          </div>
          <div className="rollover-expiry rollover-text">
            {moment(expiry * 1000).format("MMM D, YYYY")}
          </div>
          <div className="rollover-amount-owed rollover-text">
            {truncateNumber(feeRate, 4)}%
          </div>
          <div className="rollover-amount-owed rollover-text">
            {truncateNumber(apr, 4)}%
          </div>
        </div>
      );
    }
  };

  return renderRow();
};

export default RolloverRow;
