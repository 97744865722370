import { BigNumberish, ethers } from "ethers";
import { Fragment, useContext, useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";
import { Area, Bar, ComposedChart, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { AppDataContext } from "../../context/AppDataContext";
import { WalletDataContext } from "../../context/WalletDataContext";
import { APP_DATA_CONTEXT, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { fetchHistoricalPriceData, fetchLocalTokenData, generateFakePriceData, getCurrentPage, tokenData } from "../../utils/Utils";
import "./PoolSimulation.css";

const PoolSimulation = (props: {
  colToken: string, 
  lendToken: string,
  mintRatio: BigNumberish,
  fakeData?: boolean,
  startTime?: string 
}) => {

  const [historicalPriceData, setHistoricalPriceData] = useState<any[]>(generateFakePriceData());
  // const [storedHistoricalPriceData, setStoredHistoricalPriceData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);

  // load contexts
  const { chainId } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { tokenPrices, historicalPrices, setHistoricalPrices } = useContext(AppDataContext) as APP_DATA_CONTEXT;

  useEffect(() => {
    if (
      props.colToken !== "" && 
      props.lendToken !== "" && 
      props.mintRatio > -1 && chainId
    ) getGraphData();
  // eslint-disable-next-line
  }, [props.mintRatio, props.colToken, props.lendToken, tokenPrices, chainId]);

  const CustomTooltip = (data:any) => {
    if (data && data.active && data.payload && data.payload.length) {
      try {

      return (
        <div className="graph-tooltip">
          <p className="label">{`${new Date(data.payload[2].payload.timestamp).toLocaleString()}`}</p>
          <p className="label">Collateral Price: {`$${data.payload[0].value}`}</p>
          <p className="label">Lend Ratio: {`${props.mintRatio}`}</p>
          <p className="label">Borrow Value: {`$${data.payload[1].value}`}</p>
        </div>
      );
      } catch (e) {
        return (
          <div className="graph-tooltip">
            <p className="label">LOADING</p>
          </div>
        );
      }
    }
  
    return null;
  };

  // fetch historical price data from backend or load from cached data
  const getHistoricalPriceData = async (colToken: string) => {
    let historicalData:[][] = [];
    // check if entry exists
    if (!historicalPrices[colToken]) {
      // if no entry, load from backend
      historicalData = await fetchHistoricalPriceData(colToken, chainId, 61);
      let newStoredData = {
        ...historicalPrices,
        [colToken]: historicalData
      }
      // save data to cache
      setHistoricalPrices(newStoredData);
    } else {
      // if entry, load from cache
      historicalData = historicalPrices[colToken];
    }
    return (historicalData);
  }

  const getGraphData = async () => {
    if (props.colToken === "" || props.lendToken === "" || !tokenPrices) return;
    setLoading(true);
    // get historical prices for the given collateral token
    const prices:[][] = await getHistoricalPriceData(props.colToken);
    // const lendSymbol:string = fetchTokenSymbol(props.lendToken, chainId);
    const colData = fetchLocalTokenData(props.colToken, chainId);
    const lendData = fetchLocalTokenData(props.lendToken, chainId);
    // @ts-ignore
    const lendPrice = tokenPrices[ethers.utils.getAddress(props.lendToken)];
    let mintRatio = Number(props.mintRatio) * lendPrice;
    mintRatio = isFinite(mintRatio) ? mintRatio : 1;
    let historicalData = [];
    let skip = props.colToken === tokenData.ARB.address[42161] ? 1 : 2;
    // construct graph data based on historical prices
    for (let i = 0; i < prices.length; i+=skip) {
      // if (i % 2 === 0) continue;
      const priceData:any = prices[i];
      const colPrice = (priceData.price).toFixed(colData.displayDecimals);
      const pointData:any = {
        name: (priceData.timestamp  * 1000),
        timestamp: new Date(priceData.timestamp * 1000),
        // "Expiry": ,
        "Collateral Price": colPrice,
        "Borrow Value": mintRatio.toFixed(Math.max(lendData.displayDecimals, colData.displayDecimals)),
      }
      historicalData.push(pointData);
    }

    // add current price to graph
    /*
    const price = tokenPrices[props.colToken];
    // TODO: remove second case when arb token is launched
    if (parseFloat(price) > 0 && props.colToken.toUpperCase() !== tokenData.ARB.address[42161].toUpperCase()) {
      const now = new Date();
      const pointData:any = {
        name: Number(now),
        timestamp: now,
        "Collateral Price": parseFloat(price).toFixed(2),
        "Borrow Value": mintRatio.toFixed(2)
      }
      historicalData.push(pointData);
    }
    */

    setHistoricalPriceData(historicalData);
    setLoading(false);
  }

  const formatDate = (timestamp: number) => {
    if (!timestamp) return "";
    let str = new Date(timestamp).toString();
    const splitStr = str.split(" ");
    str = `${splitStr[1]} ${splitStr[2]}`
    return str;
  }

  const renderChart = () => {

    // gradient colors for price graph area
    const gradientColors = () => {
      return (
       <linearGradient id="colorView" x1="0" y1="0" x2="1" y2="0">
         <stop offset="30%" stopColor="var(--iris)" stopOpacity={0.08} />
         <stop offset="95%" stopColor="var(--lavender-rose)" stopOpacity={0.08} />
       </linearGradient>
      );
    };

    // gradient colors for price graph area
    const dashed = () => {
      const colors = [...new Array(100)].map((_, index) => 
        <stop 
          offset={`${index}%`} 
          stopColor={`${index % 5 === 0 ? "white" : "black"}`}
          stopOpacity={`${index % 5 === 0 ? 0 : 1}`}
        />
      );
      return (
       <linearGradient id="dashed" x1="0" y1="0" x2="0" y2="1">
          {colors}
       </linearGradient>
      );
    };

    // custom dot coloring logic 
    const customDot = (data: any) => {
      const { cx, cy, dataKey } = data;
      const stroke = dataKey.indexOf("Borrow Value") > -1 
        ? "var(--lavender-rose)" 
        : "var(--iris)";
      return (
        <circle cx={cx} cy={cy} r={3} stroke={stroke} strokeWidth={2} fill="white" />
      );
    }

    // make the Y axis show values between 0 and the highest price * 1.5
    const getYAxisDomain = () => {
      const sortedPrices = [...historicalPriceData].sort((data1: any, data2: any) => 
        Number(data1["Collateral Price"]) - Number(data2["Collateral Price"])
      );
      const highestPrice = Number(sortedPrices[sortedPrices.length - 1]["Collateral Price"]);
      return [0, highestPrice * 1.5];
    }

    return (
      <ResponsiveContainer width="100%" height="105%">
        <ComposedChart data={historicalPriceData} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
          <defs>
            {gradientColors()}
            {dashed()}
          </defs>
          <XAxis 
            dataKey="name"
            scale="auto" 
            tickLine={false}
            minTickGap={10}
            tickFormatter={(tick: any) => formatDate(tick)}
            axisLine={{ stroke: "var(--input)" }}
            tickCount={20}
            domain={[historicalPriceData[0].name, historicalPriceData[historicalPriceData.length - 1].name]}
            stroke="var(--border-color)" 
            type="number"
          />
          <YAxis
            type="number"
            yAxisId={1}
            tickLine={false}
            tick={false}
            domain={getYAxisDomain()}
            width={0}
          />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="natural"
            dataKey="Collateral Price"
            fill="url(#colorView)"
            strokeWidth={2}
            stroke="#4753d8"
            yAxisId={1}
            dot={false}
            activeDot={customDot}
            animationDuration={500}
          />
          <Line
            type="monotone"
            dataKey="Borrow Value"
            stroke="var(--lavender-rose)"
            yAxisId={1}
            dot={false}
            strokeDasharray={3}
            activeDot={customDot}
            animationDuration={500}
          />
          <Line
            type="monotone"
            dataKey="timestamp"
            strokeWidth={5}
            yAxisId={1}
            dot={false}
          />
          {/* the start date bar is invisible, but needed to render legend*/}
          {props.startTime &&
            <Fragment>
              <Bar 
                dataKey="Start Date" 
                fill="black"
                maxBarSize={0}
                strokeDasharray={"3 3"}
                yAxisId={1}
              />
              {Number(props.startTime) * 1000 > historicalPriceData[0].name &&
                <ReferenceLine
                  x={Number(props.startTime) * 1000}
                  stroke="black"
                  ifOverflow="extendDomain"
                  yAxisId={1}
                  strokeDasharray={3}
                />
              }
            </Fragment>
          }
          <Legend 
            iconType={"square"}
            wrapperStyle={{top: -17, right: 0}}
            iconSize={12}
            color={"red"}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }

  return (
    <div className="pool-simulation-wrapper">
      <div className="pool-simulation-title">
        <span>
          Collateral Price &amp; Borrow Value
          {loading && getCurrentPage().indexOf("my-pools") === -1 && <PuffLoader size={20} className="simulation-loader"/>}
        </span>
      </div>
      {renderChart()}
    </div>
  )
}

export default PoolSimulation;