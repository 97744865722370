import { useSnackbar } from "notistack";
import { PauseCircle } from "react-feather";
import ActionButton from "../../components/ActionButton/ActionButton";
import { getGasLimit, getTransactionUrl, supportedNetworks } from "../../utils/Utils";
import LendingPoolABI from "../../abi/LendingPool.js";
import { ethers } from "ethers";
import { useContext, useEffect, useState } from "react";
import { WalletDataContext } from "../../context/WalletDataContext";
import { METHOD_TYPE, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";

const PauseBorrowing = (props: {
  selectedPool: any,
  pending: boolean
  setPending: (val: boolean) => void
  manageActionButton: () => boolean
  updatePoolData: () => void,
}) => {

  const { enqueueSnackbar } = useSnackbar();
  const [isPoolPaused, setIsPoolPaused] = useState<boolean>(false);
  const { provider, chainId } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;

  useEffect(() => {
    getPauseStatus();
  });

  const getPauseStatus = async() => {
    if (!provider || !props.selectedPool) return;
    const poolContract = new ethers.Contract(
      props.selectedPool.id,
      LendingPoolABI,
      provider
    );
    const disabledBorrowResp = await poolContract.disabledBorrow();
    setIsPoolPaused(disabledBorrowResp.toString() === "0" ? false : true);
  }

  const pauseBorrow = async() => {
    if (!supportedNetworks.includes(chainId) || !props.selectedPool || !provider) return;

    props.setPending(true);
    enqueueSnackbar("Please confirm the toggle borrow transaction in your wallet", {
      persist: false,
      disableWindowBlurListener: true
    });

    try {
      const poolAddress = props.selectedPool.id;
      const poolContract = new ethers.Contract(
        poolAddress,
        LendingPoolABI,
        provider.getSigner() as any
      );
      const tx = await poolContract.setBorrow(
        isPoolPaused ? 0 : 1,
        {
          ...getGasLimit(chainId, METHOD_TYPE.PAUSE_BORROWING)
        }
      );
      enqueueSnackbar(`Executing toggle borrow transaction ** ${getTransactionUrl(tx.hash, chainId)} ** persist`);
      await tx.wait();
      enqueueSnackbar(`Toggle borrow transaction successful ** ${getTransactionUrl(tx.hash, chainId)}`, {
        persist: false,
        disableWindowBlurListener: true
      });
    } catch (e) {
      enqueueSnackbar("Toggle borrow transaction failed", {
        persist: false,
        disableWindowBlurListener: true
      });
      props.setPending(false);
      console.log(e);
      return;
    }
    props.setPending(false);
    props.updatePoolData();
  }

  const manageWidgetText = () => {
    if (!props.selectedPool) return "Pause Borrowing";
    else if (isPoolPaused) return "Unpause Borrowing";
    else if (!isPoolPaused) return "Pause Borrowing";
    else return "Pause Borrowing";
  }

  return (
    <div className="pause-borrowing-wrapper small-widget">
      <PauseCircle className="widget-icon" />
      <span className="widget-header">{manageWidgetText()}</span>
      <span className="widget-description">
        When paused users will no longer be able to borrow from this pool but
        all other actions such as repay or rollover will remain active
      </span>
      <ActionButton
        disabled={props.manageActionButton()} 
        title={manageWidgetText()}
        action={pauseBorrow}
      />
    </div>
  );
}

export default PauseBorrowing;