import React, { useContext } from "react";
import { useState, useEffect, Fragment } from "react";
import ReactTooltip from "react-tooltip";
import ActionButton from "../../components/ActionButton/ActionButton";
import { HelpCircle } from "react-feather";
import TokenSelector from "../../components/TokenSelector/TokenSelector";
import Selector from "../../components/Selector/Selector";
import moment from "moment";
import "./CreatePool.css";
import TransactionReview from "../../components/TransactionReview/TransactionReview";
import { ethers } from "@usedapp/core/node_modules/ethers";
import { shortenAddress } from "@usedapp/core";
import TokenModal from "../../components/TokenModal/TokenModal";
import {
  defaultChain,
  fetchLocalTokenData,
  getPoolFactoryContract,
  getTransactionUrl,
  mobileBreakpoint,
  supportedNetworks,
  tabletBreakpoint,
  tokenData,
  getGasLimit,
  networks,
  getNetworkData
} from "../../utils/Utils";
import PoolFactoryABI from "../../abi/PoolFactory";
import Spacer from "../../components/Spacer/Spacer";
import AlertBox from "../../components/AlertBox/AlertBox";
import { useSnackbar } from "notistack";
import Alert from "@mui/material/Alert";
import PrivateBorrower from "../../components/PrivateBorrower/PrivateBorrower";
import ClearBorrowers from "./ClearBorrowers/ClearBorrowers";
import PoolSimulation from "../../components/PoolSimulation/PoolSimulation";
import IncrementSelector from "../../components/IncrementSelector/IncrementSelector";
import LicenseTable from "./LicenseTable/LicenseTable";
import PoolFilters from "../../components/PoolFilters/PoolFilters";
import { APP_DATA_CONTEXT, LICENSE_DATA, METHOD_TYPE, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { useNavigate } from "react-router-dom";
import MintRatioWarning from "../../components/MintRatioWarning/MintRatioWarning";
import { WalletDataContext } from "../../context/WalletDataContext";
import { AppDataContext } from "../../context/AppDataContext";
import { useScreenSize } from "../../utils/useScreenSize";

const CreatePool = () => {

  const { provider, chainId, account } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { pending, setPending, tokenPrices } = useContext(AppDataContext) as APP_DATA_CONTEXT;

  const baseURI = "https://vendor.finance/borrow/";

  const ratios = [25, 50, 75];
  const [selectedMintRatio, setSelectedMintRatio] = useState<string | number>(0);
  const [selectedMintRatioButton, setSelectedMintRatioButton] = useState<string | number>("Custom");
  const [selectedExpiry, setSelectedExpiry] = useState(new Date());
  const [selectedAPR, setSelectedAPR] = useState(5);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [showNewPool, setShowNewPool] = useState(false);
  const [selectedInput, setSelectedInput] = useState("0");
  const [deployedPoolAddress, setDeployedPoolAddress] = useState("");
  const [decayingRateToggle, setDecayingRateToggle] = useState(true);
  const [underwaterToggle, setUnderwaterToggle] = useState(false);
  const [privateBorrowersInput, setPrivateBorrowersInput] = useState("");
  const [privateBorrowers, setPrivateBorrowers] = useState<string[]>([]);
  const [selectedLicense, setSelectedLicense] = useState<LICENSE_DATA | undefined>();
  const [showMintRatioWarning, setShowMintRatioWarning] = useState<boolean>(false);
  const [showExpiredLicenses, setShowExpiredLicenses] = useState<boolean>(false);
  const [queryParamsLoaded, setQueryParamsLoaded] = useState<boolean>(false);
  const [confirmedOracleWarning, setConfirmedOracleWarning] = useState<boolean>(false);
  //eslint-disable-next-line
  const [selectedCollateral, setSelectedCollateral] = useState<any>({
    // @ts-ignore
    address: tokenData.WETH.address[defaultChain],
    symbol: "WETH",
    hasOracle: true,
    displayDecimals: 4,
    decimals: 0,
    price: 0,
  });
  //eslint-disable-next-line
  const [selectedLend, setSelectedLend] = useState({
    // @ts-ignore
    address: tokenData.USDC.address[defaultChain],
    symbol: "USDC",
    hasOracle: true,
    displayDecimals: 4,
    decimals: 0,
    price: 0,
  });
  const { enqueueSnackbar } = useSnackbar();
  const { screenWidth } = useScreenSize();
  const navigate = useNavigate();

  // get the token price on load
  useEffect(() => {
    if (chainId) {
      checkChain();
      setPrivateBorrowersInput("");
    }
    // eslint-disable-next-line
  }, [chainId]);

  useEffect(() => {
    getQueryParams();
  // eslint-disable-next-line
  }, [tokenPrices]);

  useEffect(() => {
    if (!selectedLend || selectedLend.address === "") return;
    // reset the lend ratio to prevent the graph from going crazy
    if (!hasQueryParams()) {
      setSelectedMintRatio(0);
      setSelectedMintRatioButton("Custom");
      setUnderwaterToggle(false);
      if (!selectedLend.hasOracle || !selectedCollateral.hasOracle)
        setConfirmedOracleWarning(false);
      else 
        setConfirmedOracleWarning(true);
    }

    // once query params have been loaded remove them from the URL
    // but maintain the chain query
    if (queryParamsLoaded) {
      // add chain query if user is not on arbitrum 
      const chainQuery = chainId === networks.Arbitrum.chainId 
        ? ""
        : `?chain=${getNetworkData(chainId).chainName.toLowerCase()}`
      navigate(`/create-pool${chainQuery}`);
      // oracle warning logic after query params have been loaded
      if (!selectedLend.hasOracle || !selectedCollateral.hasOracle)
        setConfirmedOracleWarning(false);
      else 
        setConfirmedOracleWarning(true);
    }
  // eslint-disable-next-line
  }, [selectedLend, selectedCollateral]);

  // check if there are parameters in the query
  const hasQueryParams = () => {

    // attempt to extract parameters from URL query
    const urlParams = new URLSearchParams(window.location.search);
    const queryLendToken = urlParams.get("lendToken");
    const queryColToken = urlParams.get("colToken");
    const queryLendRatio = urlParams.get("lendRatio");
    const queryExpiry = urlParams.get("expiry");
    const queryFeeRate = urlParams.get("feeRate");
    const queryFeeType = urlParams.get("feeType");
    const queryUndercollateralized = urlParams.get("under");

    if (
      queryLendRatio ||
      queryLendToken || 
      queryColToken ||
      queryLendRatio ||
      queryLendRatio ||
      queryExpiry ||
      queryFeeRate ||
      queryFeeType ||
      queryUndercollateralized
    ) {
      return true;
    } else {
      return false;
    }

  }

  const getQueryParams = () => {

    // extract parameters from URL query
    const urlParams = new URLSearchParams(window.location.search);
    const queryLendToken = urlParams.get("lendToken");
    const queryColToken = urlParams.get("colToken");
    const queryLendRatio = urlParams.get("lendRatio");
    const queryExpiry = urlParams.get("expiry");
    const queryFeeRate = urlParams.get("feeRate");
    const queryFeeType = urlParams.get("feeType");
    const queryUndercollateralized = urlParams.get("under");

    // update pool paramters 
    if (queryLendToken) {
      const lendTokenData = fetchLocalTokenData(queryLendToken, chainId);
      setSelectedLend({
        address: lendTokenData.address[chainId],
        symbol: lendTokenData.symbol,
        hasOracle: lendTokenData.hasOracle,
        displayDecimals: lendTokenData.displayDecimals,
        decimals: lendTokenData.tokenDecimals,
        price: tokenPrices[lendTokenData.address],
      });
    }
    if (queryColToken) {
      const colTokenData = fetchLocalTokenData(queryColToken, chainId);
      setSelectedCollateral({
        address: colTokenData.address[chainId],
        symbol: colTokenData.symbol,
        hasOracle: colTokenData.hasOracle,
        displayDecimals: colTokenData.displayDecimals,
        decimals: colTokenData.tokenDecimals,
        price: tokenPrices[colTokenData.address],
      })
    }
    if (queryLendRatio) {
      const ratio = ethers.utils.formatUnits(queryLendRatio, 18);
      setSelectedMintRatio(ratio);
    }
    if (queryExpiry) {
      const expiry = queryExpiry.length === 10 
        ? new Date(Number(queryExpiry) * 1000) 
        : new Date(Number(queryExpiry));
      setSelectedExpiry(expiry);
    }
    if (queryFeeRate) setSelectedAPR(Number(queryFeeRate || 0) / 10000);
    if (queryFeeType) setDecayingRateToggle(queryFeeType === "2");
    if (queryUndercollateralized) setUnderwaterToggle(queryUndercollateralized === "true");
    setQueryParamsLoaded(true);
  }

  const checkChain = () => {
    // setup default lend asset based on wallet chainId or lack thereof 
    const lendTokenData:any = tokenData.USDC;
    let colTokenData:any;
    if (chainId === networks.ArbitrumGoerli.chainId)
      colTokenData = tokenData.WBTC;
    else 
      colTokenData = tokenData.WETH;
    setSelectedCollateral({
      address: colTokenData.address[chainId ? chainId : defaultChain],
      symbol: colTokenData.symbol,
      hasOracle: true,
      displayDecimals: colTokenData.displayDecimals,
      decimals: colTokenData.tokenDecimals,
      price: 0
    })
    setSelectedLend({
      address: lendTokenData.address[chainId ? chainId : defaultChain],
      symbol: lendTokenData.symbol,
      hasOracle: true,
      displayDecimals: lendTokenData.displayDecimals,
      decimals: lendTokenData.tokenDecimals,
      price: 0
    });
  }

  // deploy new pool
  const deploy = async () => {
    if (!supportedNetworks.includes(chainId as number) || !provider) return;
    setPending(true);
    const poolFactoryContract = getPoolFactoryContract(
      provider.getSigner() as any,
      chainId as number
    );
    const feeRate = Number(selectedAPR) * 10000;
    // const timestamp = new Date(selectedExpiry.replace('-','/')) / 1000
    const timestamp = Math.trunc(new Date(selectedExpiry).getTime() / 1000);
    // call deploy function
    try {
      enqueueSnackbar("Please confirm the transaction in your wallet", {
        persist: false,
      });
      // force the pool to be undercollateralized if either token doesn't have an oracle
      const forceUndercollateralized = !selectedCollateral.hasOracle || !selectedLend.hasOracle;
      console.log(
        ethers.utils.parseEther(selectedMintRatio.toString()).toString(),
        selectedCollateral.address,
        selectedLend.address,
        feeRate,
        decayingRateToggle ? 2 : 1,
        timestamp,
        privateBorrowers,
        underwaterToggle || forceUndercollateralized ? 1: 0,
        selectedLicense ? selectedLicense.id : 0
      );
      const tx = await poolFactoryContract.deployPool(
        ethers.utils.parseEther(selectedMintRatio.toString()).toString(),
        selectedCollateral.address,
        selectedLend.address,
        feeRate,
        decayingRateToggle ? 2 : 1,
        timestamp,
        privateBorrowers,
        underwaterToggle || forceUndercollateralized ? 1: 0,
        selectedLicense ? selectedLicense.id : 0,
        {
          ...getGasLimit(chainId, METHOD_TYPE.DEPLOY)
        }
      );
      enqueueSnackbar(`Executing pool creation transaction ** ${getTransactionUrl(tx.transactionHash, chainId)} ** persist`);
      let receipt = await tx.wait();
      let iface = new ethers.utils.Interface(PoolFactoryABI);
      let log = iface.parseLog(receipt.logs[2]);
      const poolAddress = log.args[0];
      setDeployedPoolAddress(poolAddress);
      setShowNewPool(true);
      // close modal
      setShowReviewModal(false);
      setPending(false);
      enqueueSnackbar(`Pool creation transaction successful ** ${getTransactionUrl(receipt.transactionHash, chainId)}`);
    } catch (e) {
      console.log(e);
      setPending(false);
      enqueueSnackbar(`Pool creation transaction failed`, {
        persist: false,
      });
    }
  };

  const calculateMintRatio = (buttonValue: number) => {
    const colPrice = (tokenPrices[selectedCollateral.address] || 0);
    const lendPrice = (tokenPrices[selectedLend.address] || 0);
    if (colPrice === 0 || lendPrice === 0) return 0;
    // get a percentage value of the collateral price
    const colValue = colPrice * (buttonValue / 100);
    // get how many lend tokens are needed to match that percent value 
    const lendValue = colValue / lendPrice;
    // different precision for different token pairs
    const res = Number(lendValue.toFixed(Math.max(selectedLend.displayDecimals, selectedCollateral.displayDecimals)));
    return (res);
  }

  const mintRatioClicked = (value: any) => {
    setSelectedMintRatioButton(value);
    if (value === "Custom") {
      setSelectedMintRatio("");
      const input = document.querySelector("input.lend-ratio-input") as HTMLInputElement;
      if (input) input.focus();
      return;
    } else {
      setSelectedMintRatio(calculateMintRatio(value));
    }
  };

  const renderOracleWarning = () => {
    if (!selectedLend.hasOracle || !selectedCollateral.hasOracle) {
      return (
        <Alert severity="warning" className="undercollateralized-alert">
          One or more tokens you have selected do not have an on-chain oracle.
          This means that in case of serious price fluctuations your pool will
          not be paused automatically. Please exercise caution and be aware that
          you can always pause borrowing for the pool on the My Pools page
        </Alert>
      );
    } else if (isPoolUndercollateralized() || underwaterToggle) {
      return (
        <Alert severity="error" className="protocol-fee-message">
          Your pool has undercollateralized borrowing enabled! This can result
          in funds being lost. Only proceed if you understand the risks! Keep in
          mind that the custom lend ratio input is the amount of tokens lent out, not a
          percent.
        </Alert>
      );
    }
  }

  // populate transaction review field
  const getTransactionInfo = () => {
    return (
      <Fragment>
        <div className="info-item">
          <span className="info-left">Collateral Token:</span>
          <span>{selectedCollateral.symbol}</span>
        </div>
        <div className="info-item">
          <span className="info-left">Lend Token:</span>
          <span>{selectedLend.symbol}</span>
        </div>
        <div className="info-item">
          <span className="info-left">Lend Ratio:</span>
          <span>{selectedMintRatio}</span>
        </div>
        <div className="info-item">
          <span className="info-left">Term Rate:</span>
          <span>{selectedAPR}%</span>
        </div>
        <div className="info-item">
          <span className="info-left">Expiry:</span>
          <span>{moment(selectedExpiry).format("MMM D, Y hh:mm a")}</span>
        </div>
        <div className="info-item">
          <span className="info-left">Decaying Rate Enabled: </span>
          <span>{decayingRateToggle ? "Yes" : "No"}</span>
        </div>
        <div className="info-item">
          <span className="info-left">Private Borrowing Enabled: </span>
          <span>{privateBorrowers.length > 0 ? "Yes" : "No"}</span>
        </div>
        <div className="info-item">
          <span className="info-left">Undercollateralized Loans Enabled:</span>
          <span>{underwaterToggle ? "Yes" : "No"}</span>
        </div>
        {renderOracleWarning()}
      </Fragment>
    );
  };

  const managePrivateBorrowersInput = () => {
    const el = document.querySelector(".private-borrowers-input") as HTMLInputElement;
    if (!el) return;
    let text = el.value;
    // update the input text
    setPrivateBorrowersInput(text);

    // extract borrowers from input 
    let borrowers = text.split(",").map(e => String(e).trim()).filter(e => e.trim().length === 42);

    // update private borrowers list
    if (borrowers.length > 0) {
      let newText = text;
      let validBorrowers:string[] = [...privateBorrowers];
      let fails = 0;
      text.split(",").forEach((_borrower) => {
        const borrower:string = _borrower.trim();
        try {
          // attempt to shorten the address
          shortenAddress(borrower);
          // if possible, the address is valid (according to ethers)
          validBorrowers.push(borrower);
          // clean up input
          newText = newText.replace(`${borrower}`, "").trim();
          newText = newText.replace(`, `, "").trim();
        } catch {
          fails++;
        }
      });

      // notify user of any invalid addresses
      if (fails > 0) {
        enqueueSnackbar("One or more of the private borrower addresses is invalid.", {
          persist: false,
        });
      }
      setPrivateBorrowersInput(newText);
      //@ts-ignore
      setPrivateBorrowers([...new Set(validBorrowers)]);
    } 
  }

  const privateBorrowersInputChanged = () => {
    managePrivateBorrowersInput(); 
  }

  const verifyPrivateBorrowersInput = () => {
    let borrowers = privateBorrowers;
    let preLength = borrowers.length;

    // are there any private borrowers?
    // if so, are the addresses valid?
    if (borrowers.length === 0) 
      return true;
    else
      // TODO: actually validate addresses 
      borrowers = borrowers.filter((e:string) => e.trim().length === 42)

    // list is verified if there are valid borrowers on the list
    return borrowers.length > 0 && borrowers.length === preLength;
  }

  // check if the parameters would make the pool undercollateralized 
  const isPoolUndercollateralized = () => {
    const colPrice = tokenPrices[selectedCollateral.address];
    const lendPrice = tokenPrices[selectedLend.address];
    const borrowValue = Number(selectedMintRatio) * lendPrice;
    return (borrowValue > colPrice)
  }

  const getActionButtonTitle = () => {
    const future24h = new Date().getTime() + 60 * 60 * 24 * 1000;
    let title = ""
    if (!provider || provider === null)
      title = ("No Wallet Connected");
    else if (showReviewModal && !pending)
      title = ("Deploy")
    else if (showReviewModal && pending)
      title = ("Deploying, please wait for confirmation window");
    else if (new Date(selectedExpiry).getTime() < new Date().getTime())
      title = ("Invalid Expiry");
    else if (new Date(selectedExpiry).getTime() < future24h)
      title = ("Invalid Expiry");
    else if (Number(selectedAPR) < 0)
      title = ("Invalid Term Rate")
    else if (Number(selectedMintRatio) <= 0)
      title = ("Invalid Lend Ratio")
    else if (selectedCollateral.symbol === selectedLend.symbol)
      title = ("Invalid Collateral/Lend Pair");
    else if (!verifyPrivateBorrowersInput())
      title = ("Invalid Private Borrower List");
    else if (isPoolUndercollateralized() && !underwaterToggle)
      title = ("Pool Lend Ratio is Too High");
    else 
      title = ("Review Transaction");

    return (title);
  }

  const getConfirmButton = () => {
    if (!selectedCollateral.hasOracle || !selectedLend.hasOracle)
      return (
        <div className="create-pool-confirm-button">
          <ActionButton
            title={"I understand"}
            action={() => setConfirmedOracleWarning(true)}
            disabled={confirmedOracleWarning}
          />
        </div>
      );
    else return <></>
  }

  const getActionButton = (title: string, action: () => void) => {
    let disabled = false;
    const future24h = new Date().getTime() + 60 * 60 * 24 * 1000;
    // review button disable logic
    // if (title.indexOf("Review") !== -1) {
      disabled =
        disabled ||
        new Date(selectedExpiry).getTime() < new Date().getTime() ||
        new Date(selectedExpiry).getTime() < future24h || 
        Number(selectedAPR) < 0 ||
        Number(selectedMintRatio) <= 0 ||
        selectedCollateral.symbol === selectedLend.symbol ||
        (isPoolUndercollateralized() && !underwaterToggle) || 
        !verifyPrivateBorrowersInput() ||
        pending ||
        (showReviewModal && !confirmedOracleWarning) ||
        !provider
    // }
    return (
      <ActionButton
        title={title}
        action={action}
        disabled={disabled}
      />
    );
  };

  const renderToggles = () => {
    return (
      <div className="toggles-container">
        <div className="toggle-wrapper">
          <ReactTooltip 
            id="decaying-pool-tip" 
            type="info" 
            className="tool-tip"
            effect={screenWidth > tabletBreakpoint ? "float" : "solid"}
          >
            <span>
              When decay rate toggle is on you are selecting the annualized
              rate. Ex: if you select 10% the effective rate will automatically
              decrease as the pool approaches expiry to make sure that borrowers
              always borrow at 10% when annualized
            </span>
          </ReactTooltip>
          <div
            className={`toggle-wrapper-switch ${
              decayingRateToggle ? "checked" : ""
            }`}
            onClick={() => setDecayingRateToggle(!decayingRateToggle)}
          >
            <input
              type="checkbox"
              className="fee-checkbox"
              checked={decayingRateToggle}
              onChange={() => {}}
            />
            <div className="toggle-wrapper-slider"></div>
          </div>
          <span
            className="toggle-wrapper-label"
          >
            Use Decaying Rate <HelpCircle data-tip data-for="decaying-pool-tip"/>
          </span>
        </div>
        <div className="toggle-wrapper">
          <ReactTooltip
            id="underwater-pool-tip"
            type="info"
            className="tool-tip"
            effect={screenWidth > tabletBreakpoint ? "float" : "solid"}
            place="top"
          >
            <span>
              When the underwater borrowing toggle is on you are allowing
              borrowers to borrow if the collateral price falls below the
              selected lend ratio. This also allows for undercollateralized
              loans. Only enable this if you understand the risks!
            </span>
          </ReactTooltip>
          <div
            className={`toggle-wrapper-switch ${
              underwaterToggle ? "checked" : ""
            }`}
            onClick={() => setUnderwaterToggle(!underwaterToggle)}
          >
            <input
              type="checkbox"
              className="fee-checkbox"
              checked={underwaterToggle}
              onChange={() => {}}
            />
            <div className="toggle-wrapper-slider"></div>
          </div>
          <span
            className="toggle-wrapper-label"
          >
            Allow Undercollateralized Borrowing <HelpCircle data-tip data-for="underwater-pool-tip"/>
          </span>
        </div>
      </div>
    );
  }

  const privateBorrowersWrapperClicked = () => {
    const el = document.querySelector(".private-borrowers-input") as HTMLInputElement;
    el.focus();
  }

  const renderPrivateBorrowersInput = () => {
    return (
      <div className="info-section-container">
        <ReactTooltip 
          id="private-pool-tip" 
          type="info" 
          className="tool-tip"
          place={screenWidth > mobileBreakpoint ? "top" : "right"}
        >
          <span>
            Comma separated list of addresses that will be allowed to borrow
            from this pool. Leave this blank if anyone is allowed to borrow from
            this pool
          </span>
        </ReactTooltip>
        <div className="info-title-container">
          <div
            className="info-title disable-select"
            data-tip
            data-for="private-pool-tip"
          >
            Borrowers <HelpCircle />
          </div>
          {privateBorrowers.length > 0 && (
            <ClearBorrowers
              setPrivateBorrowers={setPrivateBorrowers}
              setPrivateBorrowersInput={setPrivateBorrowersInput}
            />
          )}
        </div>
        <div
          className="private-borrowers-wrapper"
          onClick={() => privateBorrowersWrapperClicked()}
        >
          <input
            type="string"
            placeholder="Addresses Separated By Commas"
            className="private-borrowers-input"
            value={privateBorrowersInput}
            onChange={privateBorrowersInputChanged}
          />
          {privateBorrowers.map((borrower, index) => (
            <PrivateBorrower
              address={borrower}
              setPrivateBorrowers={setPrivateBorrowers}
              privateBorrowers={privateBorrowers}
              key={index}
            />
          ))}
        </div>
      </div>
    );
  }

  const renderPoolFilters = () => {
    return (
      <Fragment>
        <div className="pool-filters-filter-wrapper expired-license-filter bounce" onClick={() => setShowExpiredLicenses(!showExpiredLicenses)}>
          <span>Show {showExpiredLicenses ? "Active" : "Expired"} Licenses</span>
        </div>
      </Fragment>
    )
  }

  // notify user the risks of creating a pool with plsARB
  const renderPlsWarning= () => {
    if (
      selectedLend.address.toLowerCase() === tokenData.PLSARB.address[42161].toLowerCase() ||
      selectedCollateral.address.toLowerCase() === tokenData.PLSARB.address[42161].toLowerCase()
    )
      return (
        <div className="arb-token-warning">
          <Alert severity="warning" className="alert-box">
            Please be very careful with the Lend Ratio you select. The price being shown
            for plsARB is the price of ARB. plsARB is not guaranteed to have a 1:1 peg of
            ARB, and the price against ARB may flucuate once the Plutus team launches
            a plsARB/ARB pool
          </Alert>
        </div>
      );
    else return null;
  }

  return (
    <div className="create-pool-wrapper fade-in">
      <MintRatioWarning
        setSelectedMintRatio={setSelectedMintRatio}
        setShowMintRatioWarning={setShowMintRatioWarning}
        showMintRatioWarning={showMintRatioWarning}
        deploy={deploy}
      />
      <div className="create-pool-left left-section fade-in">
        {screenWidth > tabletBreakpoint &&
          <PoolSimulation
            colToken={selectedCollateral.address}
            lendToken={selectedLend.address}
            mintRatio={selectedMintRatio}
          />
        }
        <PoolFilters
          setSearchValue={() => {}}
          children={renderPoolFilters()}
        />
        <LicenseTable
          selectedLicense={selectedLicense}
          setSelectedLicense={setSelectedLicense}
          showExpiredLicenses={showExpiredLicenses}
          pending={pending}
          provider={provider}
          account={account}
          chainId={chainId}
        />
      </div>
      <div className="create-pool-right fade-in">
        {screenWidth < tabletBreakpoint &&
          <PoolSimulation
            colToken={selectedCollateral.address}
            lendToken={selectedLend.address}
            mintRatio={selectedMintRatio}
          />
        }
        <TokenModal
          showModal={showTokenModal}
          setShowModal={setShowTokenModal}
          setSelectedCollateral={setSelectedCollateral}
          setSelectedLend={setSelectedLend}
          selectedInput={selectedInput}
          selectedCollateral={selectedCollateral}
          selectedLend={selectedLend}
        />
        <div className="widget-wrapper view-section fade-in main-container-shadow">
          <div className="token-input-container token-dropdown">
            <div className="inner-token-input-container">
              <div className="row-title">Collateral Token</div>
              <div className="token-selector-long-wrapper">
                <TokenSelector
                  value={(tokenPrices[selectedCollateral.address] || 0).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: selectedCollateral.displayDecimals,
                    }
                  )}
                  symbol={selectedCollateral.symbol}
                  id="0"
                  select={true}
                  text=""
                  setShowModal={setShowTokenModal}
                  setSelectedInput={setSelectedInput}
                ></TokenSelector>
              </div>
            </div>
            <div className="inner-token-input-container">
              <div className="row-title">Lend Token</div>
              <div className="token-selector-long-wrapper">
                <TokenSelector
                  value={(tokenPrices[selectedLend.address] || 0).toLocaleString(undefined, {
                    minimumFractionDigits: selectedLend.displayDecimals,
                  })}
                  symbol={selectedLend.symbol}
                  id="1"
                  select={true}
                  text=""
                  setShowModal={setShowTokenModal}
                  setSelectedInput={setSelectedInput}
                ></TokenSelector>
              </div>
            </div>
          </div>
          <div className="info-section-container block">
            <ReactTooltip 
              id="lend-ratio-tip" 
              type="info" 
              className="tool-tip"
              place={screenWidth > tabletBreakpoint ? "top" : "right"}
              effect={screenWidth > tabletBreakpoint ? "float" : "solid"}
              arrowColor="var(--iris)"
            >
              <span>
                Amount of {selectedLend.symbol} that is lent per 1 unit of{" "}
                {selectedCollateral.symbol}
              </span>
            </ReactTooltip>
            <ReactTooltip 
              id="custom-lend-ratio-tip" 
              type="info" 
              className="tool-tip"
              place={screenWidth > tabletBreakpoint ? "top" : "top"}
              effect={screenWidth > tabletBreakpoint ? "float" : "solid"}
            >
              <span>
                Custom amount of {selectedLend.symbol} that is lent per 1 unit of{" "}
                {selectedCollateral.symbol}. This is <em>not</em> a percent.
              </span>
            </ReactTooltip>
            <div className="info-title lend-ratio-title" >
              <span>
                Lend Ratio <HelpCircle data-tip data-for="lend-ratio-tip"/>
              </span>
              <span>
                # of tokens <HelpCircle data-tip data-for="custom-lend-ratio-tip"/>
              </span>
            </div>
            <div className="lend-ratio-selector">
              {ratios.map((ratio, index) => (
                <button
                  className={`lend-ratio-option ${
                    selectedMintRatioButton === ratio ? "active" : ""
                  }`}
                  onClick={() => mintRatioClicked(ratio)}
                  key={index}
                >
                  {ratio}%
                </button>
              ))}
              <button
                className={`lend-ratio-option ${
                  selectedMintRatioButton === "Custom" ? "active" : ""
                }`}
                onClick={() => mintRatioClicked("Custom")}
              >
                Custom
              </button>
              <input
                type="number"
                placeholder="Amount"
                className="lend-ratio-input"
                value={selectedMintRatio}
                onChange={(e) => {
                  mintRatioClicked("Custom");
                  setSelectedMintRatio(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="info-section-container">
            <div className="info-title-container">
              <ReactTooltip 
                id="expiry-tip" 
                type="info" 
                className="tool-tip"
                place={screenWidth > mobileBreakpoint ? "top" : "right"}
                arrowColor="var(--iris)"
              >
                <span>
                  Date by which the loan has to be repaid or rolled over.
                  Partial payments are supported
                </span>
              </ReactTooltip>
              <ReactTooltip id="apr-tip" type="info" className="tool-tip">
                <span>
                  The rate at which your {selectedLend.symbol} will be lent out
                  to your users
                </span>
              </ReactTooltip>
              <div
                className="info-title disable-select"
                data-tip
                data-for="expiry-tip"
              >
                Expiry <HelpCircle />
              </div>
              <div
                className="info-title disable-select apr-title"
                data-tip
                data-for="apr-tip"
              >
                {decayingRateToggle ? `Annual Rate (APR)` : "Term Rate"}
                <HelpCircle />
              </div>
            </div>
            <div className="info-container">
              <Selector
                text={selectedExpiry}
                value={selectedExpiry}
                setValue={setSelectedExpiry}
                type={"date"}
                disabled={false}
              ></Selector>
              <IncrementSelector
                value={selectedAPR}
                setValue={setSelectedAPR}
              />
            </div>
          </div>
          {renderToggles()}
          {renderPrivateBorrowersInput()}
          <Spacer spacing={"0.2em"} />
          {renderPlsWarning()}
          {getActionButton(getActionButtonTitle(), () => setShowReviewModal(true))}
        </div>
        <Alert severity="info" className="protocol-fee-message">
          Vendor Finance takes 10% of all earned interest and 3% of any
          defaulted collateral
        </Alert>
        <TransactionReview
          transactionInfo={getTransactionInfo()}
          confirmButton={getConfirmButton()}
          actionButton={getActionButton(getActionButtonTitle(), deploy)}
          modal={true}
          showReview={showReviewModal}
          setShowReview={setShowReviewModal}
        />
        <AlertBox
          title="Pool Created!"
          message={baseURI + deployedPoolAddress}
          show={deployedPoolAddress !== ""}
          showNewPool={showNewPool}
          setShowNewPool={setShowNewPool}
          shrink={true}
        />
      </div>
    </div>
  );
};

export default CreatePool;
