import { ethers } from "ethers";
import { useContext, useEffect, useState } from "react";
import { ChevronDown, Info, Search } from "react-feather";
import { AppDataContext } from "../../context/AppDataContext";
import { WalletDataContext } from "../../context/WalletDataContext";
import { APP_DATA_CONTEXT, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { fetchTokenLogo, tokenData } from "../../utils/Utils";
import Dropdown from "../Dropdown/Dropdown";
import Tab from "../Tab/Tab";
import "./TokenFilter.css";

const TokenFilter = (props: {
  setColFilter: (tokens: string[]) => void,
  setLendFilter: (tokens: string[]) => void,
  colFilter: string[],
  lendFilter: string[],
}) => {

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [loadedToken, setLoadedToken] = useState<any>();
  const [supportedTokens, setSupportedTokens] = useState<any>({});
  const [overrideTokens, setOverrideTokens] = useState<string[]>([])
  const [mode, setMode] = useState<"deposit" | "borrow">("deposit");
  const { chainId } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { tokenPrices } = useContext(AppDataContext) as APP_DATA_CONTEXT;

  useEffect(() => {
    // don't update if either filter is empty
    if (props.colFilter.length > 0 && props.lendFilter.length > 0)
      saveSelectedTokens();
  // eslint-disable-next-line
  }, [props.colFilter, props.lendFilter]);

  useEffect(() => {
    getSupportedTokens();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // dynamically load tokens 
    if (loadedToken !== undefined) {
      let data = {
        ...supportedTokens,
        [`${loadedToken.symbol}`]: loadedToken
      }
      setSupportedTokens(data);
    }
  // eslint-disable-next-line
  }, [loadedToken]);

  // save the selected token filters to localStorage
  const saveSelectedTokens = () => {
    window.localStorage.setItem(
      'colFilter',
      JSON.stringify(props.colFilter)
    )
    window.localStorage.setItem(
      'lendFilter',
      JSON.stringify(props.lendFilter)
    )
  }

  // update the list of selected tokens
  const updateSelectedTokens = (symbol: string) => {
    // get copies of token lists 
    let selectedTokens = mode === "borrow" ? [...props.lendFilter] : [...props.colFilter];
    // if token is already selected remove from list
    if (selectedTokens.includes(symbol)) {
      const index = selectedTokens.indexOf(symbol);
      selectedTokens.splice(index, 1);
      // if user unchecks an overriden token, save to localstorage
      if (overrideTokens?.includes(symbol)) {
        window.localStorage.setItem(
          "uncheckedOverride",
          "true"
        )
      }
    } else {
      // if not selected, add token to list
      selectedTokens = [...selectedTokens, symbol];
    }
    // update state in parent component 
    if (mode === "borrow")
      props.setLendFilter(selectedTokens);
    else 
      props.setColFilter(selectedTokens);
  }

  // render the token row with checkbox, icon, symbol, and price
  const renderToken = (symbol: string, price: number) => {
    const selectedTokens = mode === "borrow" ? props.lendFilter : props.colFilter;
    return (
      <div 
        className="token-filter-token-wrapper"
        onClick={() => updateSelectedTokens(symbol)}
      >
        <div className="token-filter-token-left">
          <input
            type={"checkbox"}
            readOnly
            checked={selectedTokens.indexOf(symbol) > -1}
            onClick={() => updateSelectedTokens(symbol)}
          />
          <img
            src={fetchTokenLogo(symbol)}
            alt={symbol}
          />
          <span>{symbol}</span>
        </div>
        <span
          className="token-filter-token-price"
        >
          ${price.toLocaleString(undefined, {minimumFractionDigits:3, maximumFractionDigits: 3})}
        </span>
      </div>
    );
  }

  // render the list of token rows
  const renderTokens = () => {
    // check if there are any tokens included in the search
    const tokensInSearch = Object.entries(supportedTokens).filter((data: any[]) => 
      data[0].toUpperCase().indexOf(searchValue.toUpperCase()) > -1
    ).length;
    if (tokensInSearch > 0) {
      return (
        <div className="supported-tokens-wrapper">
          {Object.entries(supportedTokens).map((data: any[], index: number) => {
            try {
              // extract token data from element
              const symbol = data[0].toUpperCase();
              const tokenData = data[1];
              const price = tokenPrices[ethers.utils.getAddress(tokenData.address[chainId])];
              // check if current token is in search and if it's supported on the current chain
              if (
                symbol.toUpperCase().indexOf(searchValue.toUpperCase()) > -1 &&
                tokenData.supportedNetworks.includes(chainId)
              ) 
                return (renderToken(symbol, price));
              else return null;
            } catch (e) {
              return null;
            }
          })}
        </div>
      )
    } else {
      // if no tokens included in search, show no tokens found message
      return (
        <div className="no-tokens-found-message">
          <Info/>
          No Tokens Found
        </div>
      );
    }
  }

  // render the search bar and search icon
  const renderSearch = () => {
    return (
      <div className="token-filter-search-wrapper">
        <input
          placeholder="Search Tokens"
          className="token-filter-search"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Search className="token-filter-search-icon"/>
      </div>
    )
  }

  // get a list of the supported tokens on the chain
  const getSupportedTokens = async () => {
    for (const token in tokenData) {
      // @ts-ignore
      if (!tokenData[token].address[chainId] || tokenData[token].address[chainId].length === 0) {
        continue;
      };
      //@ts-ignore
      let supported = await tokenData[token].supportedNetworks.includes(chainId);
      //@ts-ignore
      if (supported) setLoadedToken(tokenData[token]);
      //@ts-ignore
      if (tokenData[token].overrideFilter) {
        //@ts-ignore
        setOverrideTokens([...overrideTokens, tokenData[token].symbol.toUpperCase()]);
      }
    }
  }

  const selectAll = () => {
    // get a copy of selected tokens
    let selectedTokens = mode === "borrow" ? [...props.lendFilter] : [...props.colFilter];
    if (selectedTokens.length === 0) {
      // if there are 0 tokens selected, select all of them
      Object.entries(supportedTokens).forEach((data: any[], index: number) => {
        const symbol = data[0].toUpperCase();
        selectedTokens.push(symbol);
      });
    } else {
      // if there are any tokens selected, unselect all of them 
      selectedTokens = [];
    }

    // update state in parent component 
    if (mode === "borrow")
      props.setLendFilter(selectedTokens);
    else 
      props.setColFilter(selectedTokens);
  }

  // render the "Select/Deselect All" button
  const renderSelectAllButton = () => {
    // get a copy of selected tokens
    const selectedTokens = mode === "borrow" ? [...props.lendFilter] : [...props.colFilter];
    return (
      <div 
        className="token-filter-select-all"
        onClick={selectAll}
      >
        {selectedTokens.length === 0 ? "Select All" : "Deselect All"}
      </div>
    )
  }

  return (
    <div 
      className={`token-filter-wrapper pool-filters-filter-wrapper`}
      onClick={() => {
        if (!showDropdown) setShowDropdown(true)
      }}
    >
      <span>Tokens</span>
      <ChevronDown/>
      {showDropdown &&
        <Dropdown setShowDropdown={setShowDropdown} showDropdown={showDropdown}>
          <section className="token-filter-top-section">
            <Tab mode={mode}>
              <span className="tab-item" onClick={() => setMode("deposit")}>
                Deposit 
              </span>
              <span className="tab-item" onClick={() => setMode("borrow")}>
                Borrow 
              </span>
            </Tab>
            {renderSearch()}
            {renderSelectAllButton()}
          </section>
          <section className="token-filter-bottom-section">
            {renderTokens()}
          </section>
        </Dropdown>
      }
    </div>
  );
}

export default TokenFilter;