import { ethers } from "@usedapp/core/node_modules/ethers";
import moment from "moment";
import { Fragment, useEffect, useContext, useRef, useState } from "react";
import { Bell, MoreHorizontal, MoreVertical } from "react-feather";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { AppDataContext } from "../../context/AppDataContext";
import { PoolDataContext } from "../../context/PoolDataContext";
import { WalletDataContext } from "../../context/WalletDataContext";
import { APP_DATA_CONTEXT, LOCAL_TOKEN_DATA, POOL_DATA_CONTEXT, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import {
  fetchTokenLogo,
  renderLazyLoad,
  getCalendarLink,
  fetchIcon,
  generateUid,
  tabletBreakpoint,
  fetchLocalTokenData,
  mobileBreakpoint,
  getNetworkData,
  getChainQuery,
  checkQueryPool,
} from "../../utils/Utils";
import { useScreenSize } from "../../utils/useScreenSize";
import Dropdown from "../Dropdown/Dropdown";
import PoolStats from "../PoolStats/PoolStats";
import PoolStatusContainer from "../PoolStatusContainer/PoolStatusContainer";
import "./PoolRow.css";
declare var window: any;

const PoolRow = (props: {
  pool: any, 
  myPools: boolean,
  mode?: string,
  selectedFeaturedPools?: any,
}) => {
  const [ltv, setLtv] = useState<number>(0);
  const [feeRate, setFeeRate] = useState<number>(0);
  const [annualizedFeeRate, setAnnualizedFeeRate] = useState<number>(0);
  const [isSelectedPool, setIsSelectedPool] = useState<boolean>(false);
  const [effectiveRate, setEffectiveRate] = useState<number>(0);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [showPoolStats, setShowPoolStats] = useState<boolean>(false);
  const [queryChecked, setQueryChecked] = useState<boolean>(false);

  // get contexts
  const { calculateLTV, tokenPrices, fullPoolData } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { selectedPool, setSelectedPool } = useContext(PoolDataContext) as POOL_DATA_CONTEXT;
  const { chainId } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { screenWidth } = useScreenSize();

  // create UID and remove numbers
  const rowUid = useRef<string>(generateUid());

  useEffect(() => {
    parsePoolData();
    if (selectedPool) setIsSelectedPool(selectedPool.id === props.pool.id);
  // eslint-disable-next-line
  }, [props.pool]);

  // eslint-disable-next-line
  useEffect(() => {
    // getPoolData();
  }, []);

  // get the LTV label for coloring LTV bubbles
  const getLTVLabel = (ltv: number) => {
    if (ltv >= 0 && ltv < 45)
      return "low";
    else if (ltv >= 45 && ltv < 65)
      return "mid";
    else if (ltv >= 65)
      return "high";
  }

  // parse some of the pool data from the pool object
  const parsePoolData = async () => {
    const pool = fullPoolData[props.pool.id] ? fullPoolData[props.pool.id] : props.pool;
    const formattedFeeRate = Number(pool.currentFeeRate || 0) / 10000;
    const formattedAnnualRate = Number(pool.annualizedFeeRate || 0) / 10000
    const formattedEffectiveRate = Number(pool.effectiveRate || 0) / 10000;
    setLtv(calculateLTV(props.pool));
    setIsExpired(Number(props.pool._expiry) * 1000 < new Date().getTime());
    setFeeRate(formattedFeeRate);
    setEffectiveRate(formattedEffectiveRate);
    setAnnualizedFeeRate(formattedAnnualRate);
    if (!queryChecked) {
      // check if this pool is the selected pool from the URL
      if (checkQueryPool(props.pool.id)) setSelectedPool(props.pool);
    }
    setQueryChecked(true);
  }

  // pseudo-polyfill for detecting if options menu is in
  // the clicked element composed path
  const checkOptionClicked = (target: any) => {
    if (
      target.tagName === "svg" || 
      target.tagName === "circle" ||
      target.className.indexOf("option-column") > -1
    ) 
      return true;
    else 
      return false;
  }

  // set selected pool if a row is clicked
  const poolClicked = (e: any) => {
    // special polyfill logic for mobile
    if (screenWidth < tabletBreakpoint) {
      const optionClicked = checkOptionClicked(e.nativeEvent.target);
      if (!optionClicked && props.pool.externalDataLoaded) setSelectedPool(props.pool);
    } else {
      if (props.pool.externalDataLoaded || props.myPools)
        setSelectedPool(props.pool);
    }
  }

  // render calendar component to allow set reminders 
  const renderCalendar = () => {
    const expiry = props.pool._expiry * 1000;
    const colSymbol = props.pool.colSymbol;
    const lendSymbol = props.pool.lendSymbol;
    const text = `Repay Debt for ${colSymbol}/${lendSymbol} Pool`;

    // only render on myPools
    if (props.myPools)
      return (
        <Fragment>
          <ReactTooltip id={`alert-tip-${props.pool.id}`} type="info" className="tool-tip">
            Create alert for expiry 
          </ReactTooltip>
          <Bell
            className="expiry-calendar"
            data-tip data-for={`alert-tip-${props.pool.id}`}
            onClick={() => {
              window.open(getCalendarLink(expiry, text), "_blank")
            }}
          />
        </Fragment>
      );
  }

  const renderExpiryText = () => {

    // check if the pool is expired or within 48 hours of expiry
    const now = new Date();
    const expiry = (props.pool._expiry * 1000);
    const future48h = new Date(now.setHours(now.getHours() + 48));
    const nearExpiry = future48h.getTime() >= expiry;
    let dateFormatting;

    if (screenWidth > mobileBreakpoint) {
      dateFormatting = (
        <span className={`pool-row-column-label`}>
          {moment(new Date(expiry)).format("MMM D, Y hh:mm a")}
        </span>
      )
    } else {
      dateFormatting = (
        <div className="mobile-expiry-wrapper">
          <span className={`pool-row-column-label`}>
            {moment(new Date(expiry)).format("MMM D, Y")}
          </span>
          <span className={`pool-row-column-label mobile-expiry-time`}>
            {moment(new Date(expiry)).format("hh:mm a")}
          </span>
        </div>
      )
    }

    if (isExpired) {
      return (
        <Fragment>
          <ReactTooltip id={`expired-pool-tip-${future48h.getTime()}`} type="info" className="tool-tip">
            <span>
              This pool is expired
            </span>
          </ReactTooltip>
          <span className={`pool-row-column-label expired`} data-tip data-for={`expired-pool-tip-${future48h.getTime()}`}>
            {dateFormatting}
          </span>
        </Fragment>
      );
    } else if (nearExpiry && !isExpired) {
      return (
        <Fragment>

          {renderCalendar()}
          <ReactTooltip id={`near-expiry-pool-tip-${future48h.getTime()}`} type="info" className="tool-tip">
            <span>
              This pool expires within the next 48 hours
            </span>
          </ReactTooltip>
          <span className={`pool-row-column-label near-expiry`} data-tip data-for={`near-expiry-pool-tip-${future48h.getTime()}`}>
            {dateFormatting}
          </span>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {renderCalendar()}
          <span className={`pool-row-column-label`}>
            {dateFormatting}
          </span>
        </Fragment>
      );
    }
  }

  // render different values for lender vs. borrower
  const renderAvailableColumn = () => {
    const lendTokenData:LOCAL_TOKEN_DATA = fetchLocalTokenData(props.pool._lendToken, chainId) as any;
    // return nothing if lend data doesn't exist
    if (!lendTokenData) return <></>;
    let formattedAvailable = ethers.utils.formatUnits(props.pool._lendBalance, lendTokenData.tokenDecimals);
    // replace the lend balance loaded from the graph with lend balance loaded from contract
    if (props.pool.poolLendBalance) {
      formattedAvailable = props.pool.poolLendBalance;
    }
    const lendPrice = tokenPrices[ethers.utils.getAddress(props.pool._lendToken)];
    const lendValue = parseFloat(formattedAvailable) * lendPrice;
    return (
      <Fragment>
        <ReactTooltip id={`lend-token-available-tip-${props.pool.id}`} type="info" className="tool-tip">
          <span>
            ${lendValue.toLocaleString(undefined, {maximumFractionDigits: 2})} worth of {props.pool.lendSymbol}
          </span>
        </ReactTooltip>
        <span data-tip data-for={`lend-token-available-tip-${props.pool.id}`}>
          {Number(formattedAvailable).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
        </span>
      </Fragment>
    );
  }

  // render the borrow column and change value for lent/borrowed pools
  const renderBorrowedColumn = () => {
    let value = "0";
    const lendTokenData:LOCAL_TOKEN_DATA = fetchLocalTokenData(props.pool._lendToken, chainId) as any;
    // return nothing if lend data doesn't exist
    if (!lendTokenData) return <></>;
    if (props.myPools) {
      if (props.mode === "borrowed") value = props.pool.amountOwed;
      else value = ethers.utils.formatUnits(props.pool._totalBorrowed, lendTokenData.tokenDecimals);
    } else {
      value = ethers.utils.formatUnits(props.pool._totalBorrowed, lendTokenData.tokenDecimals);
    }
    const lendPrice = tokenPrices[ethers.utils.getAddress(props.pool._lendToken)];
    const lendValue = parseFloat(value) * lendPrice;
    return (
      <Fragment>
        <ReactTooltip id={`lend-token-borrowed-tip-${props.pool.id}`} type="info" className="tool-tip">
          <span>
            ${lendValue.toLocaleString(undefined, {maximumFractionDigits: 2})} worth of {props.pool.lendSymbol}
          </span>
        </ReactTooltip>
        <span data-tip data-for={`lend-token-borrowed-tip-${props.pool.id}`}>
          {renderLazyLoad(!isNaN(Number(value)) ? value : undefined, 
            <Fragment>
              {Number(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            </Fragment>
          )}
        </span>
      </Fragment>
    );
  }

  const getPoolCloneLink = () => {
    const colToken = props.pool._colToken;
    const lendToken = props.pool._lendToken;
    const lendRatio = props.pool._mintRatio;
    const expiry = props.pool._expiry;
    const feeRate = props.pool._feeRate;
    const feeType = props.pool.feeType;
    const undercollateralized = props.pool.undercollateralized;
    return `/create-pool/?colToken=${colToken}&lendToken=${lendToken}&lendRatio=${lendRatio}&expiry=${expiry}&feeRate=${feeRate}&feeType=${feeType}&under=${undercollateralized}`;
  }

  // render LTV value and show > 999% if it's too high
  const renderLTV = (ltv: number) =>{
    return (renderLazyLoad(!isNaN(ltv), 
      <Fragment>
        {ltv > 999 ? "> 999%" : `${ltv.toFixed(2)}%`}
      </Fragment>
    ));
  }

  const renderBlockExplorerUrl = (route: string, address: string, chainId: number) => {
    const networkData = getNetworkData(chainId);
    if (route === "address") {
      return networkData?.getExplorerAddressLink(address);
    } else {
      return networkData?.getExplorerTransactionLink(address);
    }
  }

  const renderTermRateColumn = () => {
    if (props.mode === "borrowed")
      return (
        renderLazyLoad(effectiveRate !== undefined,
          <Fragment>
            {effectiveRate.toFixed(2)}%
          </Fragment>
        )
      )
    else 
      return (
        renderLazyLoad(feeRate !== undefined,
          <Fragment>
            {feeRate.toFixed(2)}%
          </Fragment>
        )
      )
  }

  const renderPairColumn = () => {

    // fetch token data to grab the token symbols 
    const lendData = fetchLocalTokenData(props.pool._lendToken, chainId);
    const colData = fetchLocalTokenData(props.pool._colToken, chainId);
    if (!lendData || !colData) return <></>;
    const lendSymbol = lendData.symbol;
    const colSymbol = colData.symbol;

    return (
      <span className={`pool-row-column-label ${!props.myPools? getLTVLabel(ltv) : ''}`}>
        <Fragment>
          <ReactTooltip id={`token-pair-tip-${props.pool.id}`} type="info" className="tool-tip">
            <span>
              {colSymbol}/{lendSymbol}
            </span>
          </ReactTooltip>
          <div data-tip data-for={`token-pair-tip-${props.pool.id}`}>
            <div className="icon-pair align-left">
              <img
                className="token-logo col-icon"
                alt={`${colSymbol}`}
                src={fetchTokenLogo(colSymbol)}
              ></img>
              <img
                className="token-logo lend-icon"
                alt={`${lendSymbol}`}
                src={fetchTokenLogo(lendSymbol)}
              ></img>
            </div>
          </div>
        </Fragment>
      </span>
    );
  }

  // render the main row component
  const renderRow = () => {
    return(
      <div 
        className={`pool-row-wrapper ${isSelectedPool ? 'selected' : ''}`}
        onClick={poolClicked}
      >
        {props.pool.externalDataLoaded &&
          <PoolStatusContainer
            pool={props.pool}
          />
        }
        {props.pool &&
          <PoolStats
            showModal={showPoolStats}
            setShowModal={setShowPoolStats}
            mode={"lent"}
            selectedPool={props.pool}
          />
        }
        {props.myPools ? 
          <div className="pool-row-column pair-column">
            {renderPairColumn()}
          </div>
        :''}
        <div className={`pool-row-column ltv-column ${props.myPools ? "my-pools-row" : ""}`}>
          <ReactTooltip id={`rollover-ltv-tip-${props.pool.id}`} type="info" className="tool-tip">
            Lend Ratio: {ethers.utils.formatUnits(props.pool._mintRatio, 18)}
          </ReactTooltip>
          <span className={`pool-row-column-label ${getLTVLabel(ltv)}`} data-tip data-for={`rollover-ltv-tip-${props.pool.id}`}>
            <Fragment>
              {renderLTV(ltv)}
            </Fragment>
          </span>
        </div>
        <div className={`pool-row-column term-rate-column ${props.myPools ? "my-pools-row" : ""}`}>
          <span className="pool-row-column-label">
            {renderTermRateColumn()}
          </span>
        </div>
        {!props.myPools ? 
          <div className="pool-row-column apr-column">
            <span className="pool-row-column-label">
              {renderLazyLoad(props.pool.currentFeeRate,
                <Fragment>
                  ~{annualizedFeeRate.toFixed(2)}%
                </Fragment>
              )}
            </span>
          </div>
        :''}
        <div className={`pool-row-column expiry-column ${props.myPools ? "my-pools-row" : ""}`}>
          {renderExpiryText()}
        </div>
        <div className={`pool-row-column available-column ${props.myPools ? "my-pools-row" : ""}`}>
          <span className="pool-row-column-label">
            {renderAvailableColumn()}
          </span>
        </div>
        <div className={`pool-row-column borrowed-column ${props.myPools ? "my-pools-row" : ""}`}>
          <span className="pool-row-column-label">
            {renderBorrowedColumn()}
          </span>
        </div>
        <div className={`pool-row-column option-column ${rowUid.current} ${props.myPools ? "my-pools-row" : ""}`} onClick={() => setShowDropdown(!showDropdown)}>
          { screenWidth > 783 ? <MoreHorizontal/> : <MoreVertical/> }
        </div>
        {showDropdown && <Dropdown setShowDropdown={setShowDropdown} showDropdown={showDropdown}>
          <div className={`inner-dropdown-wrapper ${rowUid.current}`}>
            <Link className="dropdown-item copy-pool-button" to={getPoolCloneLink()}>
              <img src={fetchIcon("copy")} alt="copy"/>
              Copy Pool
            </Link>
            <div className="dropdown-item" onClick={() => setShowPoolStats(true)}>
              <span>
                <img src={fetchIcon("eye")} alt="eye"/>
                View Pool Stats
              </span>
            </div>
            <div className="dropdown-item">
              <a 
                href={`${renderBlockExplorerUrl("address", props.pool.id, chainId)}`}
                target="_blank"
                rel="noreferrer"
                className="chain-explorer-link"
                onClick={() => window.open(renderBlockExplorerUrl("address", props.pool.id, chainId), '_blank')}
              >
                <span>
                  <img src={fetchIcon("stats")} alt="stats"/>
                  View Pool on Explorer
                </span>
              </a>
            </div>
          </div>
        </Dropdown>}
      </div> 
    );
  }

  if (!props.myPools)
    return (
      <Link to={`/borrow/${props.pool.id}${getChainQuery(chainId)}`}>
        {renderRow()}
      </Link>
    );
  else 
    return (
      <Link to={`/my-pools/${props.mode ? `${props.mode}/` : ''}${props.pool.id}${getChainQuery(chainId)}`}>
        {renderRow()}
      </Link>
    );
}

export default PoolRow;