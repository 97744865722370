import { Alert } from "@material-ui/lab";
import { useState } from "react";
import Modal from "../Modal/Modal";
import "./MintRatioWarning.css";

const MintRatioWarning = (props: {
  showMintRatioWarning: boolean,
  setSelectedMintRatio: (val: string | number) => void,
  setShowMintRatioWarning: (val: boolean) => void,
  deploy: () => void
}) => {

  const [agreedToWarning, setAgreedToWarning] = useState<boolean>(false);

  // once the check is clicked exit the modal
  const checkClicked = () => {
    props.setShowMintRatioWarning(false);
    setAgreedToWarning(false);
    props.deploy();
  }

  // if the user closes the modal without checking the box
  // reset mint ratio back to 0
  const onClose = () => {
    props.setShowMintRatioWarning(false);
    // if (!agreedToWarning) props.setSelectedMintRatio(0);
  }

  return (
    <Modal setShowModal={onClose} showModal={props.showMintRatioWarning}>
      <div className="lend-ratio-warning-wrapper">
        <Alert severity="error">
          Your selected lend ratio will set the pool to be undercollateralized.
          This featured should only be used by advanced lenders that understand
          the risks.
        </Alert>
        <div className={`warning-toggle-wrapper`}>
          <input
            type="checkbox"
            className="fee-checkbox"
            checked={agreedToWarning}
          />
          <span onClick={checkClicked}>I'm an advanced lender and understand the risks</span>
        </div>
      </div>
    </Modal>
  );
}

export default MintRatioWarning;