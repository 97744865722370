import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import axios from "axios";
import { Fragment, useContext, useEffect, useState } from "react"
import PoolFilters from "../../components/PoolFilters/PoolFilters";
import PoolRowsContainer from "../../components/PoolRowsContainer/PoolRowsContainer";
import BalanceManager from "./BalanceManager";
import UpdateFeeRate from "./UpdateFeeRate";
import "./MyPools.css";
import PauseBorrowing from "./PauseBorrowing";
import { PuffLoader } from "react-spinners";
import { fetchIcon, getNetworkData, getPoolFactoryContract, isPoolExpired, mobileBreakpoint, supportedNetworks, tabletBreakpoint, tokenData } from "../../utils/Utils";
import { BigNumber, ethers } from "ethers";
import PoolStats from "../../components/PoolStats/PoolStats";
import { useScreenSize } from "../../utils/useScreenSize";
import { X } from "react-feather";
import { WalletDataContext } from "../../context/WalletDataContext";
import { APP_DATA_CONTEXT, POOL_DATA, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { AppDataContext } from "../../context/AppDataContext";
import { PoolDataContext } from "../../context/PoolDataContext";
import PoolSimulation from "../../components/PoolSimulation/PoolSimulation";
import ReactTooltip from "react-tooltip";
import LendingPoolABI from "../../abi/LendingPool";
import UpgradeImplementation from "./UpgradeImplementation/UpgradeImplementation";
import SetRollover from "../../components/SetRollover/SetRollover";

const MyPools = () => {
  const [borrowedPools, setBorrowedPools] = useState<any[]>([]);
  const [lentPools, setLentPools] = useState<any[]>([]);
  const [mode, setMode] = useState<"borrowed" | "lent">("borrowed");
  const [searchValue, setSearchValue] = useState<string>("");
  const [poolsLoaded, setPoolsLoaded] = useState<boolean>(false);
  const [borrowedPoolsLoaded, setBorrowedPoolsLoaded] = useState<boolean>(false);
  const [lentPoolsLoaded, setLentPoolsLoaded] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>("ltv");
  const [sortDirection, setSortDirection] = useState<"ascending" | "descending">("descending");
  const [selectedPool, setSelectedPool] = useState<POOL_DATA>();
  const [showExpiredPools, setShowExpiredPools] = useState<boolean>(false);
  const [showPoolStats, setShowPoolStats] = useState<boolean>(false);
  const [utilizationData, setUtilizationData] = useState<any[]>([{ name: "Used", value: 0},{ name: "Unused", value: 100}]);
  const [showPoolModal, setShowPoolModal] = useState<boolean>(false);
  const [shouldShowUpgrade, setShouldShowUpgrade] = useState<boolean>(false);
  const [shouldShowRollover, setShouldShowRollover] = useState<boolean>();
  const [selectedRollover, setSelectedRollover] = useState<POOL_DATA>();

  const { provider, chainId, account } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { pending, setPending, fullPoolData } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { screenWidth } = useScreenSize();

  useEffect(() => {
    updatePoolData();
    setSelectedPool(undefined);
    // reload user pool data after switching chains
    setSelectedPool(undefined);
    getUserBorrowedPools();
    getUserLentPools();
  // eslint-disable-next-line
  }, [account, chainId]);

  useEffect(() => {
    handleAutoModeSelection();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedPool) {
      setShowPoolModal(true);
      if (poolsLoaded)
        getGraphData();
    }
    checkPoolEligibility();
    setSelectedRollover(undefined);
  // eslint-disable-next-line
  }, [selectedPool]);

  // auto select a mode based on the URL  
  const handleAutoModeSelection = () => {
    // get the current page path
    const path = window.location.pathname;
    const parts = path.split("/");
    if (parts.length > 2) {
      const side = parts[2];
      // switch to lend or borrow mode
      if (side === "lent")
        setMode("lent");
      else if (side === "borrowed")
        setMode("borrowed");
    }
  }

  // select the first valid pool when the user switches modes
  const modeClicked = (newMode: "lent" | "borrowed") => {
    if (newMode === mode) return;
    setMode(newMode);
    if (newMode === "borrowed")  {
      const pool = selectFirstValidPool(borrowedPools);
      setSelectedPool(pool);
    } else if (newMode === "lent") {
      const pool = selectFirstValidPool(lentPools);
      setSelectedPool(pool);
    }
  }

  useEffect(() => {
    // find and select first valid pool
    if (screenWidth > mobileBreakpoint) {
      if (mode === "borrowed")  {
        const pool = selectFirstValidPool(borrowedPools);
        setSelectedPool(pool);
      } else if (mode === "lent") {
        const pool = selectFirstValidPool(lentPools);
        setSelectedPool(pool);
      }
    }
  // eslint-disable-next-line
  }, [mode, showExpiredPools]);

  useEffect(() => {
    setPoolsLoaded(borrowedPoolsLoaded && lentPoolsLoaded);
  // eslint-disable-next-line
  }, [borrowedPoolsLoaded, lentPoolsLoaded]);

  // check if pool is eligible for certain features
  const checkPoolEligibility = async () => {
    setShouldShowUpgrade(await isPoolEligibleForUpgrade());
    setShouldShowRollover(await isPoolEligibleForRollovers());
  }

  // check if the SetRollover component should be showall of his poolsn
  const isPoolEligibleForRollovers = async () =>{
    if (selectedPool) {
      const poolContract = new ethers.Contract(
        selectedPool.id, 
        LendingPoolABI, 
        provider
      );
      // set eligible if the version() call fails
      let versionEligible = false;
      try {
        await poolContract.version();
      } catch (e) {
        versionEligible = true;
      }
      return(versionEligible);
    } else {
      return false;
    }
  }

  // check if the UpgradeImplementation component should be shown
  const isPoolEligibleForUpgrade = async () => {
    if (selectedPool) {
      // get necessary contracts
      const factoryContract = getPoolFactoryContract(
        provider?.getSigner() as any, 
        chainId
      );
      const poolContract = new ethers.Contract(
        selectedPool.id, 
        LendingPoolABI, 
        provider
      );
      // check if upgrade is allowed
      const canUpgrade = await factoryContract.allowUpgrade();
      // check if pool version is less than 3
      let versionEligible = false;
      try {
        const poolVersion:BigNumber = await poolContract.version();
        versionEligible = poolVersion.lte(BigNumber.from("3"));
      } catch (e) {
        // console.log(e);
        versionEligible = false;
      }
      return(canUpgrade && versionEligible);
    } else {
      return false;
    }
  }

  // auto select the first valid pool based on some criteria
  const selectFirstValidPool = (poolList: POOL_DATA[]):POOL_DATA | undefined => {
    let validPool:POOL_DATA | undefined = undefined;
    // convert fullPoolData object into iterable list
    const fullPoolDataList:POOL_DATA[] = [...new Array(Object.values(fullPoolData).length)].map((_, index) => 
      Object.values(fullPoolData)[index] as POOL_DATA
    );
    // is the found pool in the correct pool list
    const isInPoolList = (pool: POOL_DATA):boolean => {
      const inList = poolList.findIndex((currentPool: POOL_DATA) => currentPool.id === pool.id);
      return inList > -1;
    }
    // find and select the first valid pool if it exists
    for (const pool of fullPoolDataList) {
      if (showExpiredPools && isPoolExpired(pool) && isInPoolList(pool)) {
        validPool = pool;
        break;
      } else if (!showExpiredPools && !isPoolExpired(pool) && isInPoolList(pool)) {
        validPool = pool;
        break;
      }
    }
    return validPool;
  }

  const updatePoolData = () => {
    // setLentPools([]);
    // setBorrowedPools([]);
    getUserBorrowedPools();
    getUserLentPools();
    // getGraphData();
  };

  // get all of the required data to display utilization data
  const getGraphData = async () => {
    if (!selectedPool || !chainId || !selectedPool.externalDataLoaded) return;

    // extract vars from pool
    const { rawTotalFees, _totalBorrowed, poolLendBalance, lendDecimals } = selectedPool;

    // format into human readable number
    const formattedTotalFees = parseFloat(ethers.utils.formatUnits(rawTotalFees || BigNumber.from("0"), lendDecimals));
    const formattedTotalBorrwed = parseFloat(ethers.utils.formatUnits(_totalBorrowed, lendDecimals));
    const formattedPoolLendBalance = parseFloat(poolLendBalance || "0");

    // calculate utilization 
    let num1 = formattedTotalBorrwed + formattedPoolLendBalance - formattedTotalFees;
    // don't include total fees if the result makes num1 negative
    if (num1 < 0) num1 = formattedTotalBorrwed + formattedPoolLendBalance;
    const num2 = formattedTotalBorrwed;
    let utilization = num1 / num2;
    utilization = isNaN(utilization) ? 0 : 100 / utilization;

    let utilizationData = [
      { name: "Used", value: Math.min(utilization, 100)},
      { name: "Unused", value: 100 - utilization}
    ];

    setUtilizationData(utilizationData)
  }

  // get the pools that the user has borrowed from
  const getUserBorrowedPools = async () => {

    // don't load pools if user isn't on supported network or if no account
    if (!account || (!supportedNetworks.includes(chainId))) {
      setBorrowedPoolsLoaded(true);
      setBorrowedPools([]);
      return;
    }

    const url:any = getNetworkData(chainId)?.graphUrl;

    const query = `{
      borrowers(where: {id: "${account.toLowerCase()}"}) {
        positions {
          id
          effectiveRate 
          pool {
            id
            _deployer
            _colToken
            _lendToken
            _feeRate
            _expiry
            _mintRatio
            _startTime
            _borrowers
            _totalBorrowed
            _colBalance
            _lendBalance
            _paused
            _protocolFee
            _type
          }
        }
      }
    }`;

    // make graph request
    const borrowed = await axios.post(url,{ query });
    const res = borrowed.data.data.borrowers;

    if (res.length > 0) {
      const positions = res[0].positions;
      // extract pools from positions and add effective rate into pool data
      const pools = positions.map((position: any) => {
        return {
          ...position.pool,
          effectiveRate: position.effectiveRate
        }
      });
      // set pools for the first time before
      // contract specific data is loaded
      setBorrowedPools(pools);
      setBorrowedPoolsLoaded(true);
    } else {
      setBorrowedPoolsLoaded(true);
    }
  };

  // get the pools that the user has deployed / lent into
  const getUserLentPools = async () => {

    // wallet data is undefined at first so don't set loaded to true yet 
    // don't load pools if user isn't on supported network or if no account
    if (!account || !supportedNetworks.includes(chainId)) {
      setLentPoolsLoaded(true);
      setLentPools([]);
      return;
    }

    const url:any = getNetworkData(chainId)?.graphUrl;
    
    const query = await axios.post(
      url,
      {
        query: `
        {
          pools(where: {
            _deployer: "${account.toLowerCase()}",
          }) {
            id
            _deployer
            _colToken
            _lendToken
            _feeRate
            _expiry
            _mintRatio
            _startTime
            _borrowers
            _totalBorrowed
            _colBalance
            _lendBalance
            _paused
            _protocolFee
            _type
          }
        }
        `,
      }
    );
    const res = query.data.data.pools;
    if (res.length > 0) {
      // set pools for the first time before
      setLentPools(res);
      setLentPoolsLoaded(true);
    } else {
      setLentPoolsLoaded(true);
    }
  };

  const renderSwitch = () => {
    return (
      <div className="mode-switch-container">
        <div className={`mode-switch-slider ${mode === "lent" ? 'left' : 'right'}`}></div>
        <span 
          onClick={() => modeClicked("lent")}
          className={`${mode === "lent" ? 'active' : ''}`}
        >
          Lent
        </span>
        <span 
          onClick={() => modeClicked("borrowed")}
          className={`${mode === "borrowed" ? 'active' : ''}`}
        >
          Borrowed
        </span>
      </div>
    )
  }

  const manageActionButton = ():boolean => {
    if (pending) return true;
    else if (!selectedPool) return true;
    else if (!account) return true;
    else if (selectedPool._deployer.toLowerCase() !== account?.toLowerCase()) return true;
    else return false;
  }

  const renderUtilizationData = () => {

    const radius = 60;

    const renderCustomizedLabel = (data: any) => {
      const { cx, cy, percent } = data;
      if (data.name === "Used")  {
        let x;
        let y = cy;
        const val = (percent * 100).toFixed(1);
        const displayPercent = Math.max(0, Math.min(percent * 100, 100));
        // positioning for text
        if (val.length === 3) {
          x = cx - 15;
        } else if (val.length === 4) {
          x = cx - 17;
        } else if (val.length === 5) {
          x = cx - 23;
        } else {
          x = cx;
        }
        return (
          <text className="pie-chart-text" x={x} y={y} textAnchor={"start"} dominantBaseline="central">
            {`${(displayPercent).toFixed(1)}%`}
          </text>
        );
      } else return null;
    };


    // fake utilization data for when there is no data
    const dummyData = [
      { name: "Used", value: 50},
      { name: "Unused", value: 50}
    ];

    // gradient colors for price graph area
    const gradientColors = () => {
      return (
       <linearGradient id="pieGradient" x1="0" y1="0" x2="1" y2="0" spreadMethod="reflect">
         <stop offset="0%" stopColor="var(--iris)" stopOpacity={1} />
         <stop offset="95%" stopColor="#8f7ee1" stopOpacity={1} />
       </linearGradient>
      );
    };

    // what to render if no data is available
    const noData = (
      <Fragment>
        <span className="no-data-message">No Data Available</span>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
          <defs>
            {gradientColors()}
          </defs>
          <Pie
            data={dummyData}
            cx="50%"
            cy="46%"
            labelLine={false}
            animationDuration={500}
            animationBegin={0}
            outerRadius={radius}
            strokeLinecap={"round"}
            strokeLinejoin={"round"}
            paddingAngle={1}
            blendStroke={true}
            innerRadius={radius - 13}
            dataKey="value"
            cornerRadius={10}
          >
            {dummyData.map((entry, index) => {
              return <Cell 
                  key={`cell-${index}`} 
                  fill={`${entry.name === "Used" ? "url(#pieGradient)"  : 'var(--input)'}`} 
                  style={{zIndex: `${entry.name === "Used" ? "2" : "1"}`}}
                />
            })}
          </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Fragment>
    );

    // render either noData component or show real data
    if ((mode === "borrowed" && borrowedPools.length === 0 && !poolsLoaded) || (!selectedPool && poolsLoaded)) 
      return(noData);
    else if ((mode === "lent" && lentPools.length === 0 && !poolsLoaded) ||  (!selectedPool && poolsLoaded)) 
      return(noData);
    else 
      return (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <defs>
              {gradientColors()}
            </defs>
            <Pie
              data={utilizationData}
              cx="50%"
              cy="46%"
              labelLine={false}
              label={poolsLoaded ? renderCustomizedLabel : undefined}
              animationDuration={500}
              animationBegin={0}
              outerRadius={radius}
              paddingAngle={utilizationData[0].value <= 0 || utilizationData[0].value >= 100 ? 0 : 1}
              strokeLinecap={"round"}
              strokeLinejoin={"round"}
              blendStroke={true}
              innerRadius={radius - 13}
              cornerRadius={100}
              dataKey="value"
            >
              {utilizationData.map((entry, index) => {
                return <Cell 
                    key={`cell-${index}`} 
                    fill={`${entry.name === "Used" ? "url(#pieGradient)"  : 'var(--input)'}`} 
                    z={`${entry.name === "Used" ? 1 : 2}`}
                  />
              })}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      );
  }

  const renderPriceData = () => {
    if (!selectedPool)
      return (
        <PoolSimulation
          // @ts-ignore
          colToken={tokenData.WETH.address[chainId]}
          // @ts-ignore
          lendToken={tokenData.USDC.address[chainId]}
          mintRatio={0}
          fakeData={true}
        />
      )
    else 
      return (
        <PoolSimulation
          colToken={selectedPool._colToken}
          lendToken={selectedPool?._lendToken}
          mintRatio={ethers.utils.formatUnits(selectedPool._mintRatio, 18)}
          startTime={selectedPool._startTime}
        />
      );
  }

  const renderPoolFilters = () => {
    return (
      <Fragment>
        <div className="pool-filters-filter-wrapper expired-pool-filter" onClick={() => setShowExpiredPools(!showExpiredPools)}>
          <span>Show {showExpiredPools ? "Active" : "Expired"} Pools</span>
        </div>
      </Fragment>
    )
  }

  const renderSetRollover = () => {
    if (mode === "lent" && selectedPool && shouldShowRollover && !isPoolExpired(selectedPool))
      return (
        <SetRollover
          lentPools={lentPools}
          selectedPool={selectedPool}
          setSelectedRollover={setSelectedRollover}
          selectedRollover={selectedRollover}
        />
      )
  }

  const renderRightSection = () => {
    if (screenWidth > tabletBreakpoint)
      return (
        <section className="my-pools-right-section">
          {mode === "lent" && shouldShowUpgrade && 
            <UpgradeImplementation
              checkPoolEligibility={checkPoolEligibility}
            />
          }
          <BalanceManager 
            mode={mode}
            updatePoolData={updatePoolData}
          />
          {renderSetRollover()}
          {mode === "lent" && (selectedPool && !isPoolExpired(selectedPool) && selectedPool._deployer === account?.toLowerCase()) ?  
            <div className="my-pools-small-widgets-wrapper fade-in">
              <UpdateFeeRate
                selectedPool={selectedPool}
                pending={pending}
                setPending={setPending}
                manageActionButton={manageActionButton}
                updatePoolData={updatePoolData}
              />
              <PauseBorrowing
                selectedPool={selectedPool}
                pending={pending}
                setPending={setPending}
                manageActionButton={manageActionButton}
                updatePoolData={updatePoolData}
              />
            </div>
          :''}
        </section>
      );
    else 
      if (showPoolModal)
        return (
          <section className="my-pools-right-section modal">
            <div className="my-pools-close-modal" onClick={() => setShowPoolModal(false)}>
              <X/>
            </div>
            <BalanceManager 
              mode={mode}
              updatePoolData={updatePoolData}
            />
            {mode === "lent" && (selectedPool && !isPoolExpired(selectedPool)) ?  
              <div className="my-pools-small-widgets-wrapper fade-in">
                {shouldShowUpgrade && 
                  <UpgradeImplementation
                    checkPoolEligibility={checkPoolEligibility}
                  />
                }
                {renderSetRollover()}
                <div className="my-pools-small-widgets-inner-wrapper">
                  <UpdateFeeRate
                    selectedPool={selectedPool}
                    pending={pending}
                    setPending={setPending}
                    manageActionButton={manageActionButton}
                    updatePoolData={updatePoolData}
                  />
                  <PauseBorrowing
                    selectedPool={selectedPool}
                    pending={pending}
                    setPending={setPending}
                    manageActionButton={manageActionButton}
                    updatePoolData={updatePoolData}
                  />
                </div>
              </div>
            :''}
          </section>
        );
      else return null;
  }

  return (
    <div className="my-pools-wrapper fade-in">
      <PoolDataContext.Provider value={{ selectedPool, setSelectedPool }}>
        <section className="my-pools-left-section left-section">
          <section className="my-pools-graph-section">
            <div className="my-pools-left-graph">
              {renderPriceData()}
            </div>
            <div className="my-pools-right-graph graph-container">
              {selectedPool && poolsLoaded && <span className="my-pools-utilization-text">Utilized</span>}
              <div className="my-pools-graph-title">
                <ReactTooltip 
                  id={`pool-stats-tip`} 
                  type="info" 
                  className="tool-tip" 
                  place={screenWidth > mobileBreakpoint ? "bottom" : "left"}
                >
                  <PoolStats
                    showModal={showPoolStats}
                    setShowModal={setShowPoolStats}
                    selectedPool={selectedPool}
                    mode={mode}
                    hover={true}
                  />
                </ReactTooltip>
                <span>Pool Utilization</span>
                <div 
                  className="pool-stats-button" 
                  data-tip data-for={`pool-stats-tip`} 
                  onClick={() => setShowPoolStats(true)}
                >
                  <img src={fetchIcon("stats")} alt="stats"/>
                </div>
              </div>
              {!poolsLoaded ? 
                <PuffLoader className="loader"/>
              :
                renderUtilizationData()
              }
            </div>
          </section>
          <div className="my-pools-header">
            {renderSwitch()}
            <PoolFilters 
              setSearchValue={setSearchValue} 
              children={renderPoolFilters()}
            />
          </div>
            <PoolRowsContainer
              pools={mode === "borrowed" ? borrowedPools : lentPools}
              mode={mode}
              showPools={true}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
              searchValue={searchValue}
              showExpiredPools={showExpiredPools}
              myPools={true}
              poolsLoaded={poolsLoaded}
            />
        </section>
        {renderRightSection()}
      </PoolDataContext.Provider>
    </div>
  );
};

export default MyPools;