import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Modal from "../Modal/Modal";
// import { useSnackbar } from "notistack";
import "./AlertBox.css";
// import { Link } from "react-router-dom";
// import ActionButton from "../ActionButton/ActionButton";
import { Alert } from "@material-ui/lab";

const AlertBox = (props: {
  shrink: boolean;
  message: string;
  setShowNewPool: (val: boolean) => void;
  showNewPool: boolean;
  title: string;
  show: boolean;
}) => {
  // const { enqueueSnackbar } = useSnackbar();

  /*
  const getMessage = () => {
    if (props.shrink) {
      let start = props.message.substring(0, 39);
      let end = props.message.substring(
        props.message.length - 3,
        props.message.length
      );
      return `${start}...${end}`;
    }
  };

  const getPoolAddress = () => {
    // extract pool
    return props.message.split("/")[props.message.split("/").length - 1];
  };

  const copyText = () => {
    navigator.clipboard.writeText(props.message);
    enqueueSnackbar("URL copied to clipboard", {
      persist: false,
    });
  };
  */

  return (
    <Modal setShowModal={props.setShowNewPool} showModal={props.showNewPool}>
      <div className={"alert-box-container " + (props.show ? "" : "hidden")}>
        <div className="alert-box-header">{props.title}</div>
        <div className="aler-box-message">
          Your pool has been successfully created.
        </div>
        {/*<div className="alert-box-body">
          <div className="alret-box-button">{getMessage()}</div>
          <FontAwesomeIcon
            icon={faCopy}
            className="aler-box-icon"
            onClick={copyText}
          />
        </div>
        <div className="alert-box-link-wrapper">
          <Link to={`/borrow/${getPoolAddress()}`}>
            <ActionButton title={"Go to pool"} disabled={false} />
          </Link>
        </div>*/}
        <Alert severity="info" className="alert-box-notice">
          After deploying your pool there may be a delay between when your pools
          shows up on the UI. If your transaction was successful, your pool will
          appear within 1 - 3 minutes. If you have any questions please reach
          out in our Discord server
        </Alert>
      </div>
    </Modal>
  );
};

export default AlertBox;
