import "./TopNav.css";
import { shortenAddress } from "@usedapp/core";
import { Jazzicon } from "@ukstv/jazzicon-react";
import ConnectWallet from "../ConnectWallet/ConnectWallet";
import { Fragment, useContext, useEffect, useState } from "react";
import { AlertTriangle, ChevronDown, Info, Menu, MoreHorizontal, PlusCircle, Search, User } from "react-feather";
import { getChainQuery, getNetworkData, getWeb3Provider, networkNames, networks, renderLazyLoad, supportedNetworks, tabletBreakpoint } from "../../utils/Utils";
import { Link, useNavigate } from "react-router-dom";
import NetworkSelect from "../NetworkSelect/NetworkSelect";
import OptionsMenu from "../OptionsMenu/OptionsMenu";
import { Provider as MulticallProvider } from "ethers-multicall";
import { init, useConnectWallet } from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'
import coinbaseWalletModule from '@web3-onboard/coinbase'
import Onboard, { OnboardAPI } from '@web3-onboard/core'
import Confetti from 'react-confetti'
import millify from "millify";
import GlobalMessage from "../GlobalMessage/GlobalMessage";
import axios from "axios";
import { useScreenSize } from "../../utils/useScreenSize";
import MobileMenu from "./MobileMenu";
import { NETWORK_DATA, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { WalletDataContext } from "../../context/WalletDataContext";
import { useSnackbar } from "notistack";
// @ts-ignore
declare var window: any;
window.Buffer = window.Buffer || require("buffer").Buffer; 

// initialize wallet modules with options
const injected = injectedModule();
const walletConnect = walletConnectModule({
  bridge: 'https://bridge.walletconnect.org/',
  qrcodeModalOptions: {
    mobileLinks: ['rainbow', 'metamask', 'argent', 'trust', 'imtoken', 'pillar']
  },
  connectFirstChainId: true
});
const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: false });
const availableWallets = [injected, walletConnect, coinbaseWalletSdk];

const chains = [
  {
    id: `0x${networks.Arbitrum.chainId.toString(16)}`,
    token: 'ETH',
    label: 'Arbitrum',
    rpcUrl: networks.Arbitrum.rpcUrl
  },
  {
    id: `0x${networks.Ethereum.chainId.toString(16)}`,
    token: 'ETH',
    label: 'Ethereum',
    rpcUrl: networks.Ethereum.rpcUrl
  },
  {
    id: `0x${networks.Polygon.chainId.toString(16)}`,
    token: 'MATIC',
    label: 'Polygon',
    rpcUrl: networks.Polygon.rpcUrl
  },
  /*
  {
    id: `0x${networks.Goerli.chainId.toString(16)}`,
    token: 'ETH',
    label: 'Goerli',
    rpcUrl: networks.Goerli.rpcUrl
  },
  */
]

// initialize Onboard
init({
  wallets: availableWallets,
  chains: chains
});

const onBoardOptions = {
  wallets: availableWallets,
  chains: chains,
  appMetadata: {
    name: 'Vendor Finance',
    icon: "<svg></svg>", // svg string icon
    description: 'Permisionless Borrowing and Lending Made Simple',
    recommendedInjectedWallets: [
      { name: 'MetaMask', url: 'https://metamask.io' },
      { name: 'Coinbase', url: 'https://wallet.coinbase.com/' }
    ]
  },
  accountCenter: {
    desktop: {
      enabled: false
    },
    mobile: {
      enabled: false 
    }
  }
}

const links = [
  { text: "Borrow", href: "/borrow", icon: <Search/> },
  { text: "My Pools", href: "/my-pools", icon: <User/> },
  { text: "Create Pool", href: "/create-pool", icon: <PlusCircle/> },
];

const TopNav = () => {

  const [network, setNetwork] = useState<string>("Arbitrum");
  const [showWalletModal, setShowWalletModal] = useState<boolean>(false);
  const [showNetworkSelect, setShowNetworkSelect] = useState<boolean>(false);
  const [showNetworkWarning, setShowNetworkWarning] = useState<boolean>(false);
  const [showOptionsMenu, setShowOptionsMenu] = useState<boolean>(false);
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [tvl, setTvl] = useState<number>(0);
  const { screenWidth } = useScreenSize();

  const navigate = useNavigate();
  const [{ wallet }] = useConnectWallet();
  const { chainId, account, setChainId, setProvider, setMulticallProvider } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { enqueueSnackbar  } = useSnackbar();

  useEffect(() => {
    setNetworkName();

    // check if wrong network warning should be seen
    if (window.ethereum && window.ethereum.chainId) {
      // wait x seconds so the warning doesn't briefly show up when switching networks
      setTimeout(() => {
        const networkId = Number(window.ethereum.chainId.toString(10));
        const connectedNetwork = getNetworkData(chainId);
        setShowNetworkWarning(networkId !== connectedNetwork?.chainId)
      }, 5000);
    }
  // eslint-disable-next-line
  }, [wallet, navigate, chainId]);

  useEffect(() => {
    const onboard = Onboard(onBoardOptions);
    const previouslyConnectedWallets = JSON.parse(
      window.localStorage.getItem('connectedWallets')
    );
    autoConnectWallet(previouslyConnectedWallets, onboard);
    login();
  // eslint-disable-next-line
  }, []);

  // login to firebase account
  const login = async () => {
    const request = await axios.get("https://api.llama.fi/tvl/vendor-finance")
    const tvl = request.data;
    // setTvl(tvl.lastHourlyRecord.tvl);
    setTvl(tvl);
  }

  const setNetworkName = async () => {
    // @ts-ignore
    if (supportedNetworks.includes(chainId)){
      // @ts-ignore
      setNetwork(networkNames[chainId]);
    } else {
      setNetwork("Unsupported")
    }
  }

  const autoConnectWallet = async (previouslyConnectedWallets: any, onboard: OnboardAPI) => {
    if (previouslyConnectedWallets && previouslyConnectedWallets.length > 0) {
      // "silently" and disable all onboard modals to avoid them flashing on page load
      await onboard.connectWallet({
        autoSelect: { label: previouslyConnectedWallets[0], disableModals: true }
      })
    } else if (previouslyConnectedWallets && previouslyConnectedWallets.length === 0) {
      // remove connected wallets from storage if it is empty
      // window.localStorage.removeItem("connectedWallets");
    }
  }

  // connect to web3
  const connectWallet = async () => {
    const onboard = Onboard(onBoardOptions);
    const wallets = await onboard.connectWallet();
    const connectedWallets = wallets.map(({ label }) => label)
    window.localStorage.setItem(
      'connectedWallets',
      JSON.stringify(connectedWallets)
    )
  };

  const renderVersionMessage = () => {
    return (
      <div 
        className="version-message-wrapper fade-in"
      >
        <div className="version-message">
          <Info/>
          You are connected to our V1 site. Click <a href="https://vendor.finance" target="_self" rel="noreferrer">{" "}here</a> to switch to V2.
        </div>
        <a href="https://docs.vendor.finance/overview/v2-migration/" target="_blank" rel="noreferrer">Read More</a>
      </div>
    )
  }

  const renderWrongNetworkMessage = () => {
    const connectedNetwork = getNetworkData(chainId);
    // no wallet in browser
    if (!showNetworkWarning || !connectedNetwork || !wallet)
      return <></>
    else 
      return (
        <div 
          className="wrong-network-message fade-in"
          onClick={() => changeNetwork(connectedNetwork)}
        >
          <div className="wrong-network-message-right">
            <AlertTriangle/>
            Your wallet is not connected to {connectedNetwork.chainName}. Click here to switch to {connectedNetwork.chainName}.
          </div>
        </div>
      )
  }

  const renderNavLinks = () => {
    return(
      <div className="nav-link-container">
        {links.map((link, index:number) => 
          <Link 
            to={`${link.href}${getChainQuery(chainId)}`}
            onClick={() => setShowMenu(false)}
            className={`nav-link ${window.location.pathname.split("/")[1] === link.href.substring(1) ? 'active' : ''}`}
            key={index}
          >
            {link.text}
          </Link>
        )}
        <a 
          href="https://docs.vendor.finance/" 
          className="nav-link"
          target="_blank"
          rel="noreferrer"
        >
          Docs 
        </a>
        <a 
          href="https://discord.com/channels/945189813869490247/945412786647883826"
          className="nav-link"
          target="_blank"
          rel="noreferrer"
        >
          Report A Bug
        </a>
      </div>
    );
  }

  const changeNetwork = async (networkData: NETWORK_DATA) => {
    // reset the token prices so they are
    // reloaded once the chainId changes
    try {
      let provider;
      let multicallProvider;
      let chainId;
      const isDevSite = window.location.host.includes("localhost") || window.location.host.includes("dev");
      if (!networkData.isLive && !isDevSite) return;
      if (wallet && window.ethereum && window.ethereum.selectedAddress !== null) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x" + networkData.chainId.toString(16)}], // chainId must be in hexadecimal numbers
        });
        provider = getWeb3Provider(wallet?.provider);
        // brand new users use the default RPC
        multicallProvider = new MulticallProvider(provider);
        multicallProvider.init();
        chainId = networkData.chainId;
      } else {
        chainId = networkData.chainId;
        provider = getWeb3Provider(networkData.rpcUrl, true);
        multicallProvider  = new MulticallProvider(provider);
        chainId = networkData.chainId;
      }
      setChainId(chainId);
      setProvider(provider);
      setMulticallProvider(multicallProvider);
      setShowNetworkWarning(false);
      enqueueSnackbar(`Successfully switched to ${networkData.chainName}`, {
        persist: false,
      });
      // update chain query param
      const path = window.location.pathname;
      navigate(`${path}${getChainQuery(networkData.chainId)}`)
    } catch (e) {
      enqueueSnackbar(`Failed to switch to ${networkData.chainName}`, {
        persist: false,
      });
      console.log(e);
    }


  };

  const networkButtonClicked = async () => {
    // await changeNetworkRequest();
    setShowNetworkSelect(!showNetworkSelect)
  }

  const renderNetworkButton = () => {
    let onSupportedNetwork = supportedNetworks.includes(chainId);
    return (
      <button 
        className="network-connect-button topnav-button"
        onClick={networkButtonClicked}
      >
        {showNetworkSelect &&
          <NetworkSelect
            changeNetwork={changeNetwork}
            setShowNetworkSelect={setShowNetworkSelect}
          />
        }
        <span>
          {onSupportedNetwork ? 
            <Fragment>
              <img
                alt={`${network}`}
                src={`/assets/networks/${network.toUpperCase()}-logo.png`}
              />
              {network} 
            </Fragment>
          :
            <Fragment>
              <AlertTriangle/>
              {network} 
            </Fragment>
          }
        </span>
        <ChevronDown/>
      </button>
    )
  }


  const logoClicked = () => {
    navigate("/borrow");
    setShowConfetti(true);
  }

  const renderTVL = () => {

    // abbreviate number
    const formattedTvl = millify(tvl, {
      precision: 2,
      lowercase: true
    });

    return (
      <div className="tvl-wrapper">
        TVL: $
        {renderLazyLoad(tvl === 0 ? undefined : tvl, <Fragment>{formattedTvl}</Fragment>)}
      </div>
    );

  }

  const mobileMenuClicked = () => {
    setShowMenu(!showMenu);
  }
  
  return (
    <Fragment>
      {renderWrongNetworkMessage()}
      {renderVersionMessage()}
      {screenWidth < tabletBreakpoint && (
        <MobileMenu
          links={links}
          connectWallet={connectWallet}
          account={account}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
        />
      )}
      <section className="topnav-header topnav-section mobile">
        <img
          src={require(`../../img/vendor.png`)}
          className="vendor-logo"
          alt="vendor"
          onClick={logoClicked}
        />
        <Menu className="mobile-menu-button" onClick={mobileMenuClicked} />
        <div className="beta-text">BETA</div>
      </section>
      <nav className={`topnav-wrapper desktop`}>
        {showConfetti && (
          <Confetti
            width={window.innerWidth / 1.4}
            height={window.innerHeight - 25}
            onConfettiComplete={() => setShowConfetti(false)}
            initialVelocityY={1}
            initialVelocityX={Math.random() * (30 - 5) + 5}
            tweenDuration={100}
            numberOfPieces={50}
            recycle={false}
            confettiSource={{ x: 5, y: 5, w: 0, h: 0 }}
          />
        )}
        <section 
          className="topnav-header topnav-section desktop"
          onClick={logoClicked}
        >
          <img
            src={require(`../../img/vendor.png`)}
            className="vendor-logo"
            alt="vendor"
          />
          <Menu onClick={mobileMenuClicked} className={"mobile-menu-button"} />
          <div className="beta-text">BETA</div>
        </section>
        <section className="topnav-section nav-links">
          {renderNavLinks()}
        </section>
        <section className="topnav-section nav-links"></section>
        <section className="topnav-buttons topnav-section">
          {renderTVL()}
          {renderNetworkButton()}
          <button
            className="wallet-connect-button topnav-button filled"
            onClick={connectWallet}
          >
            <span>
              {account
                ? shortenAddress(account)
                : "Connect Wallet"}
              {account && (
                <Jazzicon
                  // seed={jsNumberForAddress(account)}
                  address={account || ""}
                />
              )}
            </span>
          </button>
          <button
            className="topnav-button options-button"
            onClick={() => setShowOptionsMenu(!showOptionsMenu)}
          >
            <MoreHorizontal />
          </button>
          {showOptionsMenu && (
            <OptionsMenu setShowOptionsMenu={setShowOptionsMenu} />
          )}
        </section>
        {showWalletModal && (
          <ConnectWallet
            showModal={showWalletModal}
            setShowModal={setShowWalletModal}
            connectWallet={connectWallet}
          />
        )}
      </nav>
      <GlobalMessage 
        message={undefined} 
        severity={"error"} 
      />
    </Fragment>
  );
}

export default TopNav;