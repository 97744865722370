import React from "react";
import "./ActionButton.css";

const ActionButton = (props: {
  disabled: boolean;
  action?: () => any;
  title: string;
}) => {
  return (
    <button
      onClick={props.action}
      disabled={props.disabled ? props.disabled : false}
      className="action-button-container disable-select"
    >
      {props.title}
    </button>
  );
};

export default ActionButton;
