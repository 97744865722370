import React from "react";
import "./RepayRow.css";
import moment from "moment";
import { mobileBreakpoint, renderLazyLoad, truncateNumber } from "../../utils/Utils";
import { useScreenSize } from "../../utils/useScreenSize";

const RepayRow = (props: {
  mintRatio: string,
  expiry: number,
  borrowAmount: number,
  colSymbol: string,
  lendSymbol: string,
  loading: boolean
}) => {
  const { screenWidth } = useScreenSize();
  const getDateFormatting = () => {
    if (screenWidth > mobileBreakpoint) return moment(props.expiry * 1000).format("MMM D, Y hh:mm A");
    else return moment(props.expiry * 1000).format("MM/DD/YY hh:mm A");
  }
  return (
    <div className="repay-row">
      <div className="repay-lend-ratio repay-text">
        {Number(props.mintRatio)}
      </div>
      <div className="repay-expiry repay-text">
        {getDateFormatting()}
      </div>
      <div className="repay-amount-owed repay-text">
        {renderLazyLoad(!props.loading,
          truncateNumber(props.borrowAmount, 4)
        )}
      </div>
    </div>
  );
};

export default RepayRow;
