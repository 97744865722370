import { Alert } from "@material-ui/lab";
import { BigNumber, ethers } from "ethers";
import { Fragment, useEffect, useState } from "react";
import { XCircle } from "react-feather";
import { getLicenseEngineContract, mobileBreakpoint } from "../../../utils/Utils";
import LicenseRow from "./LicenseRow";
import "./LicenseTable.css";
import { LICENSE_DATA } from "../../../utils/Interfaces";
import { useScreenSize } from "../../../utils/useScreenSize";

const LicenseTable = (props: {
  selectedLicense: LICENSE_DATA | any,
  setSelectedLicense: (license: LICENSE_DATA | undefined) => void,
  showExpiredLicenses: boolean,
  provider: any,
  account: string | undefined,
  chainId: number | undefined,
  pending: boolean
}) => {

  const [userLicenses, setUserLicenses] = useState<any[]>([]);
  const [licensesLoaded, setLicensesLoaded] = useState<boolean>(false);
  const { screenWidth } = useScreenSize();

  const chainId = props.chainId;
  const account = props.account;
  const provider = props.provider;

  const noLicense:LICENSE_DATA = {
    colDiscount: 0,
    currentPoolCount: BigNumber.from("0"),
    lendDiscount: 0,
    expiry: 0,
    maxPoolCount: BigNumber.from("0"),
    id: BigNumber.from("99999")
  }

  useEffect(() => {
    if (account && provider && !props.pending) fetchUserLicenses();
    else if (!account) setLicensesLoaded(true);
    // eslint-disable-next-line
  }, [account, chainId, props.pending]);

  const fetchUserLicenses = async () => {

    const licenseContract = getLicenseEngineContract(provider, chainId as number);
    let licenses = [];

    try {

      // get user license balance
      const licenseBalance = await licenseContract.balanceOf(account); 
      const formattedBalance = parseFloat(ethers.utils.formatUnits(licenseBalance, 0));

      // find user's licenses 
      if (licenseBalance.gt(BigNumber.from("0"))) {
        for (let i = 0; i < formattedBalance; i++) {
          const id = await licenseContract.tokenOfOwnerByIndex(account, i)
          // get the data for the license
          const data = await licenseContract.licenses(id);
          const { colDiscount, currentPoolCount, discount, expiry, maxPoolCount } = data;

          // setup object to save
          const licenseData:LICENSE_DATA = {
            colDiscount,
            currentPoolCount,
            lendDiscount: discount,
            expiry: expiry * 1000,
            maxPoolCount,
            id
          }

          // add to array 
          licenses.push(licenseData);
        }
      }

    } catch (e) {
      console.log(e);
      console.log("failed to fetch licenses");
    }

    // update state
    setUserLicenses(licenses);
    setLicensesLoaded(true);
  }

  const shouldRenderRow = (data: LICENSE_DATA) => {
    const isExpired = Number(data.expiry) < new Date().getTime();
    const isValid = data.currentPoolCount.lt(data.maxPoolCount);
    let show = !isExpired && isValid;
    return show || props.showExpiredLicenses;
  }

  const manageChevron = (_sortBy: string) => {
    /*
    if (userLicenses.length === 0) return null;
    if (sortBy === _sortBy) {
      if (sortDirection === "ascending") return <ChevronUp/>
      if (sortDirection === "descending") return <ChevronDown/>
    }
    */
  }

  const renderLicenseRows = () => {

    const notice = (
      <Alert severity="error">
        No licenses available. Visit <a href="https://docs.vendor.finance/" target={"_blank"} rel="noreferrer">our docs</a> to find more info 
      </Alert>
    );

    if (!licensesLoaded)
      return (
        <Alert severity="info">
          Fetching Licenses...
        </Alert>
      )
    else if (userLicenses.length === 0)
      return (notice);
    else {
      const filteredLicenses = userLicenses.filter(shouldRenderRow);
      if (filteredLicenses.length > 0)
        return (
          <Fragment>
            {userLicenses
            .filter(shouldRenderRow)
            .map((license: LICENSE_DATA, index: number) => (
              <LicenseRow 
                key={index} 
                licenseData={license}
                selectedLicense={props.selectedLicense}
                setSelectedLicense={props.setSelectedLicense}
              />
            ))}
          </Fragment>
        )
      else 
        return (notice);
    }
  }

  const headerClicked = () => {

  }

  const renderDiscountHeaders = () => {
    if (screenWidth > mobileBreakpoint) {
      return (
        <Fragment>
          <div className="license-column-header" onClick={headerClicked}>
            <span>Lend Discount</span>
            {manageChevron("lend discount")}
          </div>
          <div className="license-column-header" onClick={headerClicked}>
            <span>{screenWidth > mobileBreakpoint ? "Collateral Discount" : "Col Discount"}</span>
            {manageChevron("collateral discount")}
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="license-column-header" onClick={headerClicked}>
            <span>Lend/Col Discount</span>
            {manageChevron("lend discount")}
          </div>
        </Fragment>
      );
    }
  }

  return (
    <div className="license-table-wrapper">
      <div className="license-table-header">
        <span className="license-table-header-text">Licenses Available</span>
        <span className="license-table-header-text" onClick={() => props.setSelectedLicense(noLicense)}>Clear Selection <XCircle/></span>
      </div>
      <div className="license-rows-header-container">
        {renderDiscountHeaders()}
        <div className="license-column-header" onClick={headerClicked}>
          <span>{screenWidth > mobileBreakpoint ? "License Expiry" : "Expiry"}</span>
          {manageChevron("license expiry")}
        </div>
        <div className="license-column-header pool-count-header" onClick={headerClicked}>
          <span>{screenWidth > mobileBreakpoint ? "Pools Remaining" : "Remaining"}</span>
          {manageChevron("pools remaining")}
        </div>
      </div>
      {renderLicenseRows()}
    </div>
  );
}

export default LicenseTable;