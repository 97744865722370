import React, { useState, useEffect, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import "./LendingPool.css";
import TokenSelector from "../TokenSelector/TokenSelector";
import Selector from "../Selector/Selector";
import ValueDisplay from "../ValueDisplay/ValueDisplay";
import ActionButton from "../ActionButton/ActionButton";
import RepayRow from "../RepayRow/RepayRow";
import RolloverRow from "../RolloverRow/RolloverRow";
import RepayActionRow from "../RepayActionRow/RepayActionRow";
import { ethers, Contract, BigNumber } from "@usedapp/core/node_modules/ethers";
import { Contract as MulticallContract } from "ethers-multicall";
import LendingPoolABI from "../../abi/LendingPool.js";
import ERC20ABI from "../../abi/ERC20.js";
import moment from "moment";
import { ArrowDown, ExternalLink, HelpCircle, X } from "react-feather";
import ReactTooltip from "react-tooltip";
import Alert from "@mui/material/Alert";
import {
  getDisplayNumber,
  getRepayToWithdraw,
  getWithdrawToRepay,
  truncateNumber,
  getTransactionUrl,
  supportedNetworks,
  fetchLocalTokenData,
  tabletBreakpoint,
  readableABIs,
  getRolloverPools,
  pollTime,
  getFeeManagerContract,
  handleMulticallAddress,
  getGasLimit,
  getNetworkData
} from "../../utils/Utils";
import TransactionReview from "../TransactionReview/TransactionReview";
import { useSnackbar } from "notistack";
import Tab from "../Tab/Tab";
import { PuffLoader } from "react-spinners";
import { useCookies } from "react-cookie";
import { WalletDataContext } from "../../context/WalletDataContext";
import { APP_DATA_CONTEXT, METHOD_TYPE, POOL_DATA, POOL_DATA_CONTEXT, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { AppDataContext } from "../../context/AppDataContext";
import { PoolDataContext } from "../../context/PoolDataContext";
import { useScreenSize } from "../../utils/useScreenSize";
import { useInterval } from "@usedapp/core";

const LendingPool = (props: {
  updatePoolData: (pool?: any) => void,
  poolAddress: string,
  poolData?: any,
  defaultMode?: string,
}) => {

  // eslint-disable-next-line
  const [mode, setMode] = useState<string>(
    props.defaultMode ? props.defaultMode : "borrow"
  );

  const defaultCollateral = "WETH";
  const defaultLend = "DAI";

  // pool stats (not entirely)
  const [colDecimals, setColDecimals] = useState("18");
  const [lendDecimals, setLendDecimals] = useState("18");
  const [poolAddress, setPoolAddress] = useState<string>();
  const [colAddress, setColAddress] = useState("");
  const [lendAddress, setLendAddress] = useState("");
  const [expiry, setExpiry] = useState<number>(0);
  const [mintRatio, setMintRatio] = useState("");
  const [feeRate, setFeeRate] = useState(0.0);
  const [rawFeeRate, setRawFeeRate] = useState("");
  const [colSymbol, setColSymbol] = useState(defaultCollateral);
  const [lendSymbol, setLendSymbol] = useState(defaultLend);
  const [colAllowance, setColAllowance] = useState<string>("0");
  const [lendAllowance, setLendAllowance] = useState<string>("0");
  const [isExpired, setIsExpired] = useState(false);
  const [isUnder, setIsUnder] = useState(false);
  const [lendTokenFloatingPoint, setLendTokenFloatingPoint] = useState(0);
  const [colTokenFloatingPoint, setColTokenFloatingPoint] = useState(0);
  const [colPrice, setColPrice] = useState(0);
  const [lendPrice, setLendPrice] = useState(0);
  const [ltv, setLtv] = useState<number>(0);
  // eslint-disable-next-line
  // user col and lend balance
  const [lendBalance, setLendBalance] = useState<BigNumber>(BigNumber.from("0"));
  const [colBalance, setColBalance] = useState<BigNumber>(BigNumber.from("0"));
  // pool lend balance
  const [poolLendBalance, setPoolLendBalance] = useState<BigNumber>(BigNumber.from("0"));
  const [disabledBorrow, setDisabledBorrow] = useState(false);
  // user debt vars
  const [totalFees, setTotalFees] = useState("0");
  const [rawTotalFees, setRawTotalFees] = useState<BigNumber>(BigNumber.from("0"));
  const [colAmount, setColAmount] = useState("0");
  const [borrowAmount, setBorrowAmount] = useState("0");
  const [rawBorrowAmount, setRawBorrowAmount] = useState<BigNumber>(BigNumber.from("0"));
  // inputs
  const [colField, setColField] = useState<string>("");
  const [lendField, setLendField] = useState<string>("");
  const [rolloverPools, setRolloverPools] = useState<POOL_DATA[]>([]);
  // rollover pool data is loaded in it's own component
  const { enqueueSnackbar /* closeSnackbar */ } = useSnackbar();
  // rollover review
  const [feesDue, setFeesDue] = useState<any>(0);
  const [amountDue, setAmountDue] = useState(0);
  const [totalDue, setTotalDue] = useState(0);
  const [colReimbursed, setColReimbursed] = useState(0);
  const [selectedRollover, setSelectedRollover] = useState<number>(-1);
  const [actionButtonTitle, setActionButtonTitle] = useState<string>("");
  const [userIsWhitelisted, setUserIsWhitelisted] = useState<boolean>(false);
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [cookies] = useCookies(['infiniteApprovalDisabled']);
  const { screenWidth } = useScreenSize();

  // load contexts
  const { provider, chainId, account, multicallProvider } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { selectedPool, setSelectedPool } = useContext(PoolDataContext) as POOL_DATA_CONTEXT;
  const { pending, setPending, getPoolData, fullPoolData } = useContext(AppDataContext) as APP_DATA_CONTEXT;

  useEffect(() => {
    if (provider) parsePoolData();
    resetInputs();
    getBalanceData();
    // eslint-disable-next-line
  }, [selectedPool]);

  // reset data on tab change
  useEffect(() => {
    resetInputs();
    // eslint-disable-next-line
  }, [mode]);

  useEffect(() => {
    manageActionButtonTitle();
  });

  // periodically update balances
  useInterval(() => {
    getBalanceData(true);
  }, pollTime);

  // get user token balances and pool balances
  const getBalanceData = async (polling?: boolean) => {
    if (!selectedPool) return;

    // only set loading flag if lend or collateral token are different
    if (
      lendAddress.toLowerCase() !== selectedPool._lendToken.toLowerCase() ||
      colAddress.toLowerCase() !== selectedPool._colToken.toLowerCase()
    )
      setLoading(true);

    // setup multicall contracts
    const lendTokenContract = new MulticallContract(selectedPool._lendToken, readableABIs.erc20);
    const colTokenContract = new MulticallContract(selectedPool._colToken, readableABIs.erc20);

    // default account based values to 0
    let userLendBalance = BigNumber.from("0");
    let userColBalance = BigNumber.from("0");
    let lendAllowance = BigNumber.from("0");
    let colAllowance = BigNumber.from("0");

    // collect account based calls
    if (account) {
      // setup calls to execute
      let calls:any[] = [
        // userColBalance
        colTokenContract.balanceOf(account),
        // userLendBalance 
        lendTokenContract.balanceOf(account),
        // lendAllowance
        lendTokenContract.allowance(account, ethers.utils.getAddress(selectedPool.id)),
        // colAllowance 
        colTokenContract.allowance(account, ethers.utils.getAddress(selectedPool.id))
      ];
      // execute contract calls 
      const response = await multicallProvider.all(calls);
      // extract data from calls
      [
        userColBalance,
        userLendBalance,
        lendAllowance,
        colAllowance
      ] = response;
      // set user based state vars
      setLendBalance(userLendBalance);
      setColBalance(userColBalance);
    }

    // fetch data for current pool
    if (polling)
      await getPoolData([selectedPool], true);

    const {
      interestOwed,
      // rawInterestOwed,
      rawUserInterestOwed,
      poolLendBalance,
      // poolInterestOwed,
      lendDecimals,
      colDecimals,
      // rawTotalFees,
      userColDeposited,
      borrowAmount,
      _borrowers
    } = fullPoolData[selectedPool.id];

    // get rollover pools for the selected pool
    const potentialRollovers = await getRolloverPools(selectedPool, selectedPool._deployer, chainId);
    const validRolloverPools = await checkPoolWhitelist(potentialRollovers);

    // update state
    setPoolLendBalance(ethers.utils.parseUnits(poolLendBalance, lendDecimals));
    setRolloverPools(validRolloverPools);
    setTotalFees(interestOwed);
    setRawTotalFees(rawUserInterestOwed);
    setColAmount(userColDeposited);
    setBorrowAmount(borrowAmount);
    setRawBorrowAmount(ethers.utils.parseUnits(borrowAmount, lendDecimals));
    setColAllowance(ethers.utils.formatUnits(colAllowance, colDecimals));
    setLendAllowance(ethers.utils.formatUnits(lendAllowance, lendDecimals));
    setUserIsWhitelisted(_borrowers.indexOf(account) > -1);
    setLoading(false);

  }

  const checkPoolWhitelist = async (potentialRollovers: POOL_DATA[]) => { 
    try {
      // setup the correct multicall address
      handleMulticallAddress(chainId, multicallProvider);
      // get all calls needed for multicall
      const calls = potentialRollovers.map((pool: POOL_DATA) => {
        const poolContract = new MulticallContract(
          ethers.utils.getAddress(selectedPool.id),
          readableABIs.lendingPool
        );
        return (poolContract.allowedRollovers(ethers.utils.getAddress(pool.id)));
      });
      // execute contract calls 
      const response = await multicallProvider.all(calls);
      let rolloverPool;
      // check if any of the results return true and set it as the selected pool
      response.forEach((isSelected: boolean, index: number) => {
        if (isSelected) {
          rolloverPool = potentialRollovers[index];
        }
      });
      // return array with one rollover pool if it exists
      if (rolloverPool)
        return [rolloverPool];
      else return [];
    } catch (e) {
      console.log(e);
      return potentialRollovers;
    }
  }

  const rolloverPoolDataLoaded = (data: any) => {
    // get the index of the loaded pool
    const index = rolloverPools.findIndex((pool: any) => pool.id === data.id);
    if (index === -1) return;
    // remove from list if borrowing is disabled
    if (data.disabledBorrow) {
      let copy = [...rolloverPools];
      copy.splice(index, 1);
      setRolloverPools(copy);
    }
  }

  const resetInputs = () => {
    setLendField("");
    setColField("");
    setSelectedRollover(-1);
    // TODO: I think commenting this out will cause
    // the rollover rows to persists between different pools 
    // which is not ideal
    // setRolloverPools([]);
  };

  const reloadData = async () => {
    setPending(false);
    // wait x ms and then reload data for pool
    setTimeout(async () => {
      getBalanceData();
    }, 1000);
  };

  const renderRolloverPools = () => {
    if (loading) {
      return (
        <span className="rollover-pool-notice">
          <PuffLoader className="rollover-loading-animation fade-in"/>
        </span>
      );
    }
    return (
      <Fragment>
        <span className="rollover-pool-notice">
          {rolloverPools.length === 0 ? "No Rollovers Available" : ""}
        </span>
        {rolloverPools.map((pool, index) => (
          <RolloverRow
            rolloverRowClicked={rolloverRowClicked}
            active={selectedRollover === index}
            oldExpiry={expiry}
            oldPoolAddress={props.poolAddress}
            pool={pool}
            index={index}
            key={index}
            colPrice={colPrice}
            lendPrice={lendPrice}
            rolloverPoolDataLoaded={rolloverPoolDataLoaded}
          />
        ))}
      </Fragment>
    );
  };

  // parse pool data that is passed in and update the LendingPool
  // this is used so that pool data isn't reloaded every time the
  // selected pool is changed
  const parsePoolData = async () => {
    try {
      const {
        id,
        // amountOwed,
        // annualizedFeeRate,
        currentFeeRate,
        ltv,
        colSymbol,
        lendSymbol,
        colDecimals,
        lendDecimals,
        poolLendBalance,
        colPrice,
        lendPrice,
        isUnder,
        _mintRatio,
        _expiry,
        _colToken,
        _lendToken,
        _borrowers,
        borrowingDisabled
      } = selectedPool;
      if (_colToken !== colAddress || _lendToken !== lendAddress)
        setLoading(true);

      const lendData = fetchLocalTokenData(selectedPool._lendToken, chainId);
      const colData = fetchLocalTokenData(selectedPool._colToken, chainId);

      // @ts-ignore
      setColTokenFloatingPoint(lendData.displayDecimals);
      // @ts-ignore
      setLendTokenFloatingPoint(colData.displayDecimals);
      setLendField("");
      setColField("");
      setPoolAddress(id);
      setFeeRate(Number(currentFeeRate || 0) / 10000);
      setPoolLendBalance(
        ethers.utils.parseUnits(poolLendBalance, lendData.tokenDecimals)
      );
      setIsPrivate(_borrowers.length > 0);
      setIsExpired(Number(_expiry) * 1000 < new Date().getTime());
      setMintRatio(ethers.utils.formatEther(_mintRatio));
      setLtv(ltv);
      setRawFeeRate(currentFeeRate.toString());
      setExpiry(Number(_expiry));
      setColAddress(_colToken);
      setLendAddress(_lendToken);
      setColDecimals(colDecimals.toString());
      setLendDecimals(lendDecimals.toString());
      setColSymbol(colSymbol);
      setLendSymbol(lendSymbol);
      setIsUnder(isUnder);
      setDisabledBorrow(borrowingDisabled);
      setColPrice(colPrice);
      setLendPrice(lendPrice);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  // update transaction review vars
  const rolloverRowClicked = (
    _colSymbol: string,
    _lendSymbol: string,
    _mintRatio: string,
    _expiry: number,
    _feeRate: number,
    index: number
  ) => {
    /*
      1) Fee Due - UserReport.totalFees
      2) Amount Due:
        if new mint ratio is less than the old mintratio then: (oldMintRatio-NewMintRatio)*UserReport.colAmount
        else 0
      3)Total Due is the some of two above
      4) Collateral Reim. 
        if NewMintRatio > oldMintRatio then (newMintRAtio-OldMintratio)/newMintRatio * UserReport.colAmount
        else 0
    */
    const amountDue =
      parseFloat(_mintRatio) < parseFloat(mintRatio)
        ? (parseFloat(mintRatio) - parseFloat(_mintRatio)) *
          parseFloat(colAmount)
        : 0;

    // console.log(_mintRatio)
    // console.log(mintRatio)
    const colReimbursed =
      parseFloat(_mintRatio) > parseFloat(mintRatio)
        ? ((parseFloat(_mintRatio) - parseFloat(mintRatio)) /
            parseFloat(_mintRatio)) *
          parseFloat(colAmount)
        : 0;
    // console.log(_mintRatio > mintRatio)
    setFeesDue(totalFees);
    setAmountDue(amountDue);
    const totalDue = truncateNumber(amountDue + parseFloat(totalFees),Number(lendDecimals)).toLocaleString();
    setTotalDue(parseFloat(totalDue));
    setColReimbursed(colReimbursed);
    setSelectedRollover(index);
  };

  const hide = {
    display: "none",
  };

  // get number of digits after decimal
  const getFractionalLength = (v: string) => {
    if (v.indexOf(".") > -1) {
      return (v.split(".")[1].length);
    }
    return 0;
  }

  // get number of digits before decimal
  const getIntegerLength = (v: string) => {
    if (v.indexOf(".") > -1) {
      return (v.split(".")[0].length);
    }
    return v.length;
  }

  const syncBorrowTokenFields = (_amount: any, field: string) => {
    // return if input is blank
    if (_amount === "") {
      setColField("");
      setLendField("");
      return;
    }

    // check if new input is a number and check if decimal places is less than token decimals
    const tokenDecimals = field === "0" ? selectedPool.colDecimals : selectedPool.lendDecimals;
    if (
      isNaN(_amount) || 
      getFractionalLength(_amount) > tokenDecimals || 
      getIntegerLength(_amount) > 18
    ) return;

    const _mintRatio = ethers.utils.parseUnits(mintRatio, 18);

    if (field === "0") {
      const amount = ethers.utils.parseUnits(_amount, colDecimals);
      const term1 = amount
        .mul(_mintRatio)
        .mul(ethers.utils.parseUnits("1", lendDecimals));
      const term2 = term1.div(ethers.utils.parseUnits("1", colDecimals));
      const term3 = term2.div(ethers.utils.parseUnits("1", 18));
      setLendField(getDisplayNumber(term3, lendSymbol, false));
      setColField(_amount);
    } else {
      const amount = ethers.utils.parseUnits(_amount, lendDecimals);
      const num = amount
        .mul(ethers.utils.parseUnits("1", 18))
        .mul(ethers.utils.parseUnits("1", colDecimals));
      const denom = ethers.utils.parseUnits("1", lendDecimals).mul(_mintRatio);
      setColField(getDisplayNumber(num.div(denom), colSymbol, false));
      setLendField(_amount);
    }
  };

  const syncLendTokenFields = (_amount: any, field: string) => {
    // return if input is blank
    if (_amount === "") {
      setColField("");
      setLendField("");
      return;
    }
    // check if new input is a number and check if decimal places is less than token decimals
    const tokenDecimals = field === "0" ? selectedPool.lendDecimals: selectedPool.colDecimals;
    if (
      isNaN(_amount) || 
      getFractionalLength(_amount) > tokenDecimals || 
      getIntegerLength(_amount) > 18
    ) return;
    // extract debt values
    if (field === "0") {
      const amount = ethers.utils.parseUnits(_amount, lendDecimals);
      const synced = getRepayToWithdraw(
        amount,
        ethers.utils.parseUnits(totalFees, lendDecimals),
        ethers.utils.parseUnits(mintRatio, 18),
        colDecimals,
        lendDecimals
      );
      setColField(getDisplayNumber(synced, colSymbol, false));
      setLendField(_amount);
    } else {
      const amount = ethers.utils.parseUnits(_amount, colDecimals);
      const synced = getWithdrawToRepay(
        amount,
        ethers.utils.parseUnits(totalFees, lendDecimals),
        ethers.utils.parseUnits(mintRatio, 18),
        colDecimals,
        lendDecimals
      );
      setLendField(getDisplayNumber(synced, lendSymbol, false));
      setColField(_amount);
    }
  };

  // If repay is true then check allowance on the lend token
  // If repay is false then check allowance on the collateral token
  const approve = async (repay: boolean) => {
    if (!supportedNetworks.includes(chainId as number) || !selectedPool || !provider) return;
    setPending(true);
    enqueueSnackbar("Please confirm the approval transaction in your wallet", {
      persist: false,
      disableWindowBlurListener: true,
    });
    if (repay) {
      try {
        // infinite approval vs. specific approval
        let approvalAmount;
        if (mode !== "rollover") {
          approvalAmount = cookies.infiniteApprovalDisabled === "true"
            ? ethers.utils.parseUnits(lendField, lendDecimals)
            : ethers.constants.MaxUint256;
        } else {
          approvalAmount = cookies.infiniteApprovalDisabled === "true"
            ? ethers.utils.parseUnits(totalDue.toString(), lendDecimals)
            : ethers.constants.MaxUint256;
        }
        // setup token contract reference
        let tokenContract = new Contract(
          lendAddress,
          ERC20ABI,
          provider.getSigner()
        );
        let tx = await tokenContract.approve(
          poolAddress,
          approvalAmount,
          {
            ...getGasLimit(chainId, METHOD_TYPE.APPROVE)
          }
        );
        console.log("Approving");
        enqueueSnackbar(`Executing approval transaction ** ${getTransactionUrl(tx.hash, chainId)} ** persist`);
        await tx.wait();
        let newAllowance = await tokenContract.allowance(
          account,
          poolAddress
        );
        setLendAllowance(ethers.utils.formatUnits(newAllowance, lendDecimals));
        enqueueSnackbar(`Approval successful ** ${getTransactionUrl(tx.hash, chainId)}`);
      } catch (e) {
        console.log(e);
        enqueueSnackbar(
          `Approval failed`,
          {
            persist: false,
            disableWindowBlurListener: true,
          }
        );
        setPending(false);
        return;
      }
    } else {
      try {
        // infinite approval vs. specific approval
        const approvalAmount = cookies.infiniteApprovalDisabled === "true"
          ? ethers.utils.parseUnits(colField, colDecimals)
          : ethers.constants.MaxUint256;
        // setup token contract reference
        let tokenContract = new Contract(
          colAddress,
          ERC20ABI,
          provider.getSigner()
        );
        let tx = await tokenContract.approve(
          poolAddress,
          approvalAmount,
          {
            // @ts-ignore
            ...getGasLimit(chainId, METHOD_TYPE.APPROVE)
          }
        );
        console.log("Approving");
        enqueueSnackbar(`Executing approval transaction ** ${getTransactionUrl(tx.hash, chainId)} ** persist`);
        await tx.wait();
        let newAllowance = await tokenContract.allowance(
          account,
          poolAddress
        );
        setColAllowance(ethers.utils.formatUnits(newAllowance, colDecimals));
        enqueueSnackbar(`Approval successful ** ${getTransactionUrl(tx.hash, chainId)}`);
      } catch (e) {
        setPending(false);
        console.log(e);
        enqueueSnackbar(
          `Approval failed`,
          {
            persist: false,
            disableWindowBlurListener: true,
          }
        );
        return;
      }
    }
    reloadData();
    setPending(false);
  };

  const borrow = async () => {
    if (!supportedNetworks.includes(chainId) || !selectedPool || !provider || !poolAddress) return;
    setPending(true);
    enqueueSnackbar("Please confirm the borrow transaction in your wallet", {
      persist: false,
      disableWindowBlurListener: true,
    });
    const poolContract = new Contract(
      poolAddress,
      LendingPoolABI,
      provider.getSigner()
    );

    try {
      const tx = await poolContract.borrowOnBehalfOf(
        account,
        ethers.utils.parseUnits(colField, colDecimals),
        rawFeeRate,
        ethers.utils.parseUnits(borrowAmount, lendDecimals),
        {
          ...getGasLimit(chainId, METHOD_TYPE.BORROW)
        }
      );
      enqueueSnackbar(`Executing borrow transaction ** ${getTransactionUrl(tx.hash, chainId)} ** persist`);
      await tx.wait();
      enqueueSnackbar(`Borrow transaction successful ** ${getTransactionUrl(tx.hash, chainId)}`);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Borrow transaction failed", {
        persist: false,
        disableWindowBlurListener: true,
      });
      setPending(false);
      return;
    }
    setPending(false);
    reloadData();
    resetInputs();
  };

  const repay = async () => {
    if (!supportedNetworks.includes(chainId) || !selectedPool || !provider) return;
    setPending(true);
    const poolAddress = props.poolAddress;
    const poolContract = new Contract(
      poolAddress,
      LendingPoolABI,
      provider.getSigner()
    );
    enqueueSnackbar("Please confirm the repay transaction in your wallet", {
      persist: false,
      disableWindowBlurListener: true,
    });
    try {
      const tx = await poolContract.repayOnBehalfOf(
        account,
        ethers.utils.parseUnits(lendField, lendDecimals),
        {
          // @ts-ignore
          ...getGasLimit(chainId, METHOD_TYPE.REPAY)
        }
      );
      enqueueSnackbar(`Executing repay transaction ** ${getTransactionUrl(tx.hash, chainId)} ** persist`);
      await tx.wait();
      enqueueSnackbar(`Repay transaction successful ** ${getTransactionUrl(tx.hash, chainId)}`);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Repay transaction failed", {
        persist: false,
        disableWindowBlurListener: true,
      });
      setPending(false);
      return;
    }
    setPending(false);
    reloadData();
    resetInputs();
  };

  const rollover = async () => {
    if (!supportedNetworks.includes(chainId) || !selectedPool || !provider) return;
    try {
      setPending(true);
      const poolAddress = props.poolAddress;
      const poolContract = new Contract(
        poolAddress,
        LendingPoolABI,
        provider.getSigner()
      );
      enqueueSnackbar(
        "Please confirm the rollover transaction in your wallet",
        {
          persist: false,
          disableWindowBlurListener: true,
        }
      );

      let rolloverPool: any = rolloverPools[selectedRollover];
      let rolloverPoolAddress: any;
      if (rolloverPool) {
        rolloverPoolAddress = ethers.utils.getAddress(rolloverPool.id);
      } else {
        return;
      }

      // get feeManager contract
      const feeManagerContract = getFeeManagerContract(provider, chainId);
      let feeRateResp = await feeManagerContract.getCurrentRate(rolloverPoolAddress);
      // execute rollover into new pool
      const tx = await poolContract.rollOver(
        rolloverPoolAddress, 
        feeRateResp,
        {
          ...getGasLimit(chainId, METHOD_TYPE.ROLLOVER)
        }
      );
      enqueueSnackbar(`Executing rollover transaction ** ${getTransactionUrl(tx.hash, chainId)} ** persist`);
      await tx.wait();
      enqueueSnackbar(`Rollover transaction successful ** ${getTransactionUrl(tx.hash, chainId)}`);
      setPending(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Rollover transaction failed", {
        persist: false,
        disableWindowBlurListener: true,
      });
      setPending(false);
      return;
    }
    // navigate(`/pool/${selectedPoolAddress}`)
    reloadData();
    resetInputs();
  };

  const manageActionButtonTitle = () => {
    if (!account || account === null || account === "")
      setActionButtonTitle("No Wallet Connected");
    else if (isPrivate && !userIsWhitelisted)
      setActionButtonTitle("Wallet Not Whitelisted For Pool");
    else if (disabledBorrow && mode === "borrow")
      setActionButtonTitle("Borrowing Disabled By Lender");
    else if (isExpired) setActionButtonTitle(`Pool Expired`);
    else if (
      (Number(lendField) === 0 || isNaN(Number(lendField))) &&
      mode !== "rollover"
    )
      setActionButtonTitle("Invalid Lend/Collateral Asset Value");
    else if (parseFloat(colAmount) === 0 && mode === "rollover")
      setActionButtonTitle("No Collateral to Rollover");
    else if (pending) setActionButtonTitle("Executing...");
    else if (
      ethers.utils.parseUnits(lendField || "0", lendDecimals).gt(lendBalance) &&
      mode === "repay"
    )
      setActionButtonTitle(`Insufficient ${lendSymbol} Wallet Balance`);
    else if (
      ethers.utils.parseUnits(colField || "0", colDecimals).gt(colBalance) &&
      mode === "borrow"
    )
      setActionButtonTitle(`Insufficient ${colSymbol} Wallet Balance`);
    else if (
      ethers.utils
        .parseUnits(lendField || "0", lendDecimals)
        .gt(poolLendBalance) &&
      mode === "borrow"
    )
      setActionButtonTitle(`Insufficient ${lendSymbol} Pool Liquidity`);
    else if (
      ethers.utils.parseUnits(lendField || "0", lendDecimals).gt(rawTotalFees.add(rawBorrowAmount)) &&
      mode === "repay"
    )
      setActionButtonTitle("You Cannot Repay More Than Your Debt");
    else if (
      ethers.utils.parseUnits(totalDue.toString(), lendDecimals).gt(lendBalance) &&
      mode === "rollover"
    )
      setActionButtonTitle(`Insufficient ${lendSymbol} Wallet Balance`);
    // reset the title if there is nothing wrong
    else setActionButtonTitle("");
  };

  const getActionButton = () => {
    let title = "";
    let action;
    let disabled;
    disabled =
      Number(lendField) === 0 ||
      isNaN(Number(lendField)) ||
      (Number(colField) === 0 && Number(lendField) > Number(totalFees)) ||
      isNaN(Number(colField)) ||
      isExpired ||
      (isPrivate && !userIsWhitelisted) ||
      (disabledBorrow && mode === "borrow") ||
      pending;
    // set button data
    if (mode === "borrow") {
      action = parseFloat(colAllowance) >= parseFloat(colField) ? borrow : () => approve(false);
      title = parseFloat(colAllowance) >= parseFloat(colField) ? "Borrow" : "Approve";
      // borrow disable logic
      disabled =
        disabled ||
        // is colField greater than user col balance
        ethers.utils.parseUnits(colField, colDecimals).gt(colBalance) ||
        // is lendField greater than pool lend balance
        ethers.utils.parseUnits(lendField, lendDecimals).gt(poolLendBalance);
    } else if (mode === "repay") {
      action = parseFloat(lendAllowance) >= parseFloat(lendField) ? repay : () => approve(true);
      title = parseFloat(lendAllowance) >= parseFloat(lendField) ? "Repay" : "Approve";
      // repay disable logic
      disabled =
        disabled ||
        // is lendField greater than user borrowAmount
        ethers.utils.parseUnits(lendField || "0", lendDecimals).gt(rawTotalFees.add(rawBorrowAmount)) ||
        // is colField greater than user colAmount 
        parseFloat(colField) > parseFloat(colAmount) ||
        // is lendField greater than user lend balance
        ethers.utils.parseUnits(lendField, lendDecimals).gt(lendBalance);
    } else if (mode === "rollover") {
      action = parseFloat(lendAllowance) >= totalDue ? rollover : () => approve(true);
      title = parseFloat(lendAllowance) >= totalDue ? "Rollover" : "Approve";
      // rollover disable logic
      disabled =
        pending ||
        selectedRollover === -1 ||
        parseFloat(colAmount) === 0 ||
        ethers.utils.parseUnits(totalDue.toString(), lendDecimals).gt(lendBalance) ||
        isExpired;
    }

    return (
      <ActionButton
        title={actionButtonTitle === "" ? title : actionButtonTitle}
        action={action}
        disabled={disabled}
      />
    );
  };

  const getDaysUntilExpiry = () => {
    const expiryTime = moment(expiry * 1000);
    return `${expiryTime.fromNow(true)}`;
  };

  const formatDate = () => {
    const expiryTime = moment(expiry * 1000);
    return `${expiryTime.format("MMM. D, YYYY hh:mma")}`
  }

  // populate transaction review field
  const getTransactionInfo = () => {
    let data;
    if (isExpired) {
      data = (
        <Fragment>
          <Alert severity="error" className="transaction-alert">
            This pool has expired. All non-repaid collateral was forfeited
          </Alert>
        </Fragment>
      );
    } else if (disabledBorrow && mode === "borrow") {
      data = (
        <Fragment>
          <Alert severity="error" className="transaction-alert">
            The owner of this pool has disabled borrowing. Repaying owed debts
            is still enabled
          </Alert>
        </Fragment>
      );
    } else if (isUnder && mode === "borrow") {
      data = (
        <Fragment>
          <Alert severity="error" className="transaction-alert">
            Collateral price is below the lend ratio. Borrowing is temporarily
            disabled. You can still repay your loan.
          </Alert>
        </Fragment>
      );
    } else if (mode === "borrow") {
      let loanAmount =
        isNaN(Number(lendField)) || lendField === ""
          ? 0
          : parseFloat(lendField);
      let fee = loanAmount * (feeRate / 100);
      let repaymentAmount = loanAmount + fee;
      data = (
        <div className="transaction-info-text">
          <div className="info-item">
            <span className="info-left">Loan Amount:</span>
            <span>
              {truncateNumber(
                loanAmount,
                lendTokenFloatingPoint + 2
              ).toLocaleString()}{" "}
              {lendSymbol}
            </span>
          </div>
          <div className="info-item">
            <span className="info-left">Interest Due:</span>
            <span>
              {truncateNumber(fee, lendTokenFloatingPoint + 2).toLocaleString()}{" "}
              {lendSymbol}
            </span>
          </div>
          <div className="info-item">
            <span className="info-left">Total Repayment Amount:</span>
            <span>
              {truncateNumber(
                repaymentAmount,
                lendTokenFloatingPoint
              ).toLocaleString()}{" "}
              {lendSymbol}
            </span>
          </div>
          {lendField.length > 0 &&
            <Alert severity="warning" className="transaction-alert">
              You must repay {repaymentAmount.toLocaleString(undefined, { maximumFractionDigits: 4})} {lendSymbol} before {formatDate()} to retrieve all of your {colSymbol}
            </Alert>
          }
        </div>
      );
    } else if (mode === "repay") {
      const lendInput: number =
        isNaN(parseFloat(lendField)) || lendField === ""
          ? 0
          : parseFloat(lendField);
      const transactionTotal = lendInput;
      const towardInterest = Math.min(lendInput, parseFloat(totalFees));
      const towardsLoan = Math.max(0, lendInput - towardInterest);
      const receiveAmount =
        Math.max(0, lendInput - towardInterest) / Number(mintRatio);

      data = (
        <Fragment>
          <div className="info-item">
            <span className="info-left">{colSymbol} Received:</span>
            <span>
              {truncateNumber(
                receiveAmount || 0,
                colTokenFloatingPoint + 3
              ).toLocaleString()}{" "}
              {colSymbol}
            </span>
          </div>
          <div className="info-item">
            <span className="info-left">Payment Towards Interest:</span>
            <span>
              {truncateNumber(
                towardInterest,
                lendTokenFloatingPoint + 3
              ).toLocaleString()}{" "}
              {lendSymbol}
            </span>
          </div>
          <div className="info-item">
            <span className="info-left">Payment Towards Loan:</span>
            <span>
              {truncateNumber(
                towardsLoan,
                lendTokenFloatingPoint + 3
              ).toLocaleString()}{" "}
              {lendSymbol}
            </span>
          </div>
          <div className="info-item">
            <span className="info-left">Transaction Total:</span>
            <span>
              {truncateNumber(
                transactionTotal,
                lendTokenFloatingPoint + 2
              ).toLocaleString()}{" "}
              {lendSymbol}
            </span>
          </div>
          <div className="info-item">
            <span className="info-left">Time until expiry:</span>
            <span>{getDaysUntilExpiry()}</span>
          </div>
        </Fragment>
      );
    } else if (mode === "rollover") {
      data = (
        <Fragment>
          <ReactTooltip 
            id="amount-due-tip" 
            type="info" 
            className="tool-tip"
            place={screenWidth > tabletBreakpoint ? "top" : "right"}
            arrowColor="var(--iris)"
          >
            <span>
              If new pool has a smaller lend ratio, you can borrow less tokens
              against your collateral. This amount is the difference you need to
              return.
            </span>
          </ReactTooltip>
          <ReactTooltip 
            id="reimbursed-tip" 
            type="info" 
            className="tool-tip"
            place={screenWidth > tabletBreakpoint ? "top" : "right"}
            arrowColor="var(--iris)"
          >
            <span>
              If new pool has a larger lend ratio, you can borrow more tokens
              against your collateral. This amount is the amount of collateral
              you will get back.
            </span>
          </ReactTooltip>
          <div className="info-item">
            <span className="info-left">Fees Due:</span>
            <span>
              {truncateNumber(
                feesDue,
                lendTokenFloatingPoint + 2
              ).toLocaleString()}{" "}
              {lendSymbol}
            </span>
          </div>
          <div className="info-item">
            <span className="info-left" data-tip data-for="amount-due-tip">
              Amount Due:
              <HelpCircle />
            </span>
            <span>
              {truncateNumber(
                amountDue,
                lendTokenFloatingPoint + 2
              ).toLocaleString()}{" "}
              {lendSymbol}
            </span>
          </div>
          <div className="info-item">
            <span className="info-left">Total Due: </span>
            <span>
              {truncateNumber(
                totalDue,
                lendTokenFloatingPoint + 2
              ).toLocaleString()}{" "}
              {lendSymbol}
            </span>
          </div>
          <div className="info-item">
            <span className="info-left" data-tip data-for="reimbursed-tip">
              Collateral Reimbursed:
              <HelpCircle />
            </span>
            <span>
              {truncateNumber(
                colReimbursed,
                colTokenFloatingPoint + 2
              ).toLocaleString()}{" "}
              {colSymbol}
            </span>
          </div>
        </Fragment>
      );
    }
    return data;
  };

  const getInputPrice = (input: any) => {
    let price = 0;
    if (input === 0) {
      // collateral
      price = parseFloat(colField) * colPrice;
    } else {
      // lend
      price = parseFloat(lendField) * lendPrice;
    }
    return isNaN(price) ? 0 : price;
  };

  const getBorrowView = () => {
    return (
      <div
        className="borrow-view fade-in"
        style={mode !== "borrow" ? hide : {}}
      >
        <div className="token-input-container">
          <div className="row-title">
            Collateral
            {getInputPrice(0) > 0 ? (
              <span className="input-price">
                ~$
                {getInputPrice(0).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="token-selector-long-wrapper">
            <TokenSelector
              value={colField}
              balance={colBalance || "0"}
              symbol={colSymbol}
              sync={syncBorrowTokenFields}
              id="0"
              text={"Balance"}
              loading={loading}
            ></TokenSelector>
          </div>
          <div className="mid-separator">
            <ArrowDown />
          </div>
          <div className="row-title" onClick={() => props.updatePoolData(selectedPool)}>
            To Borrow
            {getInputPrice(1) > 0 ? (
              <span className="input-price">
                ~$
                {getInputPrice(1).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="token-selector-long-wrapper">
            <TokenSelector
              value={lendField}
              balance={poolLendBalance}
              text={"Pool Balance"}
              symbol={lendSymbol}
              sync={syncBorrowTokenFields}
              id="1"
              loading={loading}
            ></TokenSelector>
          </div>
        </div>
        <div className="info-section-container">
          <div className="info-title-container">
            <ReactTooltip 
              id="expiry-tip" 
              type="info" 
              className="tool-tip"
              place={screenWidth > tabletBreakpoint ? "top" : "right"}
              effect={screenWidth > tabletBreakpoint ? "float" : "solid"}
              arrowColor="var(--iris)"
            >
              <span>
                Date by which the loan has to be repaid or rolled over. Partial
                payments are supported.
              </span>
            </ReactTooltip>
            <ReactTooltip 
              id="ltv-tip" 
              type="info" 
              className="tool-tip"
              effect={screenWidth > tabletBreakpoint ? "float" : "solid"}
            >
              <span>
                Your LTV is determined at the time of your successful borrowing
                transaction. Your lend ratio will never change. If the LTV of
                this pool is above 100% at time of expiry, you are better off
                defaulting than repaying. Keep track of the LTV in the “My
                Pools” tab.
              </span>
            </ReactTooltip>
            <ReactTooltip 
              id="apr-tip" 
              type="info" 
              className="tool-tip"
              arrowColor="var(--iris)"
              effect={screenWidth > tabletBreakpoint ? "float" : "solid"}
            >
              <span>
                Fee owed on top of borrowed amount. Due before expiry.
              </span>
            </ReactTooltip>
            <div className="info-title expiry-title">
              Expiry <HelpCircle data-tip data-for="expiry-tip"/>
            </div>
            <div className="info-title ltv-title">
              LTV <HelpCircle data-tip data-for="ltv-tip"/>
            </div>
            <div className="info-title term-fee-title">
              Term Fee <HelpCircle data-tip data-for="apr-tip"/>
            </div>
          </div>

          <div className="info-container">
            <Selector
              text=""
              value={expiry * 1000}
              type="date"
              disabled={true}
            ></Selector>
            <ReactTooltip
              id="lend-ratio-value-tip"
              type="info"
              className="tool-tip"
              effect={screenWidth > tabletBreakpoint ? "float" : "solid"}
            >
              <span>Lend Ratio: {mintRatio}</span>
            </ReactTooltip>
            <div className="info-value ltv-value" data-tip data-for="lend-ratio-value-tip">
              <ValueDisplay rate={ltv}></ValueDisplay>
            </div>
            <div className="info-value term-fee-value">
              <ValueDisplay rate={feeRate}></ValueDisplay>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // get the value to auto fill into the repay row 
  // input when the user clicks their balance
  const getMaxClickValue = () => {
    const debt = ethers.utils
      .parseUnits(borrowAmount, lendDecimals)
      .add(ethers.utils.parseUnits(totalFees, lendDecimals))
    return (debt.lte(lendBalance) ? debt : lendBalance);
  }

  const getRepayView = () => {
    // prevent overflow/underflow crashing by always rendering some kind of component
    try {
      return (
        <div className="repay-view" style={mode !== "repay" ? hide : {}}>
          <div className="row-header">
            <span>Lend Ratio</span>
            <span>Expiry</span>
            <span>Total Owed</span>
          </div>
          <RepayRow
            colSymbol={colSymbol}
            lendSymbol={lendSymbol}
            mintRatio={mintRatio}
            expiry={expiry}
            borrowAmount={parseFloat(borrowAmount) + parseFloat(totalFees)}
            loading={loading}
          />
          <RepayActionRow
            colSymbol={colSymbol}
            lendSymbol={lendSymbol}
            colFloatPoints={colTokenFloatingPoint}
            lendFloatPoints={lendTokenFloatingPoint}
            repayValue={lendField}
            loading={loading}
            withdrawValue={colField}
            lendBalance={getMaxClickValue()}
            clickValue={getMaxClickValue()}
            colBalance={ethers.utils.parseUnits(colAmount, colDecimals)}
            getInputPrice={getInputPrice}
            sync={syncLendTokenFields}
            text="Max"
          />
        </div>
      );
    } catch (e) {
      // console.log(e);
      return (
        <div className="repay-view" style={mode !== "repay" ? hide : {}}>
          <div className="row-header">
            <span>Lend Ratio</span>
            <span>Expiry</span>
            <span>Total Owed</span>
          </div>
          <RepayRow
            colSymbol={colSymbol}
            lendSymbol={lendSymbol}
            mintRatio={mintRatio}
            expiry={expiry}
            borrowAmount={parseFloat(borrowAmount) + parseFloat(totalFees)}
            loading={loading}
          />
          <RepayActionRow
            colSymbol={colSymbol}
            lendSymbol={lendSymbol}
            colFloatPoints={colTokenFloatingPoint}
            lendFloatPoints={lendTokenFloatingPoint}
            repayValue={lendField}
            withdrawValue={colField}
            lendBalance={BigNumber.from("0")}
            loading={true}
            colBalance={BigNumber.from("0")}
            getInputPrice={getInputPrice}
            sync={syncLendTokenFields}
          />
        </div>
      );
    }
  };

  const getRolloverView = () => {
    return (
      <div className="rollover-view" style={mode !== "rollover" ? hide : {}}>
        <div className="row-header">
          <span>Pair</span>
          <span>LTV</span>
          <span>Expiry</span>
          <span>Term Fee</span>
          <span>APR</span>
        </div>
        <div className="scroll-view">{renderRolloverPools()}</div>
      </div>
    );
  };

  // close the modal if possible
  const modalCloseClicked = () => {
    setSelectedPool(undefined);
  }

  return (
    <div className="lending-pool-wrapper">
      {screenWidth < tabletBreakpoint && 
          <div onClick={modalCloseClicked} className="lending-pool-close-modal-wrapper fade-in">
            <X className="lending-pool-close-modal"/>
          </div>
        }
      <div className="lending-pool-activity-wrapper view-section">
        {mode !== "borrow" && (
          <Tab mode={mode}>
            <span className="tab-item" onClick={() => setMode("repay")}>
              Repay
            </span>
            <span className="tab-item" onClick={() => setMode("rollover")}>
              Rollover
            </span>
          </Tab>
        )}
        {getBorrowView()}
        {getRepayView()}
        {getRolloverView()}
      </div>
      <TransactionReview
        transactionInfo={getTransactionInfo()}
        actionButton={getActionButton()}
        hideHeader={isExpired}
        showReview={true}
        setShowReview={() => {}}
      />
      <Link to={`/borrow/${props.poolAddress}?chain=${getNetworkData(chainId).chainName}`} className="pool-link">
        Go to Pool
        <ExternalLink/>
      </Link>
    </div>
  );
};

export default LendingPool;
