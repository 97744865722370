import { APP_DATA_CONTEXT, FEATURED_POOLS, LOCAL_TOKEN_DATA, POOL_DATA, POOL_DATA_CONTEXT, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
//@ts-ignore
// import gradient1 from "../../img/gradients/gradient1.jpg";
import "./FeaturedPools.css";
import { useContext, useEffect, useState } from "react";
import { defaultChain, fetchLocalTokenData, tokenData } from "../../utils/Utils";
import { WalletDataContext } from "../../context/WalletDataContext";
import { PoolDataContext } from "../../context/PoolDataContext";
import { AppDataContext } from "../../context/AppDataContext";
import { ethers } from "ethers";
import { Clock, DollarSign, Star } from "react-feather";

const FeaturedPools = (props: {
  pools: any
}) => {


  let featuredPoolsObject = {
    1: [
      {
        deployerAddress: "",
        name: "All Pools",
        img: "",
        pools: 0,
        filterMethod: (pools: POOL_DATA[]) => allPoolsFilter(pools) 
      },
      {
        deployerAddress: "",
        name: "Featured Pools",
        img: <Star className="category-icon"/>,
        pools: 0,
        filterMethod: (pools: POOL_DATA[]) => featuredFilterMainnet(pools) 
      },
      {
        deployerAddress: "",
        name: "New Pools",
        img: <Clock className="category-icon"/>,
        pools: 0,
        filterMethod: (pools: POOL_DATA[]) => newPoolsFilter(pools)
      },
    ],
    5: [
      {
        deployerAddress: "",
        name: "All Pools",
        img: "",
        pools: 0,
        filterMethod: (pools: POOL_DATA[]) => allPoolsFilter(pools) 
      },
    ],
    137: [
      {
        deployerAddress: "",
        name: "All Pools",
        img: "",
        pools: 0,
        filterMethod: (pools: POOL_DATA[]) => allPoolsFilter(pools) 
      },
    ],
    42161: [
      {
        deployerAddress: "",
        name: "All Pools",
        img: "",
        pools: 0,
        filterMethod: (pools: POOL_DATA[]) => allPoolsFilter(pools) 
      },
      {
        deployerAddress: "",
        name: "Featured Pools",
        img: <Star className="category-icon"/>,
        pools: 0,
        filterMethod: (pools: POOL_DATA[]) => featuredFilterArb(pools)
      },
      {
        deployerAddress: "",
        name: "New Pools",
        img: <Clock className="category-icon"/>,
        pools: 0,
        filterMethod: (pools: POOL_DATA[]) => newPoolsFilter(pools)
      },
      {
        deployerAddress: "",
        name: "Most Funded",
        img: <DollarSign className="category-icon"/>,
        pools: 0,
        filterMethod: (pools: POOL_DATA[], fullPoolData?: any) => mostFundedFilter(pools, fullPoolData)
      }
    ],
    421613: [
      {
        deployerAddress: "",
        name: "All Pools",
        img: "",
        pools: 0,
        filterMethod: (pools: POOL_DATA[]) => allPoolsFilter(pools) 
      },
      {
        deployerAddress: "",
        name: "Featured Pools",
        img: <Star className="category-icon"/>,
        pools: 0,
        filterMethod: (pools: POOL_DATA[]) => allPoolsFilter(pools) 
      },
    ]
  }

  // import context variables
  const { tokenPrices, setLiquidityRange } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { chainId } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  // @ts-ignore
  const [featuredPools, setFeaturedPools] = useState<FEATURED_POOLS[]>(featuredPoolsObject[chainId ? chainId : defaultChain]);
  const { selectedFeaturedPools, setSelectedFeaturedPools } = useContext(PoolDataContext) as POOL_DATA_CONTEXT;

  // filter for funded pools
  const mostFundedFilter = (pools: POOL_DATA[], _tokenPrices: any) => {
    return pools.filter((pool: POOL_DATA) => {
      if (pool._borrowers.length > 0) return false;
      // get lend/col token balance and value
      let lendData:LOCAL_TOKEN_DATA = fetchLocalTokenData(pool._lendToken, chainId) as any;
      const lendBalance = ethers.utils.formatUnits(pool._lendBalance, lendData.tokenDecimals);
      const lendPrice = _tokenPrices[ethers.utils.getAddress(pool._lendToken)];
      let lendValue = Math.floor(parseFloat(lendBalance) * parseFloat(lendPrice) * 100) / 100;
      if (isNaN(lendValue)) lendValue = 0;
      return lendValue > 3450;
    }).map((pool: POOL_DATA) => pool.id)
  }

  // filter for all pools
  const allPoolsFilter = (pools: POOL_DATA[]) => {
    return pools.map((pool: POOL_DATA) => pool.id.toLowerCase());
  }

  // filter for pools we want to feature
  const featuredFilterMainnet = (pools: POOL_DATA[]) => {
    const tokensOfInterest:string[] = [];
    const whitelist = [
      "0xe47d52677960c82853bd166999af716bd5e1167e",
      "0x83234a159dbd60a32457df158fafcbdf3d1ccc08"
    ]
    return pools.filter((pool: POOL_DATA) => {
      const isJunkPool = pool._lendBalance === "0" && pool._colBalance === "0";
      const isPrivate = pool._borrowers.length > 0;
      return(
        (tokensOfInterest.includes(pool._colToken.toLowerCase()) || 
        tokensOfInterest.includes(pool._lendToken.toLowerCase()) ||
        whitelist.includes(pool.id.toLowerCase())) &&
        !isJunkPool && 
        !isPrivate
      );
    }).map((pool: POOL_DATA) => pool.id)
  }

  // filter for pools we want to feature
  const featuredFilterArb = (pools: POOL_DATA[]) => {
    const tokensOfInterest = [
      // @ts-ignore
      tokenData.GOHM.address[42161].toLowerCase(),
      // @ts-ignore
      tokenData.magicGLP.address[42161].toLowerCase(),
      // @ts-ignore
      tokenData.JGLP.address[42161].toLowerCase(),
      // @ts-ignore
      tokenData.JUSDC.address[42161].toLowerCase(),
      // @ts-ignore
      tokenData.ARB.address[42161].toLowerCase(),
      // @ts-ignore
      tokenData.WETH.address[42161].toLowerCase(),
    ];
    const whitelist = [
      "0xe1fd0754c563b8e1b6fcd1b4a10d5ef36db0dbf0",
      "0x1350c1e4c755c0044ccc5c7e2a537969bdb39668",
      "0x734f7be31e24d8ba23324b11c35956d860506df9",
      "0x6a5140e5982a7c290147333bf001d2ab02624c77"
    ]
    return pools.filter((pool: POOL_DATA) => {
      const isJunkPool = pool._lendBalance === "0" && pool._colBalance === "0";
      const isPrivate = pool._borrowers.length > 0;
      return(
        (tokensOfInterest.includes(pool._colToken.toLowerCase()) || 
        tokensOfInterest.includes(pool._lendToken.toLowerCase()) ||
        whitelist.includes(pool.id.toLowerCase())) &&
        !isJunkPool && 
        !isPrivate
      );
    }).map((pool: POOL_DATA) => pool.id)
  }

  const newPoolsFilter = (pools: POOL_DATA[]) => {
    const now = Date.now();
    return pools
      .filter((pool: POOL_DATA) => {
        const isJunkPool = pool._lendBalance === "0" && pool._colBalance === "0";
        const isPrivate = pool._borrowers.length > 0;
        const isLive =
          fetchLocalTokenData(pool._colToken, chainId) !== undefined &&
          fetchLocalTokenData(pool._lendToken, chainId) !== undefined;
        // filter for pools created in the last 3 days
        const isNew = Number(pool._startTime) > (now - 1000 * 60 * 60 * 24 * 3)/1000 && Number(pool._startTime) < now/1000;
        return (
          isNew &&
          !isJunkPool && 
          !isPrivate &&
          isLive
        );
      })
      .map((pool: POOL_DATA) => pool.id);
  }

  // update featured pools list if chainId updates
  useEffect(() => {
    // @ts-ignore
    const chainCategories = featuredPoolsObject[chainId ? chainId : defaultChain];
    const defaultSelection = chainCategories.find((featured: FEATURED_POOLS) => featured.name === "Featured Pools");
    // don't reset filter once it's set (TODO: this may not work with multichain)
    if (!selectedFeaturedPools) {
      setSelectedFeaturedPools(defaultSelection);
    }
    // set the categories
    setFeaturedPools(chainCategories);
    // get number of pools for each category
    updatePoolCount();
  // eslint-disable-next-line
  }, [chainId, tokenPrices, props.pools]);

  const featuredDisplayClicked = (category: FEATURED_POOLS) => {
    if (category.name === "All Pools")
      setLiquidityRange([0, 1000000]);
    else if (category.name === "Most Funded") 
      setLiquidityRange([3450, 1000000]);
    else
      setLiquidityRange([0, 1000000]);
    setSelectedFeaturedPools(category);
  };

  // get number of pools for each category
  const updatePoolCount = () => {
    // make copy
    let featuredCopy:any = [...featuredPools];
    for (const [index, data] of featuredPools.entries()) {
      let count = 0;
      let featuredList = data.name === "Most Funded" 
        ? data.filterMethod(props.pools, tokenPrices)
        : data.filterMethod(props.pools)
      for (const pool of props.pools) {
        if (featuredList.includes(pool.id.toLowerCase()))
          count++;
      }
      if (count > featuredCopy[index].pools)
      featuredCopy[index].pools = count;
    }
    setFeaturedPools(featuredCopy);
  }

  return (
    <div className="featured-pools-wrapper">
      {featuredPools.map((pool: FEATURED_POOLS, index: number) => (
        <div
          className={`featured-pool-display ${
            pool.name === selectedFeaturedPools?.name ? "selected" : ""
          }`}
          onClick={() => featuredDisplayClicked(pool)}
          key={index}
        >
          {index === 0 && 
            <img 
              className="featured-gradient" 
              alt="cool gradient"
              src={require("../../img/gradients/gradient5.jpg")}
            />
          }
          <div className="featured-pool-image-wrapper">
            {pool.name !== "All Pools" && pool.img}
          </div>
          <div className="featured-pool-info">
            <span className="featured-pool-name">{pool.name}</span>
            {pool.name !== "All Pools" && <span className="featured-pool-count">{pool.pools} {pool.pools > 1 || pool.pools === 0 ? 'Pools' : 'Pool'}</span>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeaturedPools;