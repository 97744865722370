import { BigNumber, ethers } from "ethers"
import { Provider } from "ethers-multicall";

export enum METHOD_TYPE {
  APPROVE="APPROVE",
  DEPOSIT="DEPOSIT",
  COLLECT="COLLECT",
  BORROW="BORROW",
  REPAY="REPAY",
  DEPLOY="DEPLOY",
  WITHDRAW="WITHDRAW",
  ROLLOVER="ROLLOVER",
  PAUSE_BORROWING="PAUSE_BORROWING",
  SET_FEES="SET_FEES",
  SET_ROLLOVER="SET_ROLLOVER",
  UPGRADE_IMPLEMENTATION="UPGRADE_IMPLEMENTATION"
}

export interface NETWORK_DATA {
  chainId: number,
  chainName: string,
  isTestChain: boolean,
  isLocalChain: boolean,
  isLive: boolean,
  multicallAddress: string,
  multiVersionSupport?: boolean,
  graphUrl: string,
  rpcUrl: string,
  getExplorerAddressLink: (address: string) => string,
  getExplorerTransactionLink: (transactionHash: string) => string
}

export interface POOL_DATA {
  // graph data
  id: string,
  _deployer: string,
  _colToken: string,
  _lendToken: string,
  _expiry: string,
  _borrowers: string[], 
  _colBalance: string,
  _lendBalance: string,
  _paused: boolean,
  _mintRatio: string,
  _type: string,
  _protocolFee: BigNumber,
  _startTime: string,
  _totalBorrowed: string,
  // contract data
  isUnder: boolean,
  rawPoolInterestOwed: BigNumber,
  borrowingDisabled: boolean,
  externalDataLoaded: boolean,
  poolLendBalance: string, 
  poolColBalance: string,
  rawTotalFees: BigNumber,
  lendDecimals: number,
  colSymbol: string,
  lendSymbol: string,
  colPrice: number,
  lendPrice: number,
  colDecimals: number,
  currentFeeRate: number,
  ltv: number
}

export interface APP_DATA_CONTEXT{
  tokenPrices: any;
  setTokenPrices: (prices: any) => void,
  historicalPrices: any;
  setHistoricalPrices: (prices: any) => void;
  pending: boolean,
  setPending: (val: boolean) => void,
  calculateLTV: (pool: POOL_DATA) => number,
  liquidityRange: number[],
  setLiquidityRange: (range: number[]) => void,
  fullPoolData: any,
  setFullPoolData: (val: {id: POOL_DATA} | any) => void,
  getPoolData: (pools: POOL_DATA[], updateBorrowed?: boolean) => void,
  fullPoolDataLoaded: boolean
}

export interface WALLET_DATA_CONTEXT {
  multicallProvider: Provider,
  provider: ethers.providers.Web3Provider | ethers.providers.JsonRpcProvider | undefined,
  account: string | undefined,
  chainId: number | 42161 
  setProvider: (provider: ethers.providers.Web3Provider | ethers.providers.JsonRpcSigner | undefined) => void,
  setAccount: (account: string) => void,
  setChainId: (chainId: number) => void,
  setMulticallProvider: (provider: Provider) => void
}

export interface POOL_DATA_CONTEXT {
  selectedPool: POOL_DATA,
  setSelectedPool: (val: POOL_DATA | any) => void,
  selectedFeaturedPools: FEATURED_POOLS | undefined 
  setSelectedFeaturedPools: (pool: FEATURED_POOLS | undefined) => void
}

export interface LICENSE_DATA {
  colDiscount: number,
  currentPoolCount: BigNumber,
  lendDiscount: number,
  expiry: number,
  maxPoolCount: BigNumber,
  id: BigNumber 
}

export interface FEATURED_POOLS {
  deployerAddress: string,
  name: string, 
  img: string,
  pools: number,
  filterMethod: (pools: POOL_DATA[], extraData?: any) => string[]
}

export interface POOL_ROW {
  poolData?: any,
}

export interface POOL_CARD {
  poolData?: any,
}

export interface TOKEN_OUTPUT {
  value: string | number,
  symbol: string,
}

export interface COLLECT_DATA {
  poolAddress: string,
  colTokenAddress: string,
  lendTokenAddress: string,
  expiry: string,
  feeRate: string,
  updatePoolData: () => void
}

export interface LOCAL_TOKEN_DATA {
  address: any,
  displayDecimals: number,
  tokenDecimals: number,
  hasOracle: boolean,
  symbol: string,
  historicalPriceNetwork: any[]
}

export interface SELECTED_TOKEN {
  address: string,
  symbol: string,
  decimals: number,
  price: number
}