import { ethers } from "ethers";
import { Fragment, useContext, useEffect, useState } from "react";
import { ChevronRight, ChevronUp } from "react-feather";
import { useScreenSize } from "../../utils/useScreenSize";
import { fetchLocalTokenData, fetchTokenLogo, mobileBreakpoint, renderLazyLoad, shouldRenderPool } from "../../utils/Utils";
import { AppDataContext } from "../../context/AppDataContext";
import { WalletDataContext } from "../../context/WalletDataContext";
import { APP_DATA_CONTEXT, LOCAL_TOKEN_DATA, POOL_DATA_CONTEXT, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import PoolRowsContainer from "../PoolRowsContainer/PoolRowsContainer";
import "./AssetRow.css"
import { PoolDataContext } from "../../context/PoolDataContext";

const AssetRow = (props: {
  lendToken: string,
  colToken: string,
  pools: any[],
  open: boolean
  searchValue: string,
}) => {
  const [colPrice, setColPrice] = useState<number>(0);
  const [lendPrice, setLendPrice] = useState<number>(0);
  const [showPools, setShowPools] = useState<boolean>(props.open);
  const [sortBy, setSortBy] = useState<string>("ltv");
  const [sortDirection, setSortDirection] = useState<"ascending" | "descending">("descending");
  const { tokenPrices, liquidityRange, calculateLTV } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { selectedFeaturedPools } = useContext(PoolDataContext) as POOL_DATA_CONTEXT;
  const { chainId, account } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;

  let colData:LOCAL_TOKEN_DATA = fetchLocalTokenData(props.colToken, chainId) as any;
  let lendData:LOCAL_TOKEN_DATA = fetchLocalTokenData(props.lendToken, chainId) as any;
  const { screenWidth } = useScreenSize();

  useEffect(() => {
    // get and set the lend and collateral token prices
    const lendPrice = tokenPrices[ethers.utils.getAddress(lendData.address[`${chainId}`])];
    const colPrice = tokenPrices[ethers.utils.getAddress(colData.address[`${chainId}`])];
    setLendPrice(lendPrice);
    setColPrice(colPrice);
  // eslint-disable-next-line 
  }, [colData, lendData, props.open]); 

  const getPoolCount = () => {
    // get pool address in URL if it exists
    const pathname = window.location.pathname;
    const queryPoolAddress = pathname.substring(pathname.lastIndexOf("/") + 1);
    if (!selectedFeaturedPools) return 0;
    // const featuredList = selectedFeaturedPools.filterMethod(props.pools, tokenPrices);
    const filteredPools = props.pools.filter(
      (pool: any) => {
        const ltv:number = calculateLTV(pool);
        return shouldRenderPool(
          pool,
          chainId,
          tokenPrices,
          account,
          liquidityRange,
          queryPoolAddress,
          ltv,
          selectedFeaturedPools.name === "All Pools" ? true : false
        )
      });
    return filteredPools.length;
  }

  const renderRowDescription = () => {
    if (screenWidth > mobileBreakpoint) {
      return (
        <Fragment>
          Deposit {colData?.symbol}{" "}
          <span className="asset-price">
            ($
            {(colPrice ? colPrice : 0).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
            )
          </span>{" "}
          to Borrow {lendData?.symbol}{" "}
          <span className="asset-price">
            ($
            {(lendPrice ? lendPrice : 0).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
            )
          </span>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {colData?.symbol}{" "}
          <span className="asset-price">
            ($
            {(colPrice ? colPrice : 0).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
            )
          </span>{" "}
          to {lendData?.symbol}{" "}
          <span className="asset-price">
            ($
            {(lendPrice ? lendPrice : 0).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
            )
          </span>
        </Fragment>
      );
    }
  }

  return (
    <div className={`asset-row-wrapper fade-in ${showPools ? 'expanded' : ''}`}>
      <div
        className="asset-row-header"
        onClick={() => setShowPools(!showPools)}
      >
        <div className="asset-row-icons">
          <img
            className="token-logo repay-col"
            alt={`${!colData ? "FETCHING" : colData?.symbol}`}
            src={fetchTokenLogo(!colData ? "FETCHING" : colData.symbol)}
          ></img>
          <img
            className="token-logo repay-pair"
            alt={`${!lendData ? "FETCHING" : lendData?.symbol}`}
            src={fetchTokenLogo(!lendData ? "FETCHING" : lendData?.symbol)}
          ></img>
        </div>
        <div className="asset-row-description">
          {renderRowDescription()}
        </div>
        <div className="asset-row-pool-count">
          {renderLazyLoad(
            props.pools,
            <Fragment>
              {getPoolCount()} {getPoolCount() > 1 ? "Pools" : "Pool"}
            </Fragment>
          )}{" "}
        </div>
        <div className="asset-row-toggle">
          <span className="asset-row-toggle-text">{showPools ? "Hide Pools" : "Show Pools"}</span>
          {showPools ? <ChevronUp /> : <ChevronRight />}
        </div>
      </div>
      <PoolRowsContainer
        pools={props.pools}
        showPools={showPools}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        lendToken={props.lendToken}
        showExpiredPools={true}
        searchValue={props.searchValue}
        myPools={false}
        selectedFeaturedPools={selectedFeaturedPools}
      />
    </div>
  );
}

export default AssetRow;