import { Fragment, useContext, useEffect, useState } from "react";
import { AppDataContext } from "../../context/AppDataContext";
import { APP_DATA_CONTEXT, METHOD_TYPE, POOL_DATA, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import "./SetRollover.css"
import LendingPoolABI from "../../abi/LendingPool.js";
import PotentialRollover from "../PotentialRollover/PotentialRollover";
import { Contract, ethers } from "ethers";
import { ChevronDown } from "react-feather";
import { WalletDataContext } from "../../context/WalletDataContext";
import ActionButton from "../ActionButton/ActionButton";
import Dropdown from "../Dropdown/Dropdown";
import { useSnackbar } from "notistack";
import { getGasLimit, getTransactionUrl, handleMulticallAddress, readableABIs } from "../../utils/Utils";
import { Contract as MulticallContract } from "ethers-multicall";
import { Skeleton } from "@material-ui/lab";

const SetRollover = (props: {
  lentPools: POOL_DATA[],
  selectedPool: POOL_DATA,
  setSelectedRollover: (val: POOL_DATA | undefined) => void,
  selectedRollover: POOL_DATA | undefined
}) => {

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [whitelistedRollovers, setWhitelistedRollovers] = useState<POOL_DATA[]>([]);
  const [actionButtonText, setActionButtonText] = useState<string>("Enable Rollover Pool");
  const [potentialRollovers, setPotentialRollovers] = useState<POOL_DATA[]>([]);
  const { provider, chainId, multicallProvider } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { pending, setPending } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setPotentialRollovers(getPotentialRollovers());
    getWhitelistedRollovers();
  // eslint-disable-next-line
  }, [props.selectedPool]);

  useEffect(() => {
    manageActionButton();
  });

  // check if a pool is in a rollover whitelist
  const checkWhitelist = (searchPool: POOL_DATA) => {
    const search = whitelistedRollovers?.find((pool: POOL_DATA) => 
      searchPool.id === pool.id
    )
    return (search !== undefined);
  }

  const manageActionButton = () => {
    if (whitelistedRollovers.length > 0) {
      if (props.selectedRollover && checkWhitelist(props.selectedRollover)) {
        setActionButtonText("Disable Rollover Pool");
      } else if (props.selectedRollover && !checkWhitelist(props.selectedRollover)) {
        setActionButtonText("Enable Rollover Pool");
      }
    } else {
      setActionButtonText("Enable Rollover Pool");
    }
  }

  const getWhitelistedRollovers = async () => {
    if (!provider) return;
    const potentialRollovers = getPotentialRollovers();
    try {
      // setup the correct multicall address
      handleMulticallAddress(chainId, multicallProvider);
      // get all calls needed for multicall
      const calls = potentialRollovers.map((pool: POOL_DATA) => {
        const poolContract = new MulticallContract(
          ethers.utils.getAddress(props.selectedPool.id),
          readableABIs.lendingPool
        );
        return (poolContract.allowedRollovers(ethers.utils.getAddress(pool.id)));
      });
      // execute contract calls 
      const response = await multicallProvider.all(calls);
      let found = false;
      let whitelist:POOL_DATA[] = [];
      // check if any of the results return true and set it as the selected pool
      response.forEach((isSelected: boolean, index: number) => {
        if (isSelected) {
          props.setSelectedRollover(potentialRollovers[index]);
          whitelist.push(potentialRollovers[index]);
          found = true;
        }
      });
      setWhitelistedRollovers(whitelist);
      // if no potential rollovers found, set to first pool found
      if (!found && potentialRollovers.length > 0) 
        props.setSelectedRollover(potentialRollovers[0]);
    } catch (e) {
      console.log(e);
    }
  }

  const getPotentialRollovers = () => {
    const potentialRollovers = props.lentPools.filter((pool: POOL_DATA) => {
      // don't render inelligible pools
      if (
        // same lend and collateral token
        (pool._lendToken !== props.selectedPool._lendToken || pool._colToken !== props.selectedPool._colToken) ||
        // same pool id
        pool.id === props.selectedPool.id ||
        // expires before origin pool
        Number(pool._expiry) < Number(props.selectedPool._expiry)
      ) return false;
      else return true;
    });

    return potentialRollovers;
  }

  const renderPotentialRollover = () => {
    const potentialRollovers = getPotentialRollovers();
    return (
      <div className="potential-rollovers-wrapper">
        {potentialRollovers.map((pool: POOL_DATA) => {
          return (
            <div
              onClick={() => setShowDropdown(false)}
              key={pool.id}
            >
              <PotentialRollover 
                pool={pool}
                setSelectedRollover={props.setSelectedRollover}
              />
            </div>
          )
        })}
      </div>
    );
  }

  const setRollover = async () => {
    if (!provider || !props.selectedRollover) return;
    setPending(true);
    try {
      const poolContract = new Contract(
        props.selectedPool.id,
        LendingPoolABI,
        provider.getSigner()
      );
      // enable the new rollover pool or disable the currently selected pool
      const shouldEnable = checkWhitelist(props.selectedRollover) ? false : true;
      const tx = await poolContract.setRolloverPool(
        props.selectedRollover.id,
        shouldEnable,
        {
          ...getGasLimit(chainId, METHOD_TYPE.SET_ROLLOVER)
        }
      );
      enqueueSnackbar(`Executing rollover transaction ** ${getTransactionUrl(tx.transactionHash, chainId)} ** persist`);
      await tx.wait();
      enqueueSnackbar(`Set rollover transaction successful ** ${getTransactionUrl(tx.transactionHash, chainId)}`);
      getWhitelistedRollovers()
    } catch (e) {
      console.log(e);
      enqueueSnackbar(`Set rollover transaction failed`, {
        persist: false,
      });
    }
    setPending(false);
  }

  const renderPotentialRolloverPreview = () => {
    const potentialRollovers = getPotentialRollovers();
    if (potentialRollovers.length > 0 && !props.selectedRollover) {
      return (
        <div className="set-rollover-input-preview">
          <Skeleton className="loading-skeleton"/>
        </div>
      )
    } else if (potentialRollovers.length > 0 && props.selectedRollover) {
      return (
        <Fragment>
          <PotentialRollover 
            setSelectedRollover={props.setSelectedRollover} 
            pool={props.selectedRollover}
          /> 
          <ChevronDown/>
        </Fragment>
      )
    } else {
      return (
        <div
          className="potential-rollover no-potential-rollovers"
        >
          No Potential Rollovers Available
        </div>
      )
    }

  }

  return (
    <div className="set-rollover-wrapper my-pools-small-widgets-wrapper">
      <span className="widget-header">Set Rollover Pool</span>
      <span className="widget-description">
        Set which pools a borrower can rollover into from this pool
      </span>
      <div 
        className="set-rollover-input-wrapper"
        onClick={() => {if (!showDropdown && potentialRollovers.length > 0) setShowDropdown(true)}}
      >
        {renderPotentialRolloverPreview()}
        {showDropdown &&
          <Dropdown
            setShowDropdown={setShowDropdown}
            showDropdown={showDropdown}
          >
            {renderPotentialRollover()}
          </Dropdown>
        }
      </div>
      <ActionButton 
        disabled={pending || !props.selectedRollover} 
        title={actionButtonText} 
        action={() => setRollover()}
      />
    </div>
  );
}

export default SetRollover;