import React from "react";
import "./Tab.css";

const Tab = (props: {
  noPadding?: boolean,
  children: any,
  mode: string
}) => {
  return (
    <div className={`tab-container ${props.noPadding ? "no-padding" : ""}`}>
      {props.children}
      <div className={`tab-rails`}>
        <div
          className={`tab-slider ${props.mode}`}
        ></div>
      </div>
    </div>
  );
};

export default Tab;
