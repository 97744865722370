import { useContext, useEffect, useState } from "react";
import { ChevronDown } from "react-feather";
import { AppDataContext } from "../../context/AppDataContext";
import { APP_DATA_CONTEXT } from "../../utils/Interfaces";
import { useScreenSize } from "../../utils/useScreenSize";
import { mobileBreakpoint } from "../../utils/Utils";
import Dropdown from "../Dropdown/Dropdown";
import "./LiquidityRangeFilter.css";

const LiquidityRangeFilter = (props: {
  liquidityRange: number[],
  setLiquidityRange: (vals: number[]) => void
}) => {

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [minValue, setMinValue] = useState<number>(props.liquidityRange[0]);
  const [maxValue, setMaxValue] = useState<number>(props.liquidityRange[1]);
  const { liquidityRange } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { screenWidth } = useScreenSize();

  useEffect(() => {
    setMinValue(liquidityRange[0]);
    setMaxValue(liquidityRange[1]);
  }, [liquidityRange]);

  // close dropdown and update range filter values
  const applyClicked = () => {
    setShowDropdown(false);
    props.setLiquidityRange([Number(minValue), Number(maxValue)]);
  }

  const onKeyPress = (e: any) => {
    if (e.code === "Enter") applyClicked();
  }

  const renderDropdown = () => {
    const filterDropdown = (
      <div className="liquidity-range-filter-dropdown fade-in">
        <div className="dropdown-input-wrapper">
          <span>Min</span>
          <div>
            <span className="dropdown-input-symbol">$</span>
            <input 
              value={minValue}
              type={"number"}
              onChange={(e: any) => setMinValue(e.target.value)}
              onKeyPress={onKeyPress}
            />
          </div>
        </div>
        <div className="dropdown-input-wrapper">
          <span>Max</span>
          <div>
            <span className="dropdown-input-symbol">$</span>
            <input 
              value={maxValue}
              type={"number"}
              onChange={(e: any) => setMaxValue(e.target.value)}
              onKeyPress={onKeyPress}
            />
          </div>
        </div>
        <button onClick={applyClicked}>Apply</button>
      </div>
    );

    // show dropdown as a modal on mobile 
    if (screenWidth > mobileBreakpoint) {
      if (showDropdown)
        return (
          <Dropdown 
            setShowDropdown={setShowDropdown}
            showDropdown={showDropdown}
          >
            {filterDropdown}
          </Dropdown>
        )
      else return null;
    } else {
      if (showDropdown)
        return (
          <Dropdown 
            setShowDropdown={setShowDropdown}
            showDropdown={showDropdown}
          >
            {filterDropdown}
          </Dropdown>
        )
      else return null;
    }
  }

  return (
    <div 
      className={`liquidity-range-filter-wrapper pool-filters-filter-wrapper`}
      onClick={() => {if (!showDropdown) setShowDropdown(true)}}
    >
      Filter Liquidity 
      <ChevronDown/>
      {renderDropdown()}
    </div>
  );
}

export default LiquidityRangeFilter;