import { Alert } from "@material-ui/lab"
import { ethers } from "ethers"
import { Fragment, useContext, useEffect } from "react"
import { ChevronDown, ChevronUp } from "react-feather"
import { AppDataContext } from "../../context/AppDataContext"
import { WalletDataContext } from "../../context/WalletDataContext"
import { APP_DATA_CONTEXT, POOL_DATA, WALLET_DATA_CONTEXT } from "../../utils/Interfaces"
// import ReactTooltip from "react-tooltip"
import { fetchTokenSymbol, mobileBreakpoint, shouldRenderPool } from "../../utils/Utils"
import PoolRow from "../PoolRow/PoolRow";
import { useScreenSize } from "../../utils/useScreenSize"

const PoolRowsContainer = (props: {
  pools: any
  showPools: boolean,
  mode?: string,
  sortBy: string,
  setSortBy: (val: string) => void,
  sortDirection: string,
  setSortDirection: (direction: any) => void,
  lendToken?: string,
  searchValue: any,
  myPools: boolean,
  showExpiredPools: boolean,
  poolsLoaded?: boolean,
  selectedFeaturedPools?: any
}) => {

    const { screenWidth } = useScreenSize();
    // import contexts
    const { chainId, account } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
    const { tokenPrices, liquidityRange, fullPoolData, getPoolData, calculateLTV } = useContext(AppDataContext) as APP_DATA_CONTEXT;

    useEffect(() => {
      if (props.showPools && Object.entries(tokenPrices).length > 0) 
        getPoolData(props.pools);
    // eslint-disable-next-line
    }, [props.showPools, props.pools, liquidityRange, props.showExpiredPools, tokenPrices]);

    const manageChevron = (_sortBy: string) => {
      if (props.sortBy === _sortBy) {
        if (props.sortDirection === "ascending") return <ChevronUp/>
        if (props.sortDirection === "descending") return <ChevronDown/>
      }
    }

    const rowHeaderClicked = (e: any) => {
      // update the column to sort by
      const text = e.currentTarget.textContent;
      props.setSortBy(text.toLowerCase());
      // update sort direction
      if (props.sortDirection === "ascending") props.setSortDirection("descending");
      else if (props.sortDirection === "descending") props.setSortDirection("ascending");
    }

    const shouldRenderRow = (pool: POOL_DATA): boolean => {

      // check if pool is expired
      const isExpired = (Number(pool._expiry) * 1000 < new Date().getTime());

      // check if pool was passed in the URL
      const pathname = window.location.pathname;
      const queryPool = pathname.substring(pathname.lastIndexOf("/") + 1).toLowerCase();

      // get token symbols
      const colSymbol = fetchTokenSymbol(pool._colToken, chainId);
      const lendSymbol = fetchTokenSymbol(pool._lendToken, chainId);

      let show = false;
      // show requirements
      if (
        (colSymbol.includes(props.searchValue.toUpperCase()) || lendSymbol.includes(props.searchValue.toUpperCase()))
      ) { 
        show = true;
      } else show = false;

      // don't show expired pools if toggle is enabled
      if (props.myPools) {
        if (isExpired && props.showExpiredPools) show = true;
        else if (props.showExpiredPools && !isExpired) show = false;
        else if(isExpired && !props.showExpiredPools) show = false;
      } else if (props.selectedFeaturedPools.name === "All Pools") {
        const ltv = calculateLTV(pool);
        show = shouldRenderPool(
          pool,
          chainId,
          tokenPrices,
          account,
          liquidityRange,
          queryPool,
          ltv,
          true
        )
      } else if (!props.myPools && props.selectedFeaturedPools.name !== "All Pools") {
        // const featuredList = props.selectedFeaturedPools.filterMethod(props.pools, tokenPrices);
        const ltv = calculateLTV(pool);
        show = shouldRenderPool(
          pool,
          chainId,
          tokenPrices,
          account,
          liquidityRange,
          queryPool,
          ltv,
          true
        );
      }

      return show;
    };

    const sortPoolRows = (_pool1: POOL_DATA, _pool2: POOL_DATA):number => {
      // don't attempt to sort if external data hasn't been loaded
      let val1, val2;
      // use the fully loaded pool data
      const pool1 = {
        ..._pool1,
        ...fullPoolData[_pool1.id],
      }
      const pool2 = {
        ..._pool2,
        ...fullPoolData[_pool2.id]
      }
      if (!pool1.externalDataLoaded || !pool2.externalDataLoaded) return 0;
      if (props.sortBy === "ltv") {
        val1 = pool1.ltv;
        val2 = pool2.ltv;
      } else if (props.sortBy === "term rate") {
        val1 = Number(pool1.currentFeeRate || 0) / 10000;
        val2 = Number(pool2.currentFeeRate || 0) / 10000;
      } else if (props.sortBy === "apr") {
        val1 = Number(pool1.annualizedFeeRate || 0) / 10000
        val2 = Number(pool2.annualizedFeeRate || 0) / 10000
      } else if (props.sortBy.indexOf("due date") > -1) {
        val1 = Number(pool1._expiry);
        val2 = Number(pool2._expiry);
      } else if (props.sortBy.indexOf("available") > -1){
        val1 = Number(pool1.poolLendBalance);
        val2 = Number(pool2.poolLendBalance);
      } else if (props.sortBy.indexOf("borrowed") > -1) {
        if (props.myPools && props.mode === "borrowed") {
          val1 = Number(pool1.amountOwed);
          val2 = Number(pool2.amountOwed);
        } else if ((props.myPools && props.mode !== "borrowed") || !props.myPools) {
          val1 = Number(ethers.utils.formatUnits(pool1._totalBorrowed, pool1.lendDecimals));
          val2 = Number(ethers.utils.formatUnits(pool2._totalBorrowed, pool2.lendDecimals));
        }
      }

      // do sort
      if (props.sortDirection === "ascending")
        return (Number(val1) - Number(val2));
      else 
        return (Number(val2) - Number(val1));

    }

    const renderAvailableText = () => {
      return (
        <Fragment>
          <span data-tip data-for={`lend-symbol-tip`}>Available</span>
          {manageChevron(`available`)}
        </Fragment>
      );
    }

    const renderBorrowedText = () => {
      return (
        <Fragment>
          <span>Borrowed</span>
          {manageChevron(`borrowed`)}
        </Fragment>
      );
    }

    const renderPoolRows = () => {
      const filteredRows = props.pools.filter((pool: any) => shouldRenderRow(pool));

      if (filteredRows.length > 0) 
        return (
          <Fragment>
            {filteredRows
            .sort(sortPoolRows)
            .map((pool: any, index: number) => (
              <PoolRow
                key={pool.id}
                pool={{
                  ...pool,
                  ...fullPoolData[`${pool.id}`]
                }}
                mode={props.mode ? props.mode : undefined}
                myPools={props.myPools}
                selectedFeaturedPools={props.selectedFeaturedPools}
              />
            ))}
          </Fragment>
        );
      else if (filteredRows.length === 0 && props.myPools && props.searchValue === "")
        return(
          <Alert severity="info" className="no-pools-alert fade-in">
            No pools available. Try borrowing and lending to see your pools.
          </Alert>
        )
      else 
        return(
          <Alert severity="info" className="no-pools-alert fade-in">
            No pools found with the asset <b>"{props.searchValue}"</b>. Try searching for a different asset.
          </Alert>
        )
    }

    const renderBottomSection = () => {
      if (props.pools.length === 0 && !props.poolsLoaded)
        return(
          <Alert severity="info">Fetching Your Pools...</Alert>
        )
      else if (props.pools.length === 0 && props.poolsLoaded)
        return(
          <Alert severity="info">
            No pools available. Try borrowing and lending to see your pools.
          </Alert>
        );
      else
        return(
          renderPoolRows()
        );
    }

    return (
      <div
        className={`pool-rows-container ${
          props.showPools ? "showPools" : ""
        } fade-in`}
      >
        <div className="pool-rows-header-container">
          {props.myPools ? (
            <div
              className="pool-column-header pair-header"
              onClick={rowHeaderClicked}
            >
              <span>Pair</span>
              {manageChevron("pair")}
            </div>
          ) : (
            ""
          )}
          <div
            className={`pool-column-header ltv-header ${props.myPools ? "my-pools-row" : ""}`}
            onClick={rowHeaderClicked}
          >
            <span>LTV</span>
            {manageChevron("ltv")}
          </div>
          <div
            className={`pool-column-header term-rate-header ${
              props.myPools ? "my-pools-row" : ""
            }`}
            onClick={rowHeaderClicked}
          >
            <span>Term Rate</span>
            {manageChevron("term rate")}
          </div>
          {!props.myPools ? (
            <div
              className="pool-column-header apr-header"
              onClick={rowHeaderClicked}
            >
              <span>APR</span>
              {manageChevron("apr")}
            </div>
          ) : (
            ""
          )}
          <div
            className={`pool-column-header expiry-header ${
              props.myPools ? "my-pools-row" : ""
            }`}
            onClick={rowHeaderClicked}
          >
            <span>{screenWidth > mobileBreakpoint ? "Repayment Due Date" : "Due Date"}</span>
            {manageChevron("repayment due date")}
          </div>
          <div
            className={`pool-column-header available-header ${
              props.myPools ? "my-pools-row" : ""
            }`}
            onClick={rowHeaderClicked}
          >
            {renderAvailableText()}
          </div>
          <div
            className={`pool-column-header borrowed-header ${
              props.myPools ? "my-pools-row" : ""
            }`}
            onClick={rowHeaderClicked}
          >
            {renderBorrowedText()}
          </div>
          <div className={`pool-column-header option-header ${props.myPools ? "my-pools-row" : ""}`}></div>
        </div>
        {renderBottomSection()}
      </div>
    );
    
}

export default PoolRowsContainer;