import React from "react";
import { Plus, Minus /* ChevronDown*/ } from "react-feather";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import "./Selector.css";
import MobileDateTimePicker from "@mui/lab/MobileDateTimePicker";
import { useScreenSize } from "../../utils/useScreenSize";
import { mobileBreakpoint } from "../../utils/Utils";

const Selector = (props: {
  background?: string,
  type?: string,
  align?: string,
  setValue?: (val: any) => void,
  disabled: boolean,
  text: any,
  value?: any,
  buttons?: boolean
}) => {

  const { screenWidth } = useScreenSize();

  const getDateFormat = () => {
    if (screenWidth > mobileBreakpoint) return ("MMM d, Y hh:mm a")
    else return ("M/d/yy hh:mm a")
  }

  return (
    <div className={"selector-container " + props.background}>
      {props.type !== "date" ? (
        <input
          className={`selector-text disable-select ${
            props.align ? "rate-selector" : ""
          }`}
          disabled={props.disabled}
          type={props.type ? props.type : ""}
          value={props.text}
          onChange={(e) => props.setValue && props.setValue(e.target.value)}
        />
      ) : (
        <LocalizationProvider dateAdapter={AdapterDateFns} className="fade-in">
          <div className="date-time-container fade-in">
            <MobileDateTimePicker
              renderInput={(props) => <TextField {...props} />}
              className="fade-in"
              label="Expiry Date &amp; Time"
              disablePast={true}
              value={props.value}
              disabled={props.disabled}
              shouldDisableDate={(value) =>  (new Date().getTime() / 1000 + 86400 > new Date(value).getTime() / 1000)}
              showToolbar={false}
              inputFormat={getDateFormat()}
              onChange={(newValue) => {
                props.setValue && props.setValue(new Date(newValue));
              }}
            />
          </div>
        </LocalizationProvider>
      )}
      {props.buttons ? (
        <div className="selector-button-container">
          <Minus
            onClick={() =>
              props.setValue && props.setValue(Math.max(0, parseFloat(props.text) - 0.5))
            }
          />
          <span className="selector-number-symbol disable-select">%</span>
          <Plus onClick={() => props.setValue && props.setValue(parseFloat(props.text) + 0.5)} />
        </div>
      ) : (
        ""
      )}
      {/*<FontAwesomeIcon icon={faAngleDown} className="selector-arrow"/>*/}
    </div>
  );
};

export default Selector;
