const Spacer = (props: {spacing: number | string}) => {
  return (
    <div
      style={{
        margin: props.spacing || 0,
      }}
    ></div>
  );
};

export default Spacer;
