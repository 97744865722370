import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { WalletDataContext } from "../../context/WalletDataContext";
import { APP_DATA_CONTEXT, POOL_DATA, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { getNetworkData, handleMulticallAddress, readableABIs } from "../../utils/Utils";
import { Contract as MulticallContract } from "ethers-multicall";
import "./PoolStatusContainer.css";
import { ethers } from "ethers";
import { AppDataContext } from "../../context/AppDataContext";

const PoolStatusContainer = (props: {
  pool: any
}) => {

  const [rolloverPools, setRolloverPools] = useState<any[]>([]);
  const [active, setActive] = useState<boolean[]>([false, false, false, false]);
  const { chainId, multicallProvider } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { pending } = useContext(AppDataContext) as APP_DATA_CONTEXT;

  // pools paused by first responder address
  const factoryPausedPools = [
    "0x39b9f0c8bd0b1add3756b0726c60e71a36225424",
    "0x4e5d12708d8157dc06c9c6c45ef9722a3b4773bd",
    "0xa273242093e0de7015d3e210bc871840ae735bf9",
    "0xe7e44cc639f2dff5e827492d48602ff3d632aa4f",
    "0xf8139974ad1d34bb2168fb5dff3e9cb9f00fecf6",
    "0x0767e3e55b1b056e9c1d128a3b73b04bc1246133",
    "0x07aAf6A1aa00bf75330D750f3F8E8E5eA8BD292f"
  ]

  useEffect(() => {
    getRolloverStatus();
  // eslint-disable-next-line
  }, [pending]);

  // get rollover pools and check if pool should have rollover status
  const getRolloverStatus = async () => {

    // use correct graph node
    const url:any = getNetworkData(chainId)?.graphUrl;

    // get col and lend tokens
    const lendToken = props.pool._lendToken;
    const colToken = props.pool._colToken;
    const deployer = props.pool._deployer;

    // get rollover pools
    const rolloverPoolsGraph = await axios.post(
      url,
      {
        query: `
      {
        pools(where: {
          _lendToken: "${lendToken}",
          _colToken: "${colToken}",
          _deployer: "${deployer}"
        }) {
          id
          _lendToken
          _deployer
          _colToken
          _expiry
          _mintRatio
          _feeRate
          _startTime
          _paused
        }
      }
      `,
      }
    );

    let filteredRolloverPools = [];
    if (rolloverPoolsGraph.data.data.pools.length !== 0) {
      // only work with the correct parameters
      filteredRolloverPools = rolloverPoolsGraph.data.data.pools.filter(
        (p: any) =>
          p._deployer === props.pool._deployer &&
          p.id !== props.pool.id &&
          p._colToken === colToken &&
          p._lendToken === lendToken &&
          p._expiry > props.pool._expiry &&
          !p._paused 
      )
    }

    setRolloverPools(await getWhitelistedRollovers(filteredRolloverPools));
  }

  const getWhitelistedRollovers = async (potentialRollovers: POOL_DATA[]) => {
    try {
      // setup the correct multicall address
      handleMulticallAddress(chainId, multicallProvider);
      // get all calls needed for multicall
      const calls = potentialRollovers.map((pool: POOL_DATA) => {
        const poolContract = new MulticallContract(
          ethers.utils.getAddress(props.pool.id),
          readableABIs.lendingPool
        );
        return (poolContract.allowedRollovers(ethers.utils.getAddress(pool.id)));
      });
      // execute contract calls 
      const response = await multicallProvider.all(calls);
      let whitelist:POOL_DATA[] = [];
      // check if any of the results return true and set it as the selected pool
      response.forEach((isSelected: boolean, index: number) => {
        if (isSelected) {
          whitelist.push(potentialRollovers[index]);
        }
      });
      return (whitelist);
    } catch (e) {
      return potentialRollovers;
    }
  }

  const updateActiveStatus = (index: number, value: boolean) => {
    let copy = [...new Array(active.length)];
    copy[index] = value;
    setActive(copy);
  }

  const renderPrivateStatus = () => {
    const isPrivate = props.pool._borrowers.length > 0;
    if (isPrivate)
      return(
        <div 
          className={`pool-status-wrapper private-status ${active[0] ? "active" : ""}`}
          onMouseOver={() => updateActiveStatus(0, true)}
          onMouseLeave={() => updateActiveStatus(0, false)}
        >
          {active[0] ? "Private" : "P"}
        </div>
      );
    else return null;
  }

  const renderRolloverStatus = () => {
    const hasRollovers = rolloverPools.length > 0;
    if (hasRollovers)
      return (
        <div 
          className={`pool-status-wrapper rollover-status ${active[1] ? "active" : ""}`}
          onMouseOver={() => updateActiveStatus(1, true)}
          onMouseLeave={() => updateActiveStatus(1, false)}
        >
          {active[1] ? "Rollover" : "R"}
        </div>
      )
    else return null;
  }

  const renderPausedStatus = () => {
    const isPaused = props.pool._paused;
    if (isPaused)
      return(
        <div 
          className={`pool-status-wrapper paused-status ${active[2] ? "active" : ""}`}
          onMouseOver={() => updateActiveStatus(2, true)}
          onMouseLeave={() => updateActiveStatus(2, false)}
        >
          {active[2] ? "Paused" : "P"}
        </div>
      );
    else return null;
  }

  const renderFactoryPausedStatus = () => {
    const isPaused = factoryPausedPools.includes(props.pool.id.toLowerCase());
    if (isPaused)
      return(
        <div 
          className={`pool-status-wrapper factory-paused-status ${active[3] ? "active" : ""}`}
          onMouseOver={() => updateActiveStatus(3, true)}
          onMouseLeave={() => updateActiveStatus(3, false)}
        >
          {active[3] ? "Factory Paused" : "F"}
        </div>
      );
    else return null;
  }

  return (
    <div className="pool-status-container">
      {renderPrivateStatus()}
      {renderRolloverStatus()}
      {renderPausedStatus()}
      {renderFactoryPausedStatus()}
    </div>
  );
}

export default PoolStatusContainer;
