import { useState } from "react";
import "./App.css";

import Dashboard from "./components/Dashboard/Dashboard";
import Disclaimer from "./components/Disclaimer/Dislcaimer";
import Verification from "./components/Verification/Verification";

function App() {

  const [countryAllowed, setCountryAllowed] = useState<boolean>();

  const renderLogic = () => {
    if (countryAllowed === undefined || countryAllowed === false) {
      return (
        <Verification
          countryAllowed={countryAllowed}
          setCountryAllowed={setCountryAllowed}
        />
      )
    } else {
      return (
        <>
          <Dashboard/>
          <Disclaimer/>
        </>
      ) 
    }
    
  }

  return (
    <div className="App">
      {renderLogic()}
    </div>
  );
}

export default App;
