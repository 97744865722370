import { ExternalLink, X } from "react-feather";
import "./Notification.css";
import { useSnackbar } from "notistack";
import { useContext, useEffect } from "react";
import { WalletDataContext } from "../../context/WalletDataContext";
import { WALLET_DATA_CONTEXT } from "../../utils/Interfaces";

const Notification = (props: {
  message: string,
  key: any,
  id: any
}) => {
  const { closeSnackbar } = useSnackbar();
  const { provider } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;

  useEffect(() => {
    // close notification after 10 seconds
    const components = props.message.split("**");
    if (components.length <= 2) {
      // automatically close notification in 7 seconds if it doesn't contain a link
      const timer = setTimeout(() => {
        closeSnackbar(props.id);
      }, 7000);
      return () => clearTimeout(timer);
    } else {
      const link = components[1];
      const hash = link.split("/")[link.split("/").length - 1];
      closeAfterConfirmation(hash);
    }
  // eslint-disable-next-line
  }, []);

  // close notification after transaction is confirmed
  const closeAfterConfirmation = async (hash: string) => {
    const tx = await provider?.getTransaction(hash.trim());
    if (tx) {
      await tx.wait();
      closeSnackbar(props.id);
    }
  }

  const renderMessage = () => {
    let message = props.message;
    // extract link from message if it exists
    if (props.message.includes("**")) {
      message = message.substring(0, message.indexOf("**"));
    }
    return message;
  }

  const renderLink = () => {
    // extract link from message if it exists
    if (props.message.includes("**")) {
      const components = props.message.split("**");
      const link = components.length > 1 ? components[1] : "";
      return (
        <ExternalLink
          onClick={() => window.open(link, '_blank')}
          className={`notification-link`}
        />
      )
    } else {
      return null;
    }
  }

  const renderSlider = () => {
    const persist = props.message.includes("persist");
    if (!persist)
      return (
        <div className="notification-slider"/>
      )
    else return null;
  }

  return (
    <div className="notification-wrapper">
      <section className="notification-top">
        <span>{renderMessage()}</span>
        <X
          className="notification-close"
          onClick={() => closeSnackbar(props.id)}
        />
        {renderLink()}
      </section>
      {renderSlider()}
    </div>
  );
};

export default Notification;
