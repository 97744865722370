import React from "react";
import "./RepayActionRow.css";
import TokenSelector from "../TokenSelector/TokenSelector";
import { BigNumber } from "ethers";
import { ArrowDown } from "react-feather";
// import { truncateNumber } from "../../utils/Utils";
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const RepayActionRow = (props: {
  getInputPrice: (val: number) => any,
  lendSymbol: string,
  colSymbol: string,
  sync: (amount: string, field: string) => any,
  repayValue: number | string,
  withdrawValue: number | string,
  colBalance: BigNumber,
  lendBalance: BigNumber,
  loading: boolean,
  clickValue?: BigNumber,
  colFloatPoints?: number,
  lendFloatPoints?: number,
  text?: string,
}) => {
  return (
    <div className="repay-action-container">
      <div className="row-title">
        <div className="repay-title">
          Repay
        </div>
        {props.getInputPrice(1) > 0 ?
          <span className="input-price">~${props.getInputPrice(1).toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
        :""}
      </div>
      <div className="token-selector-short-wrapper">
        <TokenSelector
          symbol={props.lendSymbol}
          sync={props.sync}
          value={props.repayValue}
          text={props.text ? props.text : "Balance"}
          id="0"
          balance={props.lendBalance}
          loading={props.loading}
          clickValue={props.clickValue ? props.clickValue : props.lendBalance}
        />
      </div>
      <div className="mid-separator">
        <ArrowDown/>
      </div>
      <div className="row-title">
        <div className="repay-title">To Withdraw</div>
        {props.getInputPrice(0) > 0 ?
          <span className="input-price">~${props.getInputPrice(0).toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
        :""}
      </div>
      <div className="token-selector-short-wrapper">
        <TokenSelector
          symbol={props.colSymbol}
          sync={props.sync}
          value={props.withdrawValue}
          // text={"Max"}
          // balance={props.colBalance}
          id="1"
        />
      </div>
    </div>
  );
};

export default RepayActionRow;
