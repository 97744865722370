import "./TokenModal.css";
import Modal from "../Modal/Modal";
import { useState, useEffect, useContext } from "react";
import { fetchTokenLogo, renderLazyLoad, tokenData } from "../../utils/Utils";
import { APP_DATA_CONTEXT, LOCAL_TOKEN_DATA, SELECTED_TOKEN, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { AppDataContext } from "../../context/AppDataContext";
import { WalletDataContext } from "../../context/WalletDataContext";
import { ethers } from "ethers";

const TokenModal = (props: {
  selectedInput: string,
  showModal: boolean,
  setShowModal: (val: boolean) => void,
  setSelectedCollateral: (val: any) => void,
  setSelectedLend: (val: any) => void,
  selectedCollateral: SELECTED_TOKEN | any,
  selectedLend: SELECTED_TOKEN | any,
}) => {

  const [filter, setFilter] = useState("");
  const [supportedTokens, setSupportedTokens] = useState({})
  const [loadedToken, setLoadedToken] = useState<any>();
  // list of accounts that can deploy xGRAIL pools
  const xGrailWhitelist = ["0x9f1A540A4b3C0A1567016171580C119347E55CCB", "0x01Bb7B44cc398AaA2b76Ac6253F0F5634279Db9D"]
    .map((val) => val.toLowerCase());
  const { tokenPrices } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { provider, chainId, account } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;


  useEffect(() => {
    if (provider && chainId) { 
      setSupportedTokens({});
      getWhitelistedTokens();
    }
  // eslint-disable-next-line
  }, [provider, chainId]);

  useEffect(() => {
    // dynamically load tokens 
    if (loadedToken !== undefined) {
      let data = {
        ...supportedTokens,
        [`${loadedToken.symbol}`]: loadedToken
      }
      setSupportedTokens(data);
    }
  // eslint-disable-next-line
  }, [loadedToken]);

  const getWhitelistedTokens = async () => {
    // manually add "ALL" option on borrow page
    if (window.location.pathname.indexOf("borrow") > -1) {
      const allTokens:LOCAL_TOKEN_DATA | any = {
        displayDecimals: 0,
        tokenDecimals: 0,
        symbol: "ALL",
        historicalPriceNetwork: [],
        hasOracle: false,
        address: {
          5: "",
          42161: ""
        }
      }
      setLoadedToken(allTokens);
    }
    for (const token in tokenData) {
      //@ts-ignore
      if (!tokenData[token].supportedNetworks.includes(chainId)) {
      //@ts-ignore
        continue;
      };
      // I have no idea why but tokens don't load correctly without the
      // await statement 
      //@ts-ignore
      let supported = await tokenData[token].address[chainId] !== undefined;
      //@ts-ignore
      if (supported) setLoadedToken(tokenData[token]);
    }
  }

  const tokenClicked = (data: {
    symbol: string;
    price: number;
    address: string;
    hasOracle: boolean;
    displayDecimals: number;
  }) => {
    // update selected token data
    if (props.selectedInput === "0") props.setSelectedCollateral(data);
    else props.setSelectedLend(data);
    // close modal
    props.setShowModal(false);
  };

  // iterate through tokens
  const getTokens = () => {
    return (
      Object.entries(supportedTokens).map((data: any[], index) => {
        // edgecase logic for "ALL" tokens option
        if (data[0] === "ALL") {
          const allOptionData = data[1];
          const { symbol, price, address, hasOracle, displayDecimals } = allOptionData;
          return (
            <div
              className={`token-container fade-in`}
              onClick={() => tokenClicked({symbol, price, address, hasOracle, displayDecimals})}
              key={index}
            >
              <img src={fetchTokenLogo(symbol)} alt={symbol} />
              <div className="token-info">
                <span className="token-name">{symbol}</span>
              </div>
            </div>
          );
        } else {
          // fetch token data to display on token modal
          const symbol = data[0].toUpperCase();
          const tokenData = data[1];
          // ignore non-supported tokens on a given chain
          if (!tokenData.supportedNetworks.includes(chainId)) return <></>;
          //@ts-ignore
          const address = ethers.utils.getAddress(tokenData.address[chainId]);
          //@ts-ignore
          const hasOracle = tokenData.hasOracle;
          // apply filter
          if (symbol.indexOf(filter.toUpperCase()) === -1) return null;
          if (symbol.toUpperCase() === "XGRAIL") {
            if (!account) return null;
            else if (!xGrailWhitelist.includes(account.toLowerCase())) return null;
          }
          const displayDecimals = tokenData.displayDecimals;
          let price = -1;
          let formattedPrice;
          if (tokenPrices) {
            try {
              price = tokenPrices[address]
                ? tokenPrices[address]
                : 0;
              formattedPrice = Number(price).toLocaleString(undefined, {
                minimumFractionDigits: displayDecimals
              });
            } catch (e) {
              console.log(e);
            }
          }
          return (
            <div
              className={`token-container fade-in`}
              onClick={() => tokenClicked({symbol, price, address, hasOracle, displayDecimals})}
              key={index}
            >
              <img src={fetchTokenLogo(symbol)} alt={symbol} />
              <div className="token-info">
                <span className="token-name">{symbol}</span>
                {Object.entries(tokenPrices).length > 0 && renderLazyLoad(formattedPrice,
                  <span className="token-price">
                    ${formattedPrice}
                  </span>
                )}
              </div>
            </div>
          );
        }
      })
    );
  };

  return (
    <Modal showModal={props.showModal} setShowModal={props.setShowModal}>
      <div className="token-modal-container fade-in">
        <span className="token-modal-header">Select a Token</span>
        <input
          placeholder="Filter by token name..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
        <section className="tokens-container">{getTokens()}</section>
      </div>
    </Modal>
  );
};

export default TokenModal;
