import { Alert } from "@material-ui/lab";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import "./PoolStats.css";

const PoolStats = (props: {
  showModal: boolean,
  setShowModal: (show: boolean) => void,
  selectedPool: any,
  mode: string,
  hover?: boolean
}) => {

  const [totalBorrowed, setTotalBorrowed] = useState<number>(0);

  useEffect(() => {
    if (props.selectedPool) {
      setTotalBorrowed(
        parseFloat(ethers.utils.formatUnits(props.selectedPool._totalBorrowed, props.selectedPool.lendDecimals))
      );
    }
  }, [props.selectedPool]);

  // format values in a nice way with commas for large numbers
  const formatValue = (_value: string | number) => {
    let value = typeof _value === "string" ? parseFloat(_value) : _value;
    if (!value) value = 0;
    return value.toLocaleString(undefined, {minimumFractionDigits: 4});
  }

  const renderBorrowStats = () => {
    if (!props.selectedPool) return;
    return (
      <div>
        <span className="stats-header">Advanced Borrower Stats</span>
        <div className="stats-container">
          <div className="stats-container-stat">
            <span className="stat-label">Total Collateral Deposited:</span>
            <span className="stat-value">{formatValue(props.selectedPool.userColDeposited)} {props.selectedPool.colSymbol}</span>
          </div>
          <div className="stats-container-stat">
            <span className="stat-label">Total Funds Borrowed:</span>
            <span className="stat-value">{formatValue(props.selectedPool.borrowAmount)} {props.selectedPool.lendSymbol}</span>
          </div>
          <div className="stats-container-stat">
            <span className="stat-label">Fees Due:</span>
            <span className="stat-value">{formatValue(props.selectedPool.interestOwed)} {props.selectedPool.lendSymbol}</span>
          </div>
          <div className="stats-container-stat">
            <span className="stat-label">Value of Collateral:</span>
            <span className="stat-value">${formatValue(parseFloat(props.selectedPool.colPrice) * parseFloat(props.selectedPool.userColDeposited))}</span>
          </div>
          <div className="stats-container-stat">
            <span className="stat-label">Value of Loan:</span>
            <span className="stat-value">${formatValue(parseFloat(props.selectedPool.lendPrice) * parseFloat(props.selectedPool.borrowAmount))}</span>
          </div>
        </div>
      </div>
    );
  }

  const renderLenderStats = () => {
    if (!props.selectedPool) return;
    return (
      <div>
        <span className="stats-header">Advanced Lender Stats</span>
        <div className="stats-container">
          <div className="stats-container-stat">
            <span className="stat-label">Total Funds Deposited:</span>
            <span className="stat-value">{formatValue(parseFloat(props.selectedPool.poolLendBalance) + totalBorrowed)} {props.selectedPool.lendSymbol}</span>
          </div>
          <div className="stats-container-stat">
            <span className="stat-label">Total Funds Lent Out:</span>
            <span className="stat-value">{formatValue(totalBorrowed)} {props.selectedPool.lendSymbol}</span>
          </div>
          <div className="stats-container-stat">
            <span className="stat-label">Collateral Deposited:</span>
            <span className="stat-value">{formatValue(props.selectedPool.poolColBalance)} {props.selectedPool.colSymbol}</span>
          </div>
          <div className="stats-container-stat">
            <span className="stat-label">Value of Collateral:</span>
            <span className="stat-value">${formatValue(parseFloat(props.selectedPool.colPrice) * parseFloat(props.selectedPool.poolColBalance))}</span>
          </div>
        </div>
      </div>
    );
  }

  const renderPoolStats = () => {
    return (
      <div className="pool-stats-wrapper">
        {props.selectedPool ? (
          props.mode === "borrowed" ? (
            renderBorrowStats()
          ) : (
            renderLenderStats()
          )
        ) : (
          <Alert severity="info">
            Please select a pool to view the advanced stats
          </Alert>
        )}
      </div>
    )
  }

  if (!props.hover)
    return (
      <Modal showModal={props.showModal} setShowModal={props.setShowModal}>
        {renderPoolStats()}
      </Modal>
    );
  else 
    return(renderPoolStats())
}

export default PoolStats;