import { ethers } from "ethers";
import { useContext, useEffect, useState } from "react";
import { RefreshCw } from "react-feather";
import { getGasLimit, getTransactionUrl, supportedNetworks } from "../../utils/Utils";
import ActionButton from "../../components/ActionButton/ActionButton";
import IncrementSelector from "../../components/IncrementSelector/IncrementSelector";
import FeeManagerABI from "./../../abi/FeeManager.js";
import LendingPoolABI from "../../abi/LendingPool.js";
import { useSnackbar } from "notistack";
import { useConnectWallet } from "@web3-onboard/react";
import { WalletDataContext } from "../../context/WalletDataContext";
import { METHOD_TYPE, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";

const UpdateFeeRate = (props: {
  selectedPool: any,
  pending: boolean
  setPending: (val: boolean) => void
  manageActionButton: () => boolean
  updatePoolData: () => void,
}) => {

  const [selectedFeeRate, setSelectedFeeRate] = useState<number>(5);
  const { enqueueSnackbar } = useSnackbar();
  const [{ wallet }] = useConnectWallet();
  const { provider, chainId } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;

  const getFeeRate = async() => {
    if (!wallet || !props.selectedPool) return;
    // const poolContract = new ethers.Contract(
    //   props.selectedPool.id,
    //   LendingPoolABI,
    //   library.getSigner()
    // );
    // const disabledBorrowResp = await poolContract.disabledBorrow();
    if (props.selectedPool.feeType === "1")
      setSelectedFeeRate(Number(props.selectedPool.currentFeeRate || 0) / 10000)
    else
      setSelectedFeeRate(Number(props.selectedPool.annualizedFeeRate || 0) / 10000)
  }

  useEffect(() => {
    getFeeRate();
  // eslint-disable-next-line
  }, [props.selectedPool]);

  const updateFeeRate = async() => {
    if (!supportedNetworks.includes(chainId) || !props.selectedPool || !provider) return;
    try {
      props.setPending(true);
      enqueueSnackbar("Please confirm the update rate transaction in your wallet", {
        persist: false,
        disableWindowBlurListener: true
      });
      const poolAddress = props.selectedPool.id;
      const poolContract = new ethers.Contract(
        poolAddress,
        LendingPoolABI,
        provider.getSigner() 
      );
      const feeRate = Number(selectedFeeRate) * 10000;
      const feeManagerAddress = await poolContract.feeManager();
      const feeManagerContract = new ethers.Contract(
        feeManagerAddress,
        FeeManagerABI,
        provider.getSigner()
      );
      const feeTypeResp = await feeManagerContract.rateFunction(poolAddress);
      const feeType = feeTypeResp.toString();
      const tx = await feeManagerContract.setPoolFees(
        poolAddress,
        feeRate,
        feeType,
        {
          ...getGasLimit(chainId, METHOD_TYPE.SET_FEES)
        }
      );
      enqueueSnackbar(`Executing update rate transaction ** ${getTransactionUrl(tx.hash, chainId)} ** persist`);
      await tx.wait();
      enqueueSnackbar("Update rate transaction successful **" + getTransactionUrl(tx.hash, chainId), {
        persist: false,
        disableWindowBlurListener: true
      });
    } catch (e) {
      enqueueSnackbar("Update rate transaction failed", {
        persist: false,
        disableWindowBlurListener: true
      });
      props.setPending(false);
      console.log(e);
      return;
    }
    props.setPending(false);
    props.updatePoolData();
  }

  return (
    <div className="update-fee-rate-wrapper small-widget">
      <RefreshCw className="widget-icon" />
      <span className="widget-header">Update Fee Rate</span>
      <span className="widget-description">
        You can update the pool's fee rate. Users who borrowed before the update
        will maintain their borrow rate
      </span>
      <IncrementSelector
        value={selectedFeeRate}
        setValue={setSelectedFeeRate}
      />
      <ActionButton
        disabled={props.manageActionButton()} 
        title={"Update"}
        action={updateFeeRate}
      />
    </div>
  );
}

export default UpdateFeeRate;