import { Chain } from "@usedapp/core";
import { Fragment, useContext, useEffect } from "react";
import { WalletDataContext } from "../../context/WalletDataContext";
import { NETWORK_DATA, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { getNetworkData, networks } from "../../utils/Utils";
import "./NetworkSelect.css";

const NetworkSelect = (props: {
  changeNetwork: (networkData: NETWORK_DATA) => Promise<void>
  setShowNetworkSelect: (show: boolean) => void
}) => {

  const { chainId } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;

  useEffect(() => {
    window.addEventListener('mousedown', onClick);
    return () => {
      window.removeEventListener('mousedown', onClick);
    };
  // eslint-disable-next-line
  }, []);

  // hide dropdown when user clicks anywhere else
  const onClick = () => {
    setTimeout(() => {
      props.setShowNetworkSelect(false);
    }, 300);
  }

  const networkClicked = async (network: NETWORK_DATA | Chain) => {
    try {
      await props.changeNetwork(network as NETWORK_DATA);
    } catch (e) {
      console.log(e);
    }
    props.setShowNetworkSelect(false);
  }

  const renderNetwork = (network: NETWORK_DATA) => {
    const currentNetworkData = getNetworkData(chainId);
    return (
      <Fragment>
        <button 
          className="network-select-option"
          onClick={() => networkClicked(network)}
        >
          <img
            alt={`${network.chainName}`}
            src={`/assets/networks/${network.chainName.toUpperCase()}-logo.png`}
          />
          {network.chainName} {network.multiVersionSupport && "V1"}
        </button>
        {/* Render V1 button if network supports it */}
        {network.multiVersionSupport && (
          <button 
            className="network-select-option"
            onClick={() => window.open(`https://vendor.finance/?chain=${currentNetworkData?.chainName}`, "_self")}
          >
            <img
              alt={`${network.chainName}`}
              src={`/assets/networks/${network.chainName.toUpperCase()}-logo.png`}
            />
            {network.chainName} V2
          </button>
        )}
      </Fragment>
    );
  }

  return (
    <div className="network-select-wrapper fade-in">
      {Object.values(networks).map((network: NETWORK_DATA) => {
        // is the site the dev site or local testing
        const isDevSite = window.location.host.includes("dev") || window.location.host.includes("localhost");
        // don't show test networks on production site
        if (!network.isLive && !isDevSite) return null;
        return (renderNetwork(network));
      })}
    </div>
  )
}

export default NetworkSelect;