import { Minus, Plus } from "react-feather";
import "./IncrementSelector.css";

const IncrementSelector = (props: {
  value: number,
  setValue: (num: number) => void
}) => {

  const inputChanged = (e: any) =>{
    props.setValue(parseFloat(e.target.value));
  }

  return(
    <div className="increment-selector-wrapper">
      <div 
        className="increment-selector-button" 
        onClick={() => props.value > 0 && props.setValue(props.value - 0.5)}
      >
        <Minus/>
      </div>
      <input type="number" value={props.value} onChange={inputChanged}/>
      <span className="increment-selector-percent">%</span>
      <div 
        className="increment-selector-button"
        onClick={() => props.setValue(props.value + 0.5)}
      >
        <Plus/>
      </div>
    </div>
  )
}

export default IncrementSelector;