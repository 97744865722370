import { HelpCircle } from "react-feather";
import Modal from "../Modal/Modal";
import "./TransactionReview.css";
import ReactTooltip from "react-tooltip";
import { tabletBreakpoint } from "../../utils/Utils";
import { useScreenSize } from "../../utils/useScreenSize";

const TransactionReview = (props: {
  modal?: boolean,
  hideHeader?: boolean,
  transactionInfo: any,
  confirmButton?: any,
  actionButton: any,
  showReview: boolean,
  setShowReview: (val: boolean) => void
}) => {

  const { screenWidth } = useScreenSize();

  const renderReview = () => {
    // setup base components
    let transactionComponents = (
      <div
        className={`transaction-review ${
          props.modal ? "floating" : ""
        }`}
      >
        <div className="transaction-info">
          <div className={`review-header ${props.hideHeader ? "hide" : ""}`}>
            <ReactTooltip
              id="interest-first-tip"
              type="info"
              className="tool-tip"
              effect={screenWidth > tabletBreakpoint ? "float" : "solid"}
              place="top"
              arrowColor="var(--iris)"
            >
              <span>
                Before receiving any collateral back borrowers must first repay their total interest due 
              </span>
            </ReactTooltip>
            <span>Transaction Review</span>
            <span
              className="interest-notice"
            >
              INTEREST IS ALWAYS PAID FIRST <HelpCircle data-tip data-for="interest-first-tip"/>{" "}
            </span>
          </div>
          {props.transactionInfo}
        </div>
        {props.confirmButton && props.confirmButton}
        {props.actionButton}
      </div>
    );

    // render in modal if requested
    if (props.modal)
      transactionComponents = (
        <Modal setShowModal={props.setShowReview} showModal={props.showReview}>
          {transactionComponents}
        </Modal>
      );

    return transactionComponents;
  };

  return renderReview();
};

export default TransactionReview;
