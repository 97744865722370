import { Fragment, useEffect, useState }  from "react";
import { useCookies } from "react-cookie";
import { XCircle } from "react-feather";
import Modal from "../Modal/Modal";
import "./Disclaimer.css";

const Disclaimer = () => {

    const [cookies, setCookie] = useCookies(['acceptedTOS']);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showBanner, setShowBanner] = useState<boolean>(false);

    useEffect(() => {
        // has the user seen the disclaimer
        if (!cookies.acceptedTOS) {
            setShowBanner(true);
        }
        // eslint-disable-next-line
    }, []);

    // hide disclaimer and update cookies to not show again
    const agreeClicked = () => {
        setShowBanner(false);
        setCookie("acceptedTOS", true);
    }

    const renderDisclaimer = () => {
        if (!showModal) return null;
        else if (showModal)
            return(
                <Modal 
                    showModal={showModal} 
                    setShowModal={setShowModal}
                    closeOnOuterClick={true}
                >
                    <div className="disclaimer-wrapper fade-in">
                        <XCircle onClick={() => setShowModal(false)}/>
                        <section className="disclaimer-header">
                            <h1><b>Disclaimer</b></h1>
                            <p>Vendor Finance is a set of smart contracts made available to be used voluntarily, on an “as-is” and “as available” basis. While we have made reasonable efforts to ensure Vendor Finance’s security and functionality, we strongly advise anyone not to risk any funds you can not afford to lose. By interacting or attempting to interact (in both cases, “interacting”) with the Vendor Finance user interface and or smart contracts, you confirm that you understand and agree to the following terms. <b>IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING TERMS, YOU MAY NOT USE OR ACCESS THE INTERFACE OR SMART CONTRACTS IN ANY MANNER:</b></p>
                        </section>
                        <ul>
                            <h3>Eligibility and User Age</h3>
                            <li>
                                You are not a US Person; you are not a resident, national, or agent of Antigua and Barbuda, Algeria, Bangladesh, Bolivia, Belarus, Burundi, Burma (Myanmar), Cote D’Ivoire (Ivory Coast), Crimea and Sevastopol, Cuba, Democratic Republic of Congo, Ecuador, Iran, Iraq, Liberia, Libya, Magnitsky, Mali, Morocco, Nepal, North Korea, Somalia, Sudan, Syria, Venezuela, Yemen, Zimbabwe or any other country to which the United States, the United Kingdom or the European Union embargoes goods or imposes similar sanctions; you are not a member of any sanctions list or equivalent maintained by the United States government, the United Kingdom government, the European Union, or the United Nations; you do intend to transact with any Restricted Person or Sanctions List Person; you do not, and will not, use VPN software or any other privacy or anonymization tools or techniques to circumvent, or attempt to circumvent, any restrictions.
                            </li>
                            <li>
                                You represent and warrant that you are an individual of eighteen (18) years of age or older and are in possession of the rights and/or authorization required to form a binding contract. If you are not eighteen (18) years of age or older.
                            </li>
                            <h3>Changes</h3>
                            <li>
                                We reserve the right to suspend, modify, or discontinue all or part of the Interface. We reserve the right to make changes to the terms at any time.
                            </li>
                            <h3>Supported Digital Assets</h3>
                            <li>
                                Vendor Finance may discontinue support for a digital asset at any time and for any reason at our sole discretion without advance warning.
                            </li>
                            <h3>No Warranties</h3>
                            <li>
                                The Vendor Finance team does not give any warranties, whether express or implied, regarding the protocol's suitability or usability. Vendor Finance will not be liable for any loss, whether such loss is direct, indirect, special, or consequential, suffered by any party due to their use of the Vendor Finance smart contracts and or user interface.
                            </li>
                            <h3>Assumption of Risk</h3>
                            <li>
                                By accessing and using the Interface and or smart contracts, you represent that you are financially and technically sophisticated enough to understand the inherent risks associated with using cryptographic and blockchain-based systems and that you have a working knowledge of the usage and intricacies of digital assets such as bitcoin (BTC), ether (ETH), and other digital tokens such as those following the Ethereum Token Standard (ERC-20). In particular, you understand that blockchain-based transactions are irreversible.
                            </li>
                            <h3>Indemnity, Hold Harmless, Release of Claims, and Limitation of Liability</h3>
                            <li>
                                To the fullest extent allowed under applicable law, you agree to indemnify and hold the Vendor Finance Parties harmless from and against any and all injury, claims, liabilities, damages (actual and consequential), losses, and expenses (including attorneys’ fees) arising from or in any way related to any claims relating to (a) your access to and use of the Interface and or smart contracts (including any actions taken by a third party using your account). You further expressly waive and release us from any and all liability, claims, causes of action, or damages arising from or in any way relating to your use of the interface, smart contracts and your interaction with Vendor Finance.
                            </li>
                        </ul>
                    </div>
                </Modal>
        );
    }

    if (showBanner)
        return (
          <Fragment>
            <div className="disclaimer-banner-wrapper fade-in">
              <span className="banner-message">
                Vendor Finance is a set of smart contracts made available to be
                used voluntarily, on an "as-is" and "as available" basis. While
                we have made reasonable efforts to ensure Vendor Finance's
                security and functionality, we strongly advise anyone not to
                risk any funds you can not afford to lose. By interacting or
                attempting to interact (in both cases, “interacting”) with the
                Vendor Finance user interface and or smart contracts, you
                confirm that you understand and agree to our {" "} 
                <b onClick={() => setShowModal(!showModal)}>
                  Terms of Services
                </b>
                . Vendor Finance also uses browser-cookies solely to improve user experience. By using Vendor Finance or agreeing to our ToS you consent to the use of cookies.
              </span>
              <div className="banner-button-wrapper">
                <button onClick={() => agreeClicked()}>Accept</button>
                <a href="https://defillama.com/">
                  <button>Reject</button>
                </a>
              </div>
            </div>
            {renderDisclaimer()}
          </Fragment>
        );
    else 
        return null;


}

export default Disclaimer; 