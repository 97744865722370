import { Fragment, useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import "./Explore.css";
import { fetchTokenSymbol, getNetworkData, shouldRenderPool, tabletBreakpoint, tokenData } from "../../utils/Utils";
import PoolFilters from "../../components/PoolFilters/PoolFilters";
import FeaturedPools from "../../components/FeaturedPools/FeaturedPools";
import { APP_DATA_CONTEXT, FEATURED_POOLS, POOL_DATA, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import LendingPool from "../../components/LendingPool/LendingPool";
import AssetRow from "../../components/AssetsRow/AssetRow";
import { Alert } from "@material-ui/lab";
import { PuffLoader } from "react-spinners";
import TokenFilter from "../../components/TokenFilter/TokenFilter";
import LiquidityRangeFilter from "../../components/LiquidityRangeFilter/LiquidityRangeFilter";
import { useScreenSize } from "../../utils/useScreenSize";
import { AppDataContext } from "../../context/AppDataContext";
import { WalletDataContext } from "../../context/WalletDataContext";
import { PoolDataContext } from "../../context/PoolDataContext";
declare var window: any;

const Explore = () => {

  const [pools, setPools] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [assetRows, setAssetRows] = useState<any[]>([]);
  const [selectedPool, setSelectedPool] = useState<any>();
  const [colFilter, setColFilter] = useState<string[]>([]);
  const [lendFilter, setLendFilter] = useState<string[]>([]);
  const { screenWidth } = useScreenSize();
  const [queryPool, setQueryPool] = useState<string>();
  const [loadedQueryPool, setLoadedQueryPool] = useState<boolean>(false);
  const [selectedFeaturedPools, setSelectedFeaturedPools] = useState<FEATURED_POOLS | undefined>();
  const poolLoadedCount = useRef<number>(0);
  const fetchingPools = useRef<boolean>(false);
  const { tokenPrices, liquidityRange, setLiquidityRange, fullPoolData, calculateLTV } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { chainId, account } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;

  useEffect(() => {
    poolLoadedCount.current = 0;
    getPools();
    window.addEventListener('click', onClick);
    return () => {
      window.removeEventListener('click', onClick);
    };
  // eslint-disable-next-line
  }, [chainId]);

  useEffect(() => {
    if (!loadedQueryPool)
      selectQueryPool();
  // eslint-disable-next-line
  }, [fullPoolData]);

  useEffect(() => {
    if (pools.length > 0)
    updateAssetRowData(pools);
  // eslint-disable-next-line
  }, [selectedFeaturedPools, pools]);

  useEffect(() => {
    if (selectedPool)
      setQueryPool(selectedPool.id);
  }, [selectedPool]);

  // check if pool was passed in the URL and select it if needed
  const selectQueryPool = () => {
    const pathname = window.location.pathname;
    const queryPoolAddress = pathname.substring(pathname.lastIndexOf("/") + 1).toLowerCase();
    setQueryPool(queryPoolAddress)
    for (const pool of pools) {
      // don't load the pool if query pool has already been loaded once
      if (pool.id === queryPoolAddress) {
        setSelectedPool(fullPoolData[pool.id]);
      }
    }
    setLoadedQueryPool(true && pools.length > 0);
  }

  const onClick = (e: any) => {
    const element = document.querySelector(".topnav-header.desktop");
    if (element?.contains(e.target))
      setSelectedPool(undefined);
  }

  const getPools = async() => {
    if (fetchingPools.current || !chainId) return;
    // console.log("fetching pools");
    try {

      const url:any = getNetworkData(chainId)?.graphUrl;

      // only show 15 pools when testing locally
      const count = window.location.hostname === "localhost" ? 200 : 200;
      
      const lent = await axios.post(
        url,
        {
          query: `
          {
            pools(first: ${count}, where: {_paused: false, _expiry_gt: ${Math.floor(new Date().getTime() / 1000)}}) {
              id
              _deployer
              _colToken
              _lendToken
              _feeRate
              _expiry
              _mintRatio
              _startTime
              _borrowers
              _totalBorrowed
              _colBalance
              _lendBalance
              _paused
              _type
            }
          }
          `
        }
      )
      // arbitrary start time to filter pools by.
      // const arbitraryStart = 1648851730;
      const poolBlacklist:string[] = [
        "0x2B23307C2D92671142301FFf572f3B69760713fe",
        "0xE013E963026272be98a70f6be5DCa6c391393709",
        "0xd2dCcb2ffD691Ec32585F5e7eD04B368EeD4bF04",
        "0xb567b539918911d05C1A54FF8Bc74ad07994e9ae",
        "0x12961348bA90E7253781721Ff1C92317F587ed2f",
        "0x14cba2d8c5247265fb68055a079be1b749f3ef56",
        "0x10c5a697228f7f5cb33f6a85d67ac47c36dfb45c",
        "0x0e9709AE478358a09eC124b1EbF206C236590897",
        "0xa2E96AEBB051D1c84676B10B47669df068ce3591",
        "0x8d83e08b780b412cb97E615a68304c7c8D2E7f52",
        "0xF83268339bc3c866772E7950253e5A1a60291195",
        "0xbc33738f89b17063b5F25E7662cAFc1daeA46faa",
        "0x06194Ef3e28A70ec490bF31a788bC3714D75e36a",
        "0x3D2eC20E773E33266025C178b03f50bEc881Fd9F",
        // paused
        "0x39b9f0c8bd0b1add3756b0726c60e71a36225424",
        "0x4e5d12708d8157dc06c9c6c45ef9722a3b4773bd",
        "0xa273242093e0de7015d3e210bc871840ae735bf9",
        "0xe7e44cc639f2dff5e827492d48602ff3d632aa4f",
        "0xf8139974ad1d34bb2168fb5dff3e9cb9f00fecf6",
        "0x0767e3e55b1b056e9c1d128a3b73b04bc1246133",
        "0x07aAf6A1aa00bf75330D750f3F8E8E5eA8BD292f"
      ].map((pool) => pool.toLowerCase());
      const _pools = lent.data.data.pools;
      // remove blacklisted pools from list
      const pools = _pools.filter((pool: any) => !poolBlacklist.includes(pool.id.toLowerCase()));
      if (pools.length > 0) {
        setPools(pools);
        updateAssetRowData(pools);
        let allSymbols:string[] = [];
        Object.values(tokenData).forEach((data) => {
          let supportedNetworks = data.supportedNetworks as number[];
          if (supportedNetworks.includes(chainId)) {
            allSymbols.push(data.symbol.toUpperCase());
          }
        });
        // set filters
        setColFilter(allSymbols);
        setLendFilter(allSymbols);
      }
    } catch (e) {
      // console.log(e);
    }
    fetchingPools.current = false;
  }

  const updateAssetRowData = (pools: any[]) => {
    const assetRowData:any = {}
    let count = 0;

    const featuredList = selectedFeaturedPools?.filterMethod(pools, tokenPrices);

    for (const pool of pools) {
      // key for asset row
      const key = `${pool._colToken}/${pool._lendToken}`;

      // update or create a new entry for col/lend token combos
      if (assetRowData[key] !== undefined) {
        let poolArr = [...assetRowData[key].pools];
        // check if current pool is part of a featured filter 
        if (featuredList?.includes(pool.id) || pool.id.toLowerCase() === queryPool) 
          poolArr.push(pool);
        assetRowData[key] = {
          ...assetRowData[key],
          pools: poolArr
        }
      } else {
        let poolArr = [];

        // check if current pool is part of a featured filter 
        if (featuredList?.includes(pool.id) || pool.id.toLowerCase() === queryPool) 
          poolArr.push(pool);

        assetRowData[key] = {
          ...assetRowData[key],
          pools: poolArr,
          lendToken: pool._lendToken,
          colToken: pool._colToken
        }

      } 

      if (count++ === pools.length - 1) {
        // extract the groups and set state
        let assetRowArr:any[] = [];
        Object.entries(assetRowData).forEach((data) => {
          assetRowArr.push(data[1]);
        });
        setAssetRows(assetRowArr);
      }
    }
  }

  const shouldRenderRow = (
    assetRowData: {
      colToken: string;
      lendToken: string;
      pools: POOL_DATA[];
    },
  ): boolean => {
    // get token symbols
    const colSymbol = fetchTokenSymbol(assetRowData.colToken, chainId);
    const lendSymbol = fetchTokenSymbol(assetRowData.lendToken, chainId);
    // const featuredList = selectedFeaturedPools?.filterMethod(assetRowData.pools, tokenPrices);
    // if an asset row has 0 non-zero balance pools, don't render it
    let hasPoolsOfInterest = false;
    if (
      assetRowData.pools.length > 0 &&
      selectedFeaturedPools?.name === "All Pools"
    ) {
      // collect pools that are in range
      const poolsOfInterest = assetRowData.pools.filter((pool) => {
        const ltv = calculateLTV(pool);
        return shouldRenderPool(
          pool,
          chainId,
          tokenPrices,
          account,
          liquidityRange,
          queryPool,
          ltv,
          true
        )
      });
      hasPoolsOfInterest = poolsOfInterest.length > 0;
    } else if (selectedFeaturedPools?.name !== "All Pools") {
      const featuredDeployerPools = assetRowData.pools.filter((pool) => {
        // return featuredList?.includes(pool.id.toLowerCase()) || pool.id.toLowerCase() === queryPool
        const ltv = calculateLTV(pool);
        return shouldRenderPool(
          pool,
          chainId,
          tokenPrices,
          account,
          liquidityRange,
          queryPool,
          ltv,
          false
        )
      });
      hasPoolsOfInterest = featuredDeployerPools.length > 0;
    }

    // filter logic
    const inSearch =
      colSymbol.toUpperCase().includes(searchValue.toUpperCase()) ||
      lendSymbol.toUpperCase().includes(searchValue.toUpperCase());
    // are either of the tokens in the token filters
    const inColFilter = colFilter.includes(colSymbol.toUpperCase());
    const inLendFilter = lendFilter.includes(lendSymbol.toUpperCase());

    // final show logic
    let show = false;
    if (
      inSearch &&
      inColFilter &&
      inLendFilter &&
      hasPoolsOfInterest &&
      assetRowData.pools.length > 0
    ) {
      show = true;
    } else show = false;

    return show;
  };

  const renderProtocolIntro = () => {
    return (
      <div className="protocol-intro-wrapper fade-in">
        <h1>How to use the protocol</h1>
        <p>
          Borrowing is simple! Choose the borrowing pool you would like to enter, review the terms set by the lender and then deposit your collateral. For a more comprehensive guide on how to borrow please review the borrower documents <a href="https://docs.vendor.finance/how-to-use/borrow" target="_blank" rel="noreferrer">here</a>
        </p>
        <p>
          As a borrower your interest rate is fixed as soon as you have a confirmation that your borrow transaction was successful. You can rest assured that you will never be liquidated! Defaulting your collateral is an option that is up to the borrower! As long as you repay by the expiration, you will receive your collateral back! 
        </p>
        <p>
          As a lender you get to set all of your own lending terms! All you need to do is go to the “Create Pool” tab. Once you have created your pool all you need to do is fund it! For a more comprehensive guide on how to lend please review the lender documents <a href="https://docs.vendor.finance/how-to-use/lend" target="_blank" rel="noreferrer">here</a>
        </p>
      </div>
    );
  }

  const shouldBeOpen = (pools: POOL_DATA[]) => {
    let show = false;
    pools.forEach((pool: POOL_DATA) => {
      if (show) return;
      // check if pool is the query pool
      const isQueryPool = pool.id === queryPool;
      show = isQueryPool;
    });
    return show;
  }

  const renderAssetRows = () => {
    // filter out for pools we don't want to show
    // and sort by pool count (for now)
    const filteredAssetRows = assetRows
      .filter((assetRowData: any) => shouldRenderRow(assetRowData))
      .sort((d1: any, d2: any) => d2.pools.length - d1.pools.length);

    // check if token prices have been loaded yet
    const pricesLoaded = Object.entries(tokenPrices).length > 0;

    if (pools.length === 0 || !pricesLoaded) {
      return (
        <Alert severity="info" className="no-pools-alert fade-in">
          Fetching Pools...
          <PuffLoader size={30} className="loader"/>
        </Alert>
      );
    } else if (filteredAssetRows.length > 0) {
      return (
          filteredAssetRows.map((assetRowData: any, index: number) => 
            <Fragment key={index}>
              <AssetRow
                lendToken={assetRowData.lendToken}
                colToken={assetRowData.colToken}
                pools={assetRowData.pools}
                searchValue={searchValue}
                open={shouldBeOpen(assetRowData.pools)}
                key={`${assetRowData.colToken}-${assetRowData.lendToken}`}
              />
            </Fragment>
          )
      );
    } else if (filteredAssetRows.length === 0 && pricesLoaded) {
      return (
        <Alert severity="info" className="no-pools-alert fade-in">
          No pools found. Try modifying your search / filter values 
        </Alert>
      );
    }
  }

  const renderPoolFilters = () => {
    return (
      <Fragment>
        <TokenFilter
          setColFilter={setColFilter}
          setLendFilter={setLendFilter}
          colFilter={colFilter}
          lendFilter={lendFilter}
        />
        <LiquidityRangeFilter
          liquidityRange={liquidityRange}
          setLiquidityRange={setLiquidityRange}
        />
      </Fragment>
    )
  }

  const renderRightSection = () => {
    if (screenWidth > tabletBreakpoint)
      return (
        <section className="explore-right-section">
          {!selectedPool ? (
            renderProtocolIntro()
          ) : (
            <LendingPool
              poolAddress={selectedPool.id}
              poolData={selectedPool}
              updatePoolData={getPools}
            />
          )}
        </section>
      );
    else {
      if (selectedPool)
        return (
          <section className="explore-right-section modal fade-in">
            <LendingPool
              poolAddress={selectedPool.id}
              poolData={selectedPool}
              updatePoolData={getPools}
            />
          </section>
        );
    }
  }

  return (
    <div className="explore-wrapper fade-in">
      <PoolDataContext.Provider value={{ selectedPool, setSelectedPool, selectedFeaturedPools, setSelectedFeaturedPools }}>
        <section className="explore-left-section left-section">
          <FeaturedPools
            pools={pools}
          />
          <div className="filter-wrapper">
            <PoolFilters
              setSearchValue={setSearchValue}
              children={renderPoolFilters()}
            />
          </div>
          <div className="explore-asset-rows">
            {renderAssetRows()}
          </div>
        </section>
        {renderRightSection()}
      </PoolDataContext.Provider>
    </div>
  );
}

export default Explore;