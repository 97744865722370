import "./Dashboard.css";
import TopNav from "../TopNav/TopNav";
import { useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";
import CreatePool from "../../pages/CreatePool/CreatePool";
import MyPools from "../../pages/MyPools/MyPools";
import Explore from "../../pages/Explore/Explore";
import Notification from "../../components/Notification/Notification";
import {
  fetchLocalTokenData,
  getAccount,
  getChainId,
  getWeb3Provider,
  tokenData,
  contractAddresses,
  readableABIs,
  getPoolGraphData,
  networks,
  handleMulticallAddress,
  fetchTokenPrice,
  defaultChain,
  fetchBulkPrices,
  getNetworkDataByName,
  getNetworkData,
  fetchHistoricalPriceData,
} from "../../utils/Utils";
import { BigNumber, ethers } from "ethers";
import { useConnectWallet } from "@web3-onboard/react";
import { AppDataContext } from "../../context/AppDataContext";
import { WalletDataContext } from "../../context/WalletDataContext";
import LendingPoolABI from "../../abi/LendingPool.js";
import { LOCAL_TOKEN_DATA, POOL_DATA } from "../../utils/Interfaces";
import { Contract, Provider as MulticallProvider, Provider } from "ethers-multicall";
import { SnackbarProvider } from "notistack";
declare var window: any;

// let tokenPrices:{} = {}

const Dashboard = () => {
  // pending result
  const [pending, setPending] = useState<boolean>(false);
  // range of liquidity to show
  const [liquidityRange, setLiquidityRange] = useState<number[]>([0, 1000000]);
  // token prices to be updated in AppData context
  const [tokenPrices, setTokenPrices] = useState<any>({});
  const [historicalPrices, setHistoricalPrices] = useState<any>({});
  // object holding full contract data for all pools
  const [fullPoolData, setFullPoolData] = useState<any | {}>({});


  // wallet data to be updated in the WalletDataContext 
  const [{ wallet }] = useConnectWallet();
  const [account, setAccount] = useState<string | any>("");
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | ethers.providers.JsonRpcSigner | undefined>();
  const [chainId, setChainId] = useState<number>();
  const [multicallProvider, setMulticallProvider] = useState<MulticallProvider>();
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const connecting = useRef<boolean>(false);

  useEffect(() => {
    // spam prevention
    if (!connecting.current && !initialLoad)
      firstLoadWallet();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // load token prices if they haven't already been loaded
    if (chainId) loadTokenPrices();
    if (Object.entries(historicalPrices).length === 0) getHistoricalEthPrices();
  // eslint-disable-next-line
  }, [chainId])

  useEffect(() => {
    if (initialLoad) updateWalletData();
  // eslint-disable-next-line
  }, [wallet]);

  // pre-fetch historical prices for WETH before getting to the create pool page
  const getHistoricalEthPrices = async () => {
    // fetch historical price data from backend or load from cached data
    if (!chainId) return;
    let historicalData:[][] = [];
    // @ts-ignore
    const colToken = tokenData.WETH.address[chainId];
    // check if entry exists
    if (!historicalPrices[colToken]) {
      // if no entry, load from backend
      historicalData = await fetchHistoricalPriceData(colToken, chainId, 61);
      let newStoredData = {
        ...historicalPrices,
        [colToken]: historicalData
      }
      // save data to cache
      setHistoricalPrices(newStoredData);
    } else {
      // if entry, load from cache
      historicalData = historicalPrices[colToken];
    }
    return (historicalData);
  }
  
  const updateWalletData = () => {
    if (wallet && getChainId(wallet) === 42161 && chainId === 42161) {
      const provider = getWeb3Provider(wallet?.provider);
      const multicallProvider = new Provider(provider);
      const chainId = getChainId(wallet);
      const account = getAccount(wallet);
      multicallProvider.init();
      setProvider(provider);
      setMulticallProvider(multicallProvider);
      setChainId(chainId);
      setAccount(account);
    } else if (wallet && getChainId(wallet) !== chainId) {
      // wallet is connected but chainId is different
      const network = getNetworkData(chainId as number) ;
      const provider = getWeb3Provider(network.rpcUrl, true);
      const multicallProvider = new Provider(provider);
      const account = getAccount(wallet);
      multicallProvider.init();
      setProvider(provider);
      setMulticallProvider(multicallProvider);
      setChainId(chainId);
      setAccount(account);
    } else if (wallet && getChainId(wallet) === chainId) {
      // wallet is connected and chainId is the same
      const provider = getWeb3Provider(wallet.provider);
      const multicallProvider = new Provider(provider);
      const account = getAccount(wallet);
      multicallProvider.init();
      setProvider(provider);
      setMulticallProvider(multicallProvider);
      setChainId(chainId);
      setAccount(account);
    }
  }

  // method for initially loading wallet data
  const firstLoadWallet = () => {
    connecting.current = false;
    // const userWallet = wallet;
    let multicallProvider;
    let chainId;
    let provider;
    let account;

    // check if there is a network in the url
    const urlParams = new URLSearchParams(window.location.search);
    const queryChain = urlParams.get("chain");
    const network = queryChain 
      ? getNetworkDataByName(queryChain) 
      : networks.Arbitrum

    provider = getWeb3Provider(network.rpcUrl, true);
    multicallProvider = new Provider(provider);
    chainId = network.chainId;

    if (multicallProvider) {
      multicallProvider.init();
      setProvider(provider);
      setMulticallProvider(multicallProvider);
      setChainId(chainId);
      setAccount(account);
    }

    setInitialLoad(true);
    connecting.current = false;
  }

  const calculateLTV = (pool: POOL_DATA) => {
    // get token prices from tokenPrice context object
    const lendPrice = tokenPrices[ethers.utils.getAddress(pool._lendToken)];
    const colPrice = tokenPrices[ethers.utils.getAddress(pool._colToken)];
    // calculate LTV from lend and collateral prices
    const mintRatio = ethers.utils.formatUnits(pool._mintRatio, 18);
    const ltvLendValue = parseFloat(mintRatio) * lendPrice;
    const ltvColValue = colPrice;
    return (ltvLendValue / ltvColValue) * 100;
  }

  // take the difference between old pool balance and new pool balance
  // to calculate the new _totalBorrowed
  const updateBorrowBalance = async (oldPoolData: POOL_DATA, newPoolData: POOL_DATA) => {
    // difference in amount available
    const diff = parseFloat(oldPoolData.poolLendBalance) - parseFloat(newPoolData.poolLendBalance);
    let newTotalBorrowed = ethers.utils.parseUnits(diff.toFixed(2), newPoolData.lendDecimals).add(newPoolData._totalBorrowed);
    // prevent from going below 0
    newTotalBorrowed = newTotalBorrowed.lt(BigNumber.from("0")) ? BigNumber.from("0") : newTotalBorrowed;
    // create a copy of the pool data
    // and add new values
    let copy = {
      _totalBorrowed: newTotalBorrowed.toString()
    };
    return(copy);
  }

  // get all the contract based pool data along with some calculated values.
  // store the data in an object holding all pool data
  const getPoolData = async (pools: POOL_DATA[], updateBorrowed?: boolean) => {

    if (!multicallProvider || !chainId) return;

    // console.log("getting pool data for the following pools: ");
    // console.log(pools);

    const localData:any = {};
    let completed = 0;

    pools.forEach(async (pool: POOL_DATA) => {
      try {

        // skip if provider doesn't exist or 
        if (
          !provider || 
          (fullPoolData[pool.id] !== undefined && !updateBorrowed)
        ) {
          completed++;
          return;
        }

        // setup the correct multicall address
        handleMulticallAddress(chainId, multicallProvider);

        // setup contracts for multicall
        const poolContract = new Contract(pool.id, readableABIs.lendingPool);
        const lendTokenContract = new Contract(pool._lendToken, readableABIs.erc20);
        const colTokenContract = new Contract(pool._colToken, readableABIs.erc20);
        // @ts-ignore
        const feeManagerContract = new Contract(contractAddresses.FEE_MANAGER.address[chainId], readableABIs.feeManager);

        const colData: LOCAL_TOKEN_DATA = fetchLocalTokenData(
          pool._colToken,
          chainId
        );
        const lendData: LOCAL_TOKEN_DATA = fetchLocalTokenData(
          pool._lendToken,
          chainId
        );

        let amountOwed = "0";
        let interestOwed = "0";
        let userColDeposited = "0";
        let borrowAmount = "0";
        let rawUserInterestOwed = BigNumber.from("0");

        // get individual user stats if account is present and user is on my-pools
        if (account && window.location.pathname.indexOf("my-pools") > -1) {
          try {
            // reinitialize lending pool contract with ethers
            const poolContract = new ethers.Contract(pool.id, LendingPoolABI, provider);
            const rawUserDebtData = await poolContract.debt(account);
            // extract user debt data
            const [
              rawBorrowAmount,
              rawColAmount,
              rawTotalFees,
            ] = rawUserDebtData;
            const totalFees = ethers.utils.formatUnits(
              rawTotalFees,
              lendData?.tokenDecimals
            );
            const amountBorrowed = ethers.utils.formatUnits(
              rawBorrowAmount,
              lendData?.tokenDecimals
            );
            const colAmount = ethers.utils.formatUnits(
              rawColAmount,
              colData?.tokenDecimals
            )
            amountOwed = (parseFloat(amountBorrowed) + parseFloat(totalFees)).toString();
            borrowAmount = amountBorrowed;
            interestOwed = totalFees;
            userColDeposited = colAmount;
            rawUserInterestOwed = rawTotalFees;

          } catch (e) {console.log(e)}
        }


        // list of calls to feed through multicall for the current pool
        let calls:any[] = [
          lendTokenContract.balanceOf(pool.id),
          colTokenContract.balanceOf(pool.id),
          feeManagerContract.feeRates(pool.id),
          feeManagerContract.getCurrentRate(pool.id),
          poolContract.totalFees(),
          poolContract.undercollateralized()
        ];

        const feeType = pool._type;

        // execute contract calls 
        const response = await multicallProvider.all(calls);

        // extract data from calls
        let [
          poolLendBalanceResp, 
          poolColBalanceResp, 
          annualizedFeeRate, 
          feeRateResp, 
          rawPoolInterestOwed,
          undercollateralized
        ] = response;


        // calculate annualized fee rate based on type
        if (feeType === "1") {
          annualizedFeeRate = (31536000 / (((Number(pool._expiry)) - new Date().getTime() / 1000))) * (parseFloat(feeRateResp.toString()));
        } else {
          annualizedFeeRate = annualizedFeeRate.toString();
        }

        // get token prices from tokenPrice context object
        const lendPrice = tokenPrices[ethers.utils.getAddress(lendData.address[`${chainId}`])];
        const colPrice = tokenPrices[ethers.utils.getAddress(colData.address[`${chainId}`])];

        // calculate LTV from lend and collateral prices
        const mintRatio = ethers.utils.formatUnits(pool._mintRatio, 18);
        const ltvLendValue = parseFloat(mintRatio) * lendPrice;
        const ltvColValue = colPrice;
        const ltv = (ltvLendValue / ltvColValue) * 100;

        // undercollateralized and underwater logic
        // undercollateralized = undercollateralized.gt(BigNumber.from("0")) ? true : false;
        undercollateralized = undercollateralized.toString() === "0" ? false : true;
        let underMintRatio = colPrice < parseFloat(mintRatio);
        let isUnder;
        if (underMintRatio && undercollateralized) {
          isUnder = false;
        } else if (underMintRatio && !undercollateralized) {
          isUnder = true;
        } else {
          isUnder = false;
        }

        // collect all calculated data into an object 
        let fetchedData = {
          ...pool,
          externalDataLoaded: true,
          borrowAmount,
          poolLendBalance: ethers.utils.formatUnits(poolLendBalanceResp, lendData?.tokenDecimals),
          poolColBalance: ethers.utils.formatUnits(poolColBalanceResp, colData?.tokenDecimals),
          poolInterestOwed: ethers.utils.formatUnits(rawPoolInterestOwed, lendData?.tokenDecimals),
          isExpired: Number(pool._expiry) * 1000 < new Date().getTime(),
          colSymbol: colData?.symbol,
          lendSymbol: lendData?.symbol,
          colDecimals: colData?.tokenDecimals,
          lendDecimals: lendData?.tokenDecimals,
          currentFeeRate: feeRateResp.toString(),
          rawUserInterestOwed: rawUserInterestOwed,
          rawPoolInterestOwed: rawPoolInterestOwed,
          colPrice,
          lendPrice,
          feeType,
          annualizedFeeRate,
          amountOwed,
          interestOwed,
          userColDeposited,
          isUnder,
          undercollateralized: Boolean(undercollateralized),
          ltv
        }

        let updatedData = {};

        // if using custom list of pools, get updated data for the pool
        if (updateBorrowed) {
          const updatedBorrowBalanceData = await updateBorrowBalance(pool, fetchedData);
          const graphData = await getPoolGraphData(pool.id, chainId);
          updatedData = {
            ...updatedBorrowBalanceData,
            ...graphData
          }
        }

        // save the data into the local object
        localData[`${pool.id}`] = {
          ...fetchedData,
          ...updatedData
        };

      } catch (e) {
        completed++;
        console.log(e);
      }

      if (completed++ >= pools.length - 1) {
        setFullPoolData({
          ...fullPoolData,
          ...localData
        });
      }
    });
  };

  // locally load the token price or fetch it if it hasn't been loaded yet
  const loadTokenPrices = async () => {
    let localPrices:any = {};
    let completed = 0;

    console.log("loading prices");
    // extract prices from coingecko bulk response
    const extractPrices = (bulkPrices: any) => {
      // iterate through all tokens and grab the response if it exists
      Object.values(tokenData).forEach((data: any) => {
        const priceKey = `coingecko:${data.id}`;
        try {
          if (bulkPrices[priceKey]) {
            // get price, tokenaddress, and key for object
            const price = bulkPrices[priceKey].price;
            const tokenAddress = data.address[`${chainId}`];
            const key = ethers.utils.getAddress(tokenAddress);
            // add price to localPrices object
            localPrices = {
              [`${key}`]: price,
              ...localPrices
            }
          }
        } catch (e) {
          console.log(e);
          console.log("failed to load price for ", data.id);
        }
      });
    }

    let ids:string[] = [];

    // iterate through all tokens and fetch prices
    Object.values(tokenData).forEach(async (data: any) => {
      let price = 0;
      // get token address for current chain and the address
      // for where the price can be fetched
      const tokenAddress = data.address[`${chainId}`];
      const priceNetwork = data.historicalPriceNetwork[0];
      const networkAddress = data.address[priceNetwork];
      // skip non-supported tokens for the chain
      const supported = tokenAddress !== "" && tokenAddress !== undefined;
      if (!localPrices[networkAddress] && supported) {
        const key = ethers.utils.getAddress(tokenAddress);
        // fetch price
        if (data.id) {
          // if token has an ID add to array
          ids.push(data.id);
        } else {
          // else fetch price normally (oracle/coingecko single token/firebase)
          price = await fetchTokenPrice(tokenAddress, chainId || defaultChain, provider);
          // store in object
          localPrices = {
            [`${key}`]: price,
            ...localPrices
          }
        }
      } 
      // update global state object when complete
      if (completed++ === Object.values(tokenData).length - 1) {
        const bulkPrices = await fetchBulkPrices(ids);
        extractPrices(bulkPrices);
        setTokenPrices(localPrices);
      }
    });

    // don't delete
    return;
  }

  return(
    <div className="dashboard-wrapper">
      <WalletDataContext.Provider value={{ provider, setProvider, chainId, setChainId, account, setAccount, multicallProvider, setMulticallProvider }}>
        <AppDataContext.Provider 
          value={{ 
            tokenPrices, 
            setTokenPrices, 
            pending, 
            setPending, 
            calculateLTV, 
            liquidityRange, 
            setLiquidityRange,
            fullPoolData,
            getPoolData,
            setFullPoolData,
            historicalPrices,
            setHistoricalPrices
          }}
        >
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={600000}
            content={(key: any, message: string) => {
              return (
                <div>
                  <Notification message={message} key={key} id={key} />
                </div>
              );
            }}
          >
            <TopNav/>
            <div className="dashboard-content">
              <div className="routes-container">

                    <Routes>
                      <Route
                        path="/create-pool"
                        element={
                          <CreatePool/>
                        }
                      />
                      <Route
                        path="/my-pools/*"
                        element={
                          <MyPools/>
                        }
                      />
                      <Route
                        path="/borrow/*"
                        element={
                          <Explore
                            key={`Borrow Page (${chainId})`}
                          />
                        }
                      />
                      <Route
                        path="/"
                        element={
                          <Explore
                            key={`Borrow Page (${chainId})`}
                          />
                        }
                      />
                    </Routes>
              </div>
            </div>
          </SnackbarProvider>
        </AppDataContext.Provider>
      </WalletDataContext.Provider>
    </div>
  )
}

export default Dashboard;