import { ethers } from "ethers";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { LICENSE_DATA } from "../../../utils/Interfaces";
import { useScreenSize } from "../../../utils/useScreenSize";
import { mobileBreakpoint, tabletBreakpoint } from "../../../utils/Utils";

const LicenseRow = (props: {
  licenseData: LICENSE_DATA, 
  key: number,
  selectedLicense: LICENSE_DATA | any,
  setSelectedLicense: (license: LICENSE_DATA) => void
}) => {

  const [poolsRemaining, setPoolsRemaining] = useState<number>(0);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const { screenWidth } = useScreenSize();

  useEffect(() => {

    // update pool count on row
    if (props.licenseData) {
      const remainingRaw = props.licenseData.maxPoolCount.sub(props.licenseData.currentPoolCount);
      setPoolsRemaining(parseFloat(ethers.utils.formatUnits(remainingRaw, 0)));
    }
    // check if pool is selected
    if (props.selectedLicense !== undefined) {
      setIsSelected(props.selectedLicense.id.eq(props.licenseData.id));
    } else if (props.selectedLicense === undefined) {
      setIsSelected(false);
    }

  }, [props.selectedLicense, props.licenseData]);

  const rowClicked = () => {
    props.setSelectedLicense(props.licenseData);
  }

  const renderExpiry = () => {
    const expiry = props.licenseData.expiry;
    if (screenWidth > mobileBreakpoint) return moment(expiry).format("MMM D, Y hh:mm a");
    else return moment(expiry).format("MM/DD/YY hh:mm A");
  }

  const renderDiscountColumns = () => {
    if (screenWidth > mobileBreakpoint) {
      return (
        <Fragment>
          <span className="license-row-column lend-discount-column">
            {props.licenseData.lendDiscount / 10000}%
          </span>
          <span className="license-row-column collateral-discount-column">
            {props.licenseData.colDiscount / 10000}%
          </span>
        </Fragment>
      );
    } else {
      return (
        <span className="license-row-column lend-discount-column">
          {props.licenseData.lendDiscount / 10000}% / {props.licenseData.colDiscount / 10000}%
        </span>
      );
    }
  }

  const renderPoolCount = () => {
    const text = poolsRemaining === 0 || poolsRemaining > 1 
      ? "Pools" 
      : "Pool";
    return (`${poolsRemaining} ${screenWidth > tabletBreakpoint ? text : ""}`);
  }

  return (
    <div className={`license-row-wrapper ${isSelected ? "selected" : ""}`} onClick={rowClicked}>
      {renderDiscountColumns()}
      <span className="license-row-column expiry-column">
        {renderExpiry()}
      </span>
      <div className="license-row-column pool-count-column">
        <span>
          {renderPoolCount()}
        </span>
      </div>
    </div>
  );
}

export default LicenseRow;