import axios from "axios";
import OracleABI from "../abi/OracleABI";
import { ethers, BigNumber } from "@usedapp/core/node_modules/ethers";
import { Skeleton } from "@material-ui/lab";
import { Arbitrum, Mainnet, Polygon } from "@usedapp/core";
import PoolFactory from "../abi/PoolFactory";
import LicenseManager from "../abi/LicenseManager";
import { db } from "./firebase-config";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore"; 
import moment from "moment";
import FeeManager from "../abi/FeeManager";
import { METHOD_TYPE, POOL_DATA } from "./Interfaces";
import { Provider } from "ethers-multicall";
import { addDays, subDays } from "date-fns";

export const prodRPCAddress = "https://thrilling-virulent-flower.arbitrum-mainnet.quiknode.pro/f1c88a01f0b68ef4fb58fb3c4ae1adbc2b74ee70/";
export const devRPCAddress = "https://eth-goerli.g.alchemy.com/v2/BuClIQXzCjtWOEPzWYpu7kQUZwyrW78C";
// Arbitrum
export const defaultChain:number = 42161;
// Goerli
export const devChain:number = 5;
// time to poll token balances
export const pollTime = 5000;
export const tabletBreakpoint = 1183;
export const mobileBreakpoint = 600;

export const prodGraphURL = `https://gateway-arbitrum.network.thegraph.com/api/${process.env.REACT_APP_GRAPH_KEY}/subgraphs/id/CycasGMVHZbqdgBUCWscBq2bAYZRASquemRavtTgAzya`;
export const devGraphURL = "https://api.thegraph.com/subgraphs/name/0xtaiga/vendorfinancetest";

// readable ABIs for multicall contract instantiation
export const readableABIs = {
  erc20: [
    "function balanceOf(address _owner) public view returns (uint256)",
    "function decimals() public view returns (uint256)",
    "function allowance(address owner, address spender) public view returns (uint256)"
  ],
  lendingPool: [
    "function totalFees() public view returns (uint256)",
    "function undercollateralized() public view returns (uint256)",
    "function allowedRollovers(address) public view returns (bool)"
  ],
  feeManager: [
    "function feeRates(address _pool) public view returns (uint48)",
    "function getCurrentRate(address _pool) public view returns (uint48)"
  ]
}

// get graph data for an individual pool
export const getPoolGraphData = async (id: string, chainId: number) => {

  let res:any[] = [];

  try {
    const url = chainId === devChain 
      ? devGraphURL
      : prodGraphURL;

    const query = await axios.post(
      url,
      {
        query: `
        {
          pools(where: {
            id: "${id.toLowerCase()}",
          }) {
            id
            _deployer
            _colToken
            _lendToken
            _feeRate
            _expiry
            _mintRatio
            _startTime
            _borrowers
            _totalBorrowed
            _colBalance
            _lendBalance
            _paused
            _protocolFee
            _type
          }
        }
        `,
      }
    );
    res = query.data.data.pools[0];
  } catch (e) {
    console.log(e);
    console.log("failed to get pool graph data");
  }

  return res;
}

// get the current page "borrow", "create-pool", "my-pools", etc.
export const getCurrentPage = () => {
  const pathname = window.location.pathname;
  return pathname.split("/")[1];
}

export const getRolloverPools = async (selectedPool: POOL_DATA, deployer: string, chainId: number) => {

  // get graph URL
  const url:any = getNetworkData(chainId)?.graphUrl;

  // get collateral and lend tokens
  const colToken = selectedPool._colToken;
  const lendToken = selectedPool._lendToken;

  const rolloverPoolsGraph = await axios.post(url, {query: `
    {
      pools(where: {
        _lendToken: "${lendToken}",
        _colToken: "${colToken}",
        _deployer: "${deployer}"
      }) {
        id
        _lendToken
        _deployer
        _colToken
        _expiry
        _mintRatio
        _feeRate
        _startTime
        _paused
      }
    }
    `
    }
  );
  let filteredRolloverPools = [];
  if (rolloverPoolsGraph.data.data.pools.length !== 0) {
    // only work with the correct parameters
    filteredRolloverPools = rolloverPoolsGraph.data.data.pools.filter(
      (p: any) =>
        p._deployer === selectedPool._deployer &&
        p.id !== selectedPool.id &&
        p._colToken === colToken &&
        p._lendToken === lendToken &&
        p._expiry > selectedPool._expiry &&
        !p.paused
    )
  }

  return (filteredRolloverPools);
}

// get the web3 provider that allows for signing transactions 
export const getWeb3Provider = (rpcUrl?: ethers.providers.JsonRpcProvider | any, jsonProvider?: boolean): ethers.providers.Web3Provider | any => {
  if (!jsonProvider) {
    const provider = new ethers.providers.Web3Provider(rpcUrl);
    return(provider);
  } else {
    const provider = new ethers.providers.JsonRpcProvider(rpcUrl ? rpcUrl : prodRPCAddress);
    return (provider);
  }
}

// get the current chainId from the Blocknative provider
export const getChainId = (wallet: {chains: any[]}): number | undefined => {
  try {
    return parseInt(wallet.chains[0].id);
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

// get the current account address from the Blocknative provider
export const getAccount = (wallet: {accounts: any[]}): string | undefined => {
  try {
    return wallet.accounts[0].address;
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

// check if a given pool is expired
export const isPoolExpired = (pool: POOL_DATA) => {
  return (Number(pool._expiry) * 1000 < new Date().getTime());
}

// create fake historical price data
export const generateFakePriceData = ():any[] => {
  let historicalData = [];
  const randomInRange = () => Math.random() * (500 - 400) + 400;
  let date = subDays(new Date(), 60);
  let lastRandom = 1500;
  for (let i = 0; i < 1465; i+=40) {
    // if (i % 2 === 0) continue;
    date = addDays(date, 1);
    const randomValue = i % 2 === 0 ? randomInRange() : lastRandom
    if (randomValue !== lastRandom) lastRandom = randomValue;
    historicalData.push({
      name: date.getTime(),
      timestamp: date,
      "Collateral Price": randomValue,
      "Borrow Value": 0
    });
  }
  return historicalData;
}

export const getCalendarLink = (timestamp: number, message: string) => {
	const rawTimestamp = moment.utc(timestamp).format('YYYYMMDD,HHmmss');
	const split = rawTimestamp.split(',');
	const date = split[0];
	const time = split[1];
	return `https://www.timeanddate.com/countdown/generic?iso=${date}T${time}&p0=%3A&msg=${message}&font=sanserif&csz=1`;
}

// get a contract reference to license engine
export const getFeeManagerContract = (
  wallet: ethers.Wallet | ethers.providers.JsonRpcProvider,
  chainId: number
) =>{
  return new ethers.Contract(
    // @ts-ignore
    contractAddresses.FEE_MANAGER.address[chainId],
    FeeManager,
    wallet 
  );
}

// get a contract reference to license engine
export const getLicenseEngineContract = (
  wallet: ethers.Wallet | ethers.providers.JsonRpcProvider,
  chainId: number
) =>{
  return new ethers.Contract(
    // @ts-ignore
    contractAddresses.LICENSE_ENGINE.address[chainId],
    LicenseManager,
    wallet 
  );
}

// get a contract reference to oracle 
export const getOracleContract = (
  wallet: ethers.Wallet | ethers.providers.JsonRpcProvider,
  chainId: number
) =>{
  return new ethers.Contract(
    // @ts-ignore
    contractAddresses.ORACLE_ADDRESS.address[chainId],
    OracleABI,
    wallet 
  );
}

// get a contract reference to pool factory 
export const getPoolFactoryContract = (
  wallet: ethers.Wallet | ethers.providers.JsonRpcProvider,
  chainId: number
) =>{
  return new ethers.Contract(
    // @ts-ignore
    contractAddresses.POOL_FACTORY.address[chainId],
    PoolFactory,
    wallet 
  );
}

// get a transaciton URL based on hash and chainId
export function getTransactionUrl(hash: string, chainId = defaultChain) {
  const network = getNetworkData(chainId);
  const url = network?.getExplorerTransactionLink(hash);
  return url;
}

export function getWithdrawToRepay(
  input: BigNumber, 
  totalFees: BigNumber, 
  mintRatio: BigNumber, 
  colDecimals: number | string, 
  lendDecimals: number | string 
) {
  const num = input.mul(mintRatio).mul(ethers.utils.parseUnits("1", lendDecimals));
  const denom = ethers.utils.parseUnits("1", colDecimals).mul(ethers.utils.parseUnits("1", 18));
  return num.div(denom).add(totalFees);
}

export async function fetchTokenPriceFirebase(symbol: string) {
  // get timestamp docs for the last ~hour
  const q = query(collection(db, symbol), orderBy("timestamp", "desc"), limit(1));
  const querySnapshot = await getDocs(q);
  let price = 0;
  // get the latest timestamp
  await querySnapshot.forEach(async(doc: any) => {
    const data = doc.data() || true;
    if (price === 0) price = (data.price);
  });
  return price;
}

export function generateUid() {
  let uid =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
  uid = uid.replace(/\d+/g, '')
  return (uid);
}

export function getRepayToWithdraw(
  input: BigNumber, 
  totalFees: BigNumber, 
  mintRatio: BigNumber, 
  colDecimals: number | string, 
  lendDecimals: number | string
) {
  /*
    (_repayAmount * 1e18 * (10**colToken.decimals())) /
      (10**lendToken.decimals()) /
      mintRatio;
  */
  let val = input.sub(totalFees);
  const term1 = val.mul(ethers.utils.parseUnits("1", 18)).mul(ethers.utils.parseUnits("1", colDecimals));
  const term2 = term1.div(ethers.utils.parseUnits("1", lendDecimals));
  const term3 = term2.div(mintRatio);
  val = term3;
  return val.gt(0) ? val : ethers.BigNumber.from(0);
}

export async function fetchBulkPrices (ids: string[]) {
  try {
    const tokenQuery = ids.map((id) => `coingecko:${id}`);
    const query = `${tokenQuery.join()}`;
    const prices = await axios.get(
      `https://coins.llama.fi/prices/current/${query}`
    );
    return (prices.data.coins);
  } catch (e) {
    console.log(e);
    console.log("failed to fetch bulk prices");
  }
}


export async function fetchTokenPrice(_address: string, chainId: number, provider?: any) {
  try {
    // checksum address
    const address = ethers.utils.getAddress(_address.toLowerCase());
    const currentTokenData = fetchLocalTokenData(address, chainId);
    // @ts-ignore
    if (!currentTokenData.usesFirebase) {
      // don't fetch prices for unsupported chains
      if (!supportedNetworks.includes(chainId)) return 0;

      // attempt to get price using oracle first
      const rawOraclePrice:number = await fetchTokenPriceOracle(address, chainId, provider);
      const oraclePrice = parseFloat(ethers.utils.formatUnits(rawOraclePrice, 8));

      // if oracle fails use coingecko
      if (oraclePrice < 0) {
        // const tokenData = fetchLocalTokenData(address, chainId);
        const currency = "usd";
        // get token data
        // get the price feed network for the token
        const network = currentTokenData.historicalPriceNetwork[1];
        const priceNetworkAddress = currentTokenData.address[currentTokenData.historicalPriceNetwork[0]];
        if (network !== "none") {
          const query = `contract_addresses=${priceNetworkAddress}&vs_currencies=${currency}`;
          const prices = await axios.get(
            `https://api.coingecko.com/api/v3/simple/token_price/${network}?${query}`
          );
          return prices && prices.data[priceNetworkAddress.toLowerCase()]?.[currency];
        } else {
          return 0;
        }
      } else {
        return oraclePrice;
      }
    } else {
      // use firebase for some tokens without oracles 
      const tokenData = fetchLocalTokenData(address, chainId);
      return await fetchTokenPriceFirebase(tokenData.symbol);
    }
  } catch (e) {
    console.log(e);
    console.log("failed to load token price for ", _address);
    return 0;
  }
}

// fetch a token's price from an on-chain oracle
export async function fetchTokenPriceOracle(
  address: string, 
  chainId: number, 
  provider: ethers.providers.JsonRpcProvider | ethers.providers.Web3Provider
) {
  try {
    const oracleContract = getOracleContract(provider, chainId);
    const price = await oracleContract.getPriceUSD(address);
    return price;
  } catch (e) {
    console.log(e);
    return -1;
  }
}

// locally fetch an icon from a given name
export function fetchIcon(name: string) {
  return (require(`../img/icons/${name}.svg`));
}

// locally fetch the logo for a given social media site
export function fetchSocialLogo(name: string) {
  return require(`../../public/assets/socials/${name}.svg`);
}

// locally fetch the logo for a given token symbol
export function fetchTokenLogo(symbol: string | undefined) {
  if (symbol)
    return require(`../../public/assets/tokens/${symbol.toUpperCase()}-logo.png`);
  else return null;
}

// fetch historical price data for a given token from firebase 
export async function fetchHistoricalFirebasePriceData(
  symbol: string
):Promise<any[]>{
  // get timestamp docs
  const q = query(collection(db, symbol), orderBy("timestamp", "desc"), limit(1000));
  const querySnapshot = await getDocs(q);
  let priceData:any[] = [];
  // iterate through timestamps and store the price
  await querySnapshot.forEach(async(doc: any) => {
    const data = doc.data() || true;
    priceData.unshift([data.timestamp * 1000, data.price]);
  });
  return priceData;
}

// fetch historical price data for a given token from coingecko
export async function fetchHistoricalPriceData(
  address: string, 
  chainId: number, 
  days=30, 
  currency="usd"
) {
  const data = await fetchLocalTokenData(address, chainId);
  if (!data.usesFirebase) {
    const startTimestamp = Math.floor(subDays(new Date(), 30).getTime() / 1000);
    const endTimestamp = Math.floor(new Date().getTime() / 1000);
    const url = `https://coins.llama.fi/chart/coingecko:${data.id}?start=${startTimestamp}?end=${endTimestamp}&span=100&period=1d`;
    const priceRequest = await axios.get(url);
    return (priceRequest.data.coins[`coingecko:${data.id}`].prices);
  } else {
    return await fetchHistoricalFirebasePriceData(data.symbol);
  }
}

// locally fetch the symbol for a token given the token address
export function fetchTokenSymbol(address: string, chainId = defaultChain) {
  const data = fetchLocalTokenData(address, chainId);
  return data?.symbol || "";
}

// fetch the data for locally stored tokens
export function fetchLocalTokenData (address: string, chainId = defaultChain) {
  for (const [key, _value] of Object.entries(tokenData)) {
    try {
      let value:any = _value;
      if (value.address[chainId].toLowerCase() === address.toLowerCase() && key) {
        return value;
      }
    } catch (e) {
      continue;
    }
  };
  return undefined;
}

export function getDisplayNumber (num: BigNumber, _symbol: string | undefined, truncate = false) {
  const symbol = _symbol ? _symbol : "";
  const data = Object.values(tokenData).find((data) => {
    return data.symbol.toUpperCase() === symbol.toUpperCase();
  });
  if (!data) return "0";
  try {
    if (truncate) {
      let res:any = ethers.utils.formatUnits(num, data.tokenDecimals);
      // res = (+res).toFixed(data.displayDecimals);
      res = Math.floor(res * Math.pow(10, data.displayDecimals)) / Math.pow(10, data.displayDecimals);
      return (res.toString());
    } else {
      return (ethers.utils.formatUnits(num, data.tokenDecimals));
    }
  } catch (e) {
    console.log(symbol);
    console.log(data);
    return "0";
  }
}

// if the value is valid render the element, else render a skeleton component
export function renderLazyLoad (value: any, element: any) {
  if (value) {
    return element
  } else {
    return (
      <Skeleton 
        className="loading-skeleton"
        height={23}
      />
    );
  }
}

export function truncateNumber(num: number | any, fixed = 0, ignoreBorder = false) {
  let repeatCount = fixed === 0 ? 0 : fixed - 1
  let borderNumber = parseFloat(`0.${"0".repeat(repeatCount)}1`)
  if (!ignoreBorder && num < borderNumber) return 0;
  else {
    // eslint-disable-next-line
    const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
    return num.toString().match(re)[0];
  }
}

// decide whether to use hardcoded gas limit or let wallet estimate
export const getGasLimit = (chainId: number, methodType: METHOD_TYPE, override?: boolean) => {
  if (chainId !== networks.Polygon.chainId && !override)
    return {}
  else 
    return {
      // @ts-ignore
      gasLimit: gasLimits[methodType][chainId]
    }
}

export const gasLimits = {
  APPROVE: {
    5: 80000,
    137: 80000,
    42161: 2500000,
    421613: 2500000
  },
  DEPOSIT: {
    5: 120000,
    137: 120000,
    42161: 2000000,
    421613: 2000000
  },
  COLLECT: {
    5: 170000,
    137: 170000,
    42161: 2000000,
    421613: 2000000
  },
  BORROW: {
    5: 350000,
    137: 350000,
    42161: 2500000,
    421613: 2500000
  },
  REPAY: {
    5: 170000,
    137: 170000,
    42161: 2500000,
    421613: 2500000
  },
  DEPLOY: {
    5: 700000,
    137: 700000,
    42161: 4500000,
    421613: 2500000
  },
  WITHDRAW: {
    5: 95000,
    137: 95000,
    42161: 1500000,
    421613: 100000 
  },
  ROLLOVER: {
    5: 500000,
    137: 500000,
    42161: 2000000,
    421613: 2000000 
  },
  PAUSE_BORROWING: {
    5: 35000,
    137: 35000,
    42161: 2000000,
    421613: 2000000 
  },
  SET_FEES: {
    5: 0,
    137: 65000,
    42161: 2000000, 
    421613: 2000000,
  },
  SET_ROLLOVER: {
    5: 40000,
    137: 35000,
    42161: 2000000,
    421613: 2000000
  },
  UPGRADE_IMPLEMENTATION: {
    5: 700000,
    137: 700000,
    42161: 4500000,
    421613: 2500000
  }
}

export const networkNames = {
	1: "Ethereum",
	3: "Ropsten",
	4: "Rinkeby",
	5: "Goerli",
	42: "Kovan",
	56: "BSC",
  137: "Polygon",
	250: "Fantom",
  42161: "Arbitrum",
  421613: "AGoerli",
  43114: "Avalanche",
};

export const supportedNetworks = [
  1,      // Mainnet
  // 5,   // Goerli
  137,    // Polygon
  42161,  // Arbitrum
  421613, // aGoerli
];

export const contractAddresses = {
  ORACLE_ADDRESS: {
    address: {
      1: "0xc2e70e6AB40DE43365dc798bd6b99737c54c3089",
      5: "0x9f2997c305A54b85599c729279e83ab8dc135cbF",
      42: "0x84dF6e8615f01284E67155e6722DEb2d0C7d250D",
      137: "0x36147adA2D37dea5F65873527006C84c34ac8A59",
      42161: "0xc2e70e6AB40DE43365dc798bd6b99737c54c3089",
      421613: "0xBfC606bdd9ae52C4Aa786cc4fF74abccBC07b64c"
    }
  },
  LICENSE_ENGINE: {
    address: {
      1: "0xAf013ba5cE2e3AC97BF93Ea968031B3D0B4682Cf",
      5: "0x02e0CF1c7fFcB03103a0B8118D45a942191BEa47",
      42: "0x78f4db8d54464F7611859AA052D136A24fA1A6F4",
      137: "0xAf013ba5cE2e3AC97BF93Ea968031B3D0B4682Cf",
      42161: "0xD30dD0A7750C261c7FF175DCB9A06979e3dcAE48",
      421613: "0xa7ff080d43F7F726e68b565d1d7445F1B7C555C3"
    }
  },
  POOL_FACTORY: {
    address: {
      1: "0x928cf648069082D9AEf25ddB2bF10D25bf1C1D73",
      5: "0x45Cc252c2ab952301EDAe9BfA85CEb286fccE36d",
      42: "0xd271561199711bbE08e77936e3486D4Cb491c120",
      137: "0x928cf648069082D9AEf25ddB2bF10D25bf1C1D73",
      42161: "0xF0dbf74CeF39e5Cd4e54D0FfD59075024C7d8857",
      421613: "0xAf013ba5cE2e3AC97BF93Ea968031B3D0B4682Cf"
    }
  },
  FEE_MANAGER: {
    address: {
      1: "0xeCBFd6cF5Eebe9313D386A19a42a474a2998e56b",
      5: "0x0F9628887572FeC4932bc80B9b54446000afb0e6",
      42: "",
      137: "0xeCBFd6cF5Eebe9313D386A19a42a474a2998e56b",
      42161: "0x34F429e82dA625aBa84e80B5c2a9fa471771B807",
      421613: "0x5DE8492178e9C0De9240aC4b01987B1aDaffBB41"
    }
  }
}

export const shouldRenderPool = (
  pool: POOL_DATA, 
  chainId: number,
  tokenPrices: any,
  account: string | undefined,
  liquidityRange: number[],
  queryPool: string | undefined,
  ltv: number,
  showJunkPools?: boolean
) => {
  let show = true;
  // get lend/col token balance and value
  const lendTokenData = fetchLocalTokenData(pool._lendToken, chainId);
  const colTokenData = fetchLocalTokenData(pool._colToken, chainId);
  if (!colTokenData || !lendTokenData) return false;
  const lendBalance = ethers.utils.formatUnits(pool._lendBalance, lendTokenData.tokenDecimals);
  const colBalance = ethers.utils.formatUnits(pool._colBalance, colTokenData.tokenDecimals);
  const lendPrice = tokenPrices[lendTokenData.address[`${chainId}`]];
  const colPrice = tokenPrices[colTokenData.address[`${chainId}`]];
  let colValue = Math.floor(parseFloat(colBalance) * parseFloat(colPrice) * 100) / 100;
  if (isNaN(colValue)) colValue = 0;
  // truncate value to 2 decimals
  let lendValue = Math.floor(parseFloat(lendBalance) * parseFloat(lendPrice) * 100) / 100;
  if (isNaN(lendValue)) lendValue = 0;
  // is the user allowed to borrow
  let isPrivate = pool._borrowers.length > 0;
  let userWhitelisted = !isPrivate;
  if (isPrivate && pool._borrowers.includes(account || ""))
    userWhitelisted = true;
  // is pool liquidity in range
  const inRange =  lendValue >= liquidityRange[0] && lendValue <= liquidityRange[1];
  // check if junk pool (no lend and collateral)
  const junkPool = (lendValue === 0 && colValue === 0) && !showJunkPools;
  // is pool disabled
  const isDisabled = pool._paused;
  // is pool the query pool
  const isQueryPool = pool.id.toLowerCase() === queryPool;
  // is the pool underwater
  // const ltv = calculateLTV(pool);
  const isUnderWater = ltv > 100;
  if (isQueryPool) show = true;
  else if (junkPool) show = false;
  else if (isUnderWater && !isPrivate) show = false;
  else if (isPrivate && !userWhitelisted) show = false;
  else if (!inRange || isDisabled) show = false;
  return show;
}

export const handleMulticallAddress = (chainId: number, multicallProvider: Provider) => {
  const networkData = getNetworkData(chainId);
  // @ts-ignore
  multicallProvider._multicallAddress = networkData?.multicallAddress;
}

// add chain query if user is not on arbitrum 
export const getChainQuery = (chainId: number) => {
  const chainQuery = chainId === networks.Arbitrum.chainId 
    ? ""
    : `?chain=${getNetworkData(chainId).chainName.toLowerCase()}`
  return chainQuery;
}

// check URL for an ethereum address using regex
export const checkQueryPool = (poolAddress: string) => {
  const path = window.location.pathname;
  const parts = path.split("/");
  const regex = /0x[a-fA-F0-9]{40}/;
  if (parts.length > 3) {
    const queryAddress = parts[3];
    if (regex.test(queryAddress)) {
      if (queryAddress.toLowerCase() === poolAddress.toLowerCase())
        return true;
      else 
        return false;
    }
  }

  return false;
}

// get network data based on chain name 
export const getNetworkDataByName = (networkName: string) => {
  const network = Object.values(networks).find((network) => 
    network.chainName.toLowerCase() === networkName.toLowerCase()
  )
  // return requested network if it exists
  if (network) return network;
  // else return arbitrum as default
  else return networks.Arbitrum;
}

// get network data based on chainId
export const getNetworkData = (chainId: number) => {
  const network = Object.values(networks).find((network) => 
    network.chainId === chainId
  )
  // return requested network if it exists
  if (network) return network;
  // else return arbitrum as default
  else return networks.Arbitrum;
}

export const networks = {
  Ethereum: {
    chainId: Mainnet.chainId,
    chainName: "Ethereum",
    isTestChain: false,
    isLocalChain: false,
    isLive: true,
    multicallAddress:  "0xeefba1e63905ef1d7acba5a8513c70307c1ce441",
    graphUrl: "https://api.studio.thegraph.com/query/48389/vendor-finance-ethereum-v1/v0.0.1",
    rpcUrl: "https://restless-quiet-brook.quiknode.pro/40e8524d1c27f6c9990192a6dda43a2bffddfaa5/",
    getExplorerAddressLink: (address: string) => `https://etherscan.io/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://etherscan.io/tx/${transactionHash}`,
  },
  Arbitrum: {
    chainId: Arbitrum.chainId,
    chainName: "Arbitrum",
    isTestChain: false,
    isLocalChain: false,
    isLive: true,
    multicallAddress:  "0xB064Fe785d8131653eE12f3581F9A55F6D6E1ca3",
    graphUrl: `https://gateway-arbitrum.network.thegraph.com/api/af21e56a3d82a334011b652560d970bb/subgraphs/id/CycasGMVHZbqdgBUCWscBq2bAYZRASquemRavtTgAzya`,
    rpcUrl: "https://thrilling-virulent-flower.arbitrum-mainnet.quiknode.pro/f1c88a01f0b68ef4fb58fb3c4ae1adbc2b74ee70",
    getExplorerAddressLink: (address: string) => `https://arbiscan.io/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://arbiscan.io/tx/${transactionHash}`,
  },
  Polygon: {
    chainId: Polygon.chainId,
    chainName: "Polygon",
    isTestChain: true,
    isLocalChain: false,
    isLive: false,
    multicallAddress: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507',
    graphUrl: "https://api.thegraph.com/subgraphs/name/0xtaiga/vendor-finance-polygon",
    rpcUrl: "https://polygon.llamarpc.com",
    getExplorerAddressLink: (address: string) => `https://polygonscan.com/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://polygonscan.com/tx/${transactionHash}`,
  },
  ArbitrumGoerli: {
    chainId: 421613,
    chainName: "AGoerli",
    isTestChain: true,
    isLocalChain: false,
    isLive: true,
    multiVersionSupport: true,
    multicallAddress:  "0x21cd0d37ca11da6c4035c4727cd091c0dd9f800f",
    graphUrl: "https://api.thegraph.com/subgraphs/name/0xtaiga/vendor-finance-arbitrum-goerli",
    rpcUrl: "https://goerli-rollup.arbitrum.io/rpc",
    getExplorerAddressLink: (address: string) => `https://arbiscan.io/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://arbiscan.io/tx/${transactionHash}`,
  },
  /*
  Goerli: {
    chainId: Goerli.chainId,
    chainName: "Goerli",
    isTestChain: false,
    isLocalChain: false,
    isLive: false,
    multicallAddress:  "",
    graphUrl: "https://api.thegraph.com/subgraphs/name/0xtaiga/vendorfinancetest",
    rpcUrl: "https://eth-goerli.g.alchemy.com/v2/BuClIQXzCjtWOEPzWYpu7kQUZwyrW78C",
    getExplorerAddressLink: (address: string) => `https://goerli.etherscan.io/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://goerli.etherscan.io/tx/${transactionHash}`
  },
  TestNet: {
    chainId: 1337,
    chainName: "Local Fork",
    isTestChain: true,
    isLocalChain: true,
    multicallAddress:  "0xB064Fe785d8131653eE12f3581F9A55F6D6E1ca3",
    graphUrl: "https://api.thegraph.com/subgraphs/name/0xtaiga/vendor",
    getExplorerAddressLink: (address: string) => `https://arbiscan.io/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://arbiscan.io/tx/${transactionHash}`,
  }
  */
}

export const tokenData = {
  WETH: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "WETH",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1, 137, 5, 42161],
    hasOracle: true,
    id: "weth",
    address: {
      1: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      5: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
      42: "0xd0A1E359811322d97991E03f863a0C30C2cF029C",
      137: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      42161: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"
    }
  },
  SYN: {
    displayDecimals: 3,
    tokenDecimals: 18,
    symbol: "SYN",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1],
    hasOracle: false,
    id: "synapse-2",
    address: {
      1: "0x0f2D719407FdBeFF09D87557AbB7232601FD9F29",
    }
  },
  GRAIL: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "GRAIL",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "camelot-token",
    address: {
      42161: "0x3d9907F9a368ad0a51Be60f7Da3b97cf940982D8"
    }
  },
  ARB: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "ARB",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "arbitrum",
    address: {
      42161: "0x912CE59144191C1204E64559FE8253a0e49E6548"
    }
  },
  PEPE: {
    displayDecimals: 8,
    tokenDecimals: 18,
    symbol: "PEPE",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "pepe",
    address: {
      1: "0x6982508145454Ce325dDbE47a25d4ec3d2311933",
      5: "",
      42: "",
      42161: "0xa54b8e178a49f8e5405a4d44bb31f496e5564a05"
    }
  },
  XGRAIL: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "xGRAIL",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161, 421613],
    hasOracle: false,
    id: "camelot-token",
    address: {
      42161: "0x3CAaE25Ee616f2C8E13C74dA0813402eae3F496b",
      421613: "0x45bd859A0533d29B4D55a860bd70A332AE810dEE"
    }
  },
  PLSARB: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "PLSARB",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "arbitrum",
    address: {
      42161: "0x7a5D193fE4ED9098F7EAdC99797087C96b002907"
    }
  },
  GOHM: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "gOHM",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [1, 42161],
    usesFirebase: false,
    hasOracle: true,
    id: "governance-ohm",
    overrideFilter: true,
    address: {
      1: "0x0ab87046fBb341D058F17CBC4c1133F25a20a52f",
      42161: "0x8D9bA570D6cb60C7e3e0F31343Efe75AB8E65FB1"
    }
  },
  JUSDC: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "jUSDC",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    usesFirebase: false,
    hasOracle: false,
    id: "jones-usdc",
    overrideFilter: true,
    address: {
      42161: "0xe66998533a1992ecE9eA99cDf47686F4fc8458E0"
    }
  },
  JGLP: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "jGLP",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    usesFirebase: false,
    hasOracle: false,
    id: "jones-glp",
    overrideFilter: true,
    address: {
      42161: "0x7241bC8035b65865156DDb5EdEf3eB32874a3AF6"
    }
  },
  magicGLP: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "magicGLP",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    usesFirebase: true,
    hasOracle: false,
    overrideFilter: true,
    address: {
      42161: "0x85667409a723684Fe1e57Dd1ABDe8D88C2f54214"
    }
  },
  CMUMAMI: {
    displayDecimals: 4,
    tokenDecimals: 9,
    symbol: "cmUMAMI",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "compounded-marinated-umami",
    address: {
      1: "",
      5: "",
      42: "",
      42161: "0x1922C36F3bc762Ca300b4a46bB2102F84B1684aB"
    }
  },
  UMAMI: {
    displayDecimals: 4,
    tokenDecimals: 9,
    symbol: "UMAMI",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "umami-finance",
    address: {
      1: "",
      5: "",
      42: "",
      42161: "0x1622bF67e6e5747b81866fE0b85178a93C7F86e3"
    }
  },
  PLVGLP: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "plvGLP",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [42161],
    usesFirebase: true,
    hasOracle: false,
    address: {
      1: "",
      5: "",
      42: "",
      42161: "0x5326e71ff593ecc2cf7acae5fe57582d6e74cff1"
    }
  },
  MAGIC: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "MAGIC",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [42161],
    hasOracle: true,
    id: "magic",
    address: {
      1: "0xB0c7a3Ba49C7a6EaBa6cD4a96C55a1391070Ac9A",
      5: "",
      42: "",
      42161: "0x539bdE0d7Dbd336b79148AA742883198BBF60342"
    }
  },
  Y2K: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "Y2K",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "y2k",
    address: {
      42161: "0x65c936f008BC34fE819bce9Fa5afD9dc2d49977f"
    }
  },
  /*
  RDPX: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "RDPX",
    historicalPriceNetwork: [1, "ethereum"],
    hasOracle: true,
    address: {
      1: "0xeec2be5c91ae7f8a338e1e5f3b5de49d07afdc81",
      5: "",
      42: "",
      42161: "0x6c2c06790b3e3e3c38e12ee22f8183b37a13ee55"
    }
  },
  */
  RETH: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "RETH",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1, 42161],
    hasOracle: false,
    id: "reth",
    address: {
      1: "0xae78736Cd615f374D3085123A210448E74Fc6393",
      5: "",
      42: "",
      42161: "0xEC70Dcb4A1EFa46b8F2D97C310C9c4790ba5ffA8"
    }
  },
  XCAL: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "XCAL",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    usesFirebase: false,
    hasOracle: false,
    id: "3xcalibur",
    address: {
      1: "",
      5: "",
      42: "",
      42161: "0xd2568acCD10A4C98e87c44E9920360031ad89fCB"
    }
  },
  GMX: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "GMX",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "gmx",
    address: {
      1: "",
      5: "",
      42: "",
      42161: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a"
    }
  },
  DPX: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "DPX",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [42161],
    hasOracle: true,
    id: "dopex",
    address: {
      1: "0xEec2bE5c91ae7f8a338e1e5f3b5DE49d07AfdC81",
      5: "",
      42: "",
      42161: "0x6C2C06790b3E3E3c38e12Ee22F8183b37a13EE55"
    }
  },
  AAVE: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "AAVE",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1],
    hasOracle: true,
    id: "aave",
    address: {
      1: "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
    }   
  },
  UNI: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "UNI",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1],
    hasOracle: true,
    id: "uniswap",
    address: {
      1: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
    }   
  },
  CVX: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "CVX",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1],
    hasOracle: true,
    id: "convex-finance",
    address: {
      1: "0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B",
    }   
  },
  WSTETH: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "WSTETH",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1, 42161],
    hasOracle: true,
    id: "wrapped-steth",
    address: {
      1: "0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0",
      42161: "0x5979D7b546E38E414F7E9822514be443A4800529"
    }
  },
  FXS: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "FXS",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1],
    hasOracle: true,
    id: "frax-share",
    address: {
      1: "0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0",
    }
  },
  LDO: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "LDO",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1],
    hasOracle: true,
    id: "lido-dao",
    address: {
      1: "0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32",
    }
  },
  APE: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "APE",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1],
    hasOracle: true,
    id: "apecoin",
    address: {
      1: "0x4d224452801ACEd8B2F0aebE155379bb5D594381",
    }
  },
  CBETH: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "CBETH",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1],
    hasOracle: true,
    id: "coinbase-wrapped-staked-eth",
    address: {
      1: "0xBe9895146f7AF43049ca1c1AE358B0541Ea49704",
    }
  },
  YFI: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "YFI",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1],
    hasOracle: true,
    id: "yearn-finance",
    address: {
      1: "0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e",
    }
  },
  WBTC: {
    displayDecimals: 4,
    tokenDecimals: 8,
    symbol: "WBTC",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1, 42161, 421613],
    hasOracle: true,
    id: "wrapped-bitcoin",
    address: {
      1: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
      5: "0xC04B0d3107736C32e19F1c62b2aF67BE61d63a05",
      42: "0xd3a691c852cdb01e281545a27064741f0b7f6825",
      137: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
      42161: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      421613: "0x2Df743730160059c50c6bA9E87b30876FA6Db720"
    }
  },
  CRV: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "CRV",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1, 42161],
    hasOracle: true,
    id: "curve-dao-token",
    address: {
      1: "0xD533a949740bb3306d119CC777fa900bA034cd52",
      5: "",
      42: "",
      42161: "0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978"
    }
  },
  SPELL: {
    displayDecimals: 5,
    tokenDecimals: 18,
    symbol: "SPELL",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [42161],
    hasOracle: true,
    id: "spell-token",
    address: {
      1: "0x090185f2135308BaD17527004364eBcC2D37e5F6",
      42161: "0x3E6648C5a70A150A88bCE65F4aD4d506Fe15d2AF"
    }
  },
  DAI: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "DAI",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1, 137, 42161, 421613],
    hasOracle: true,
    id: "dai",
    address: {
      1: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      42: "0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa",
      137: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      42161: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1"
    }
  },
  LINK: {
    displayDecimals: 3,
    tokenDecimals: 18,
    symbol: "LINK",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1],
    hasOracle: true,
    id: "chainlink",
    address: {
      1: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
      5: "0x326C977E6efc84E512bB9C30f76E30c160eD06FB",
      42161: ""
    }
  },
  COMP: {
    displayDecimals: 3,
    tokenDecimals: 18,
    symbol: "COMP",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [],
    hasOracle: true,
    id: "compound-governance-token",
    address: {
      1: "0xc00e94Cb662C3520282E6f5717214004A7f26888",
      42: "0x61460874a7196d6a22D1eE4922473664b3E95270",
      42161: ""
    }
  },
  USDT: {
    displayDecimals: 4,
    tokenDecimals: 6,
    symbol: "USDT",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1, 137, 42161, 421613],
    hasOracle: true,
    id: "tether",
    address: {
      1: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      42: "0x07de306FF27a2B630B1141956844eB1552B956B5",
      137: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      42161: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      421613: "0xbAc565f93f3192D35E9106E67B9d5c9348bD9389"
    }
  },
  USDC: {
    displayDecimals: 4,
    tokenDecimals: 6,
    symbol: "USDC",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1, 137, 42161, 421613],
    hasOracle: true,
    id: "usd-coin",
    address: {
      1: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      42: "0xb7a4F3E9097C08dA09517b5aB877F7a917224ede",
      137: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      42161: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      421613: "0x6775842AE82BF2F0f987b10526768Ad89d79536E"
    }
  },
  MIM: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "MIM",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [42161],
    hasOracle: true,
    id: "magic-internet-money",
    address: {
      1: "0x99D8a9C45b2ecA8864373A26D1459e3Dff1e17F3",
      5: "",
      42: "",
      42161: "0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A"
    }
  },
  FRAX: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "FRAX",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1],
    hasOracle: true,
    id: "frax",
    address: {
      1: "0x853d955aCEf822Db058eb8505911ED77F175b99e",
      5: "",
      42: "",
      42161: "0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F"
    }
  },
}