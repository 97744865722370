import { Alert } from "@material-ui/lab";
import { ethers } from "ethers";
import { useContext } from "react";
import ActionButton from "../../../components/ActionButton/ActionButton";
import { AppDataContext } from "../../../context/AppDataContext";
import { PoolDataContext } from "../../../context/PoolDataContext";
import { WalletDataContext } from "../../../context/WalletDataContext";
import { APP_DATA_CONTEXT, METHOD_TYPE, POOL_DATA_CONTEXT, WALLET_DATA_CONTEXT } from "../../../utils/Interfaces";
import LendingPoolABI from "../../../abi/LendingPool";
import "./UpgradeImplementation.css"
import { useSnackbar } from "notistack";
import { getGasLimit, getTransactionUrl } from "../../../utils/Utils";

const UpgradeImplementation = (props: {
  checkPoolEligibility: () => void
}) => {

  const { pending, setPending } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { selectedPool } = useContext(PoolDataContext) as POOL_DATA_CONTEXT;
  const { provider, chainId } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { enqueueSnackbar } = useSnackbar();

  const upgrade = async () => {
    if (!selectedPool) return;
    try {
      enqueueSnackbar("Please confirm the upgrade transaction in your wallet", {
        persist: false,
        disableWindowBlurListener: true,
      });
      setPending(true);
      const poolContract = new ethers.Contract(
        selectedPool.id, 
        LendingPoolABI, 
        provider?.getSigner()
      );
      const tx = await poolContract.upgradeTo(
        "0x512445cD569889a03Ef61b6f1c8Fc2346D670f49",
        {
          ...getGasLimit(chainId, METHOD_TYPE.UPGRADE_IMPLEMENTATION)
        }
      );
      enqueueSnackbar(`Executing upgrade transaction ** ${getTransactionUrl(tx.hash, chainId)} ** persist`);
      await tx.wait();
      enqueueSnackbar(`Upgrade implementation transaction successful ** ${getTransactionUrl(tx.hash, chainId)}`);
      props.checkPoolEligibility();
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Upgrade implemenetation transaction failed", {
        persist: false,
        disableWindowBlurListener: true,
      });
    }
    setPending(false);
  }

  return (
    <div className="upgrade-implementation-wrapper">
      <Alert severity="error">
        There is an updated version of the lending pools available. While no
        funds are at risk and upgrade is optional we strongly suggest to upgrade
        for improved experience and security. Vendor Team can not upgrade the
        pool on your behalf.{" "}
        <a 
          href="https://vendorfinance.medium.com/vendor-finance-incident-report-and-fix-2ae3833611f1"
          target="_blank"
          rel="noreferrer"
        >
          Read our Medium article for more info
        </a>
      </Alert>
      <ActionButton
        disabled={pending}
        title={"Upgrade Implementation"}
        action={() => upgrade()}
      />
    </div>
  );
}

export default UpgradeImplementation;