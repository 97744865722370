import React, { Fragment } from "react";
import "./TokenSelector.css";
import { ChevronDown } from "react-feather";
import { fetchTokenLogo, getDisplayNumber, renderLazyLoad } from "../../utils/Utils";

const TokenSelector = (props: {
  sync?: (val: any, id: any) => void,
  id?: any,
  select?: boolean,
  setSelectedInput?: (val: any) => void,
  setShowModal?: (val: boolean) => void,
  symbol: string,
  value: string | number,
  disabled?: boolean,
  balance?: any,
  clickValue?: any,
  text?: string,
  type?: string
  loading?: boolean
}) => {

  const handleChange = (event: any) => {
    if (props.sync)
      props.sync(event.target.value, props.id);
  };

  const setValue = (val: any) => {
    if (props.sync)
      props.sync(val, props.id);
  };

  const inputClicked = () => {
    if (!props.select) return;
    if (props.setSelectedInput) props.setSelectedInput(props.id);
    if (props.setShowModal) props.setShowModal(true);
  };

  return (
    <div
      className={`token-selector-container ${props.select ? "select" : ""}`}
      onClick={inputClicked}
    >
      <div className="token-drop-down">
        <img
          className="token-logo"
          src={fetchTokenLogo(props.symbol)}
          alt="token"
        ></img>
        <div className="token-symbol">{props.symbol}</div>
        {!props.select && <div className="token-selector-separator"></div>}
      </div>
      <input
        onChange={handleChange}
        className="token-amount-entry"
        placeholder="0.0"
        min="0"
        value={`${props.select ? "$" : ""}${props.value}`}
        disabled={props.select || props.disabled}
      ></input>
      {props.select && <ChevronDown />}
      {renderLazyLoad(
        !props.loading,
        <Fragment>
          {props.balance  ? 
            <span className="token-balance" onClick={() => setValue(getDisplayNumber(props.clickValue ? props.clickValue : props.balance, props.symbol))}>
              {props.text ? `${props.text}:` : ''} {getDisplayNumber(props.balance, props.symbol, true)}
            </span>
          : ""}
        </Fragment>
      )}
    </div>
  );
};

export default TokenSelector;
