import { Fragment, useEffect, useState } from "react";
import { Filter, Search } from "react-feather";
import Dropdown from "../Dropdown/Dropdown";
import "./PoolFilters.css";

const PoolFilters = (props: {
  setSearchValue: (value: string) => void;
  children: any
}) => {

  const [searchValue, setSearchValue] = useState<string>("");
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [showDropdownFilters, setShowDropdownFilters] = useState<boolean>(false);

  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId:any = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(window.innerWidth), 0);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);
    window.addEventListener('click', onClick);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
      window.removeEventListener('click', onClick);
    }
  }, []);
  
  const searchUpdated = (e: any) => {
    const val = e.target.value;
    setSearchValue(val);
    props.setSearchValue(val);
  };

  const onClick = (e: any) => {
    // get the dropdown and menu button components
    const target = document.querySelector(`.pool-filters-mobile-wrapper`);
    // skip for some elements that are clicked
    if (e.target.tagName === "INPUT") return;
    if (target !== null) {
      // close dropdown if click is outside of the dropdown boundaries
      const withinBoundaries = e.composedPath().includes(target);
      if (!withinBoundaries) setShowDropdownFilters(false);
    }
  }
  
  const renderFilters = () => {
    if (width > 600)
      return (
        <Fragment>
          {props.children}
        </Fragment>
      );
    else 
      return (
        <Fragment>
          <div className="pool-filters-mobile-wrapper">
            <Filter onClick={() => setShowDropdownFilters(!showDropdownFilters)}/>
            {showDropdownFilters &&
              <Dropdown 
                setShowDropdown={setShowDropdownFilters}
                showDropdown={showDropdownFilters}
              >
                {props.children}
              </Dropdown>
            }
          </div>
        </Fragment>
      )
  }

  return (
    <div className="pool-filters-header">
      <div className="top-level-pool-filters filter">
        {renderFilters()}
      </div>
      <div className="pool-filters-search-wrapper filter">
        <input
          className={`search-input`}
          placeholder="Search token symbols"
          value={searchValue}
          onInput={searchUpdated}
        />
        <div
          className="search-toggle"
        >
          <Search />
        </div>
      </div>
    </div>
  );
};

export default PoolFilters;